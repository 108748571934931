/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.manager = (function() {

    /**
     * Namespace manager.
     * @exports manager
     * @namespace
     */
    var manager = {};

    manager.ArgumentsBytes = (function() {

        /**
         * Properties of an ArgumentsBytes.
         * @memberof manager
         * @interface IArgumentsBytes
         * @property {string|null} [MetricVersion] ArgumentsBytes MetricVersion
         * @property {Uint8Array|null} [Bytes] ArgumentsBytes Bytes
         */

        /**
         * Constructs a new ArgumentsBytes.
         * @memberof manager
         * @classdesc Represents an ArgumentsBytes.
         * @implements IArgumentsBytes
         * @constructor
         * @param {manager.IArgumentsBytes=} [properties] Properties to set
         */
        function ArgumentsBytes(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsBytes MetricVersion.
         * @member {string} MetricVersion
         * @memberof manager.ArgumentsBytes
         * @instance
         */
        ArgumentsBytes.prototype.MetricVersion = "";

        /**
         * ArgumentsBytes Bytes.
         * @member {Uint8Array} Bytes
         * @memberof manager.ArgumentsBytes
         * @instance
         */
        ArgumentsBytes.prototype.Bytes = $util.newBuffer([]);

        /**
         * Creates a new ArgumentsBytes instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsBytes
         * @static
         * @param {manager.IArgumentsBytes=} [properties] Properties to set
         * @returns {manager.ArgumentsBytes} ArgumentsBytes instance
         */
        ArgumentsBytes.create = function create(properties) {
            return new ArgumentsBytes(properties);
        };

        /**
         * Encodes the specified ArgumentsBytes message. Does not implicitly {@link manager.ArgumentsBytes.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsBytes
         * @static
         * @param {manager.IArgumentsBytes} message ArgumentsBytes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsBytes.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.MetricVersion != null && message.hasOwnProperty("MetricVersion"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.MetricVersion);
            if (message.Bytes != null && message.hasOwnProperty("Bytes"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.Bytes);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsBytes message, length delimited. Does not implicitly {@link manager.ArgumentsBytes.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsBytes
         * @static
         * @param {manager.IArgumentsBytes} message ArgumentsBytes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsBytes.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsBytes message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsBytes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsBytes} ArgumentsBytes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsBytes.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsBytes();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.MetricVersion = reader.string();
                    break;
                case 2:
                    message.Bytes = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsBytes message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsBytes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsBytes} ArgumentsBytes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsBytes.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsBytes message.
         * @function verify
         * @memberof manager.ArgumentsBytes
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsBytes.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.MetricVersion != null && message.hasOwnProperty("MetricVersion"))
                if (!$util.isString(message.MetricVersion))
                    return "MetricVersion: string expected";
            if (message.Bytes != null && message.hasOwnProperty("Bytes"))
                if (!(message.Bytes && typeof message.Bytes.length === "number" || $util.isString(message.Bytes)))
                    return "Bytes: buffer expected";
            return null;
        };

        /**
         * Creates an ArgumentsBytes message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsBytes
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsBytes} ArgumentsBytes
         */
        ArgumentsBytes.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsBytes)
                return object;
            var message = new $root.manager.ArgumentsBytes();
            if (object.MetricVersion != null)
                message.MetricVersion = String(object.MetricVersion);
            if (object.Bytes != null)
                if (typeof object.Bytes === "string")
                    $util.base64.decode(object.Bytes, message.Bytes = $util.newBuffer($util.base64.length(object.Bytes)), 0);
                else if (object.Bytes.length)
                    message.Bytes = object.Bytes;
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsBytes message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsBytes
         * @static
         * @param {manager.ArgumentsBytes} message ArgumentsBytes
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsBytes.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.MetricVersion = "";
                if (options.bytes === String)
                    object.Bytes = "";
                else {
                    object.Bytes = [];
                    if (options.bytes !== Array)
                        object.Bytes = $util.newBuffer(object.Bytes);
                }
            }
            if (message.MetricVersion != null && message.hasOwnProperty("MetricVersion"))
                object.MetricVersion = message.MetricVersion;
            if (message.Bytes != null && message.hasOwnProperty("Bytes"))
                object.Bytes = options.bytes === String ? $util.base64.encode(message.Bytes, 0, message.Bytes.length) : options.bytes === Array ? Array.prototype.slice.call(message.Bytes) : message.Bytes;
            return object;
        };

        /**
         * Converts this ArgumentsBytes to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsBytes
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsBytes.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsBytes;
    })();

    manager.ArgumentsConf = (function() {

        /**
         * Properties of an ArgumentsConf.
         * @memberof manager
         * @interface IArgumentsConf
         * @property {string|null} [Version] ArgumentsConf Version
         * @property {number|null} [PartSize] ArgumentsConf PartSize
         * @property {number|null} [TimeoutV2V] ArgumentsConf TimeoutV2V
         * @property {number|null} [TimeoutDirectDownload] ArgumentsConf TimeoutDirectDownload
         * @property {number|null} [StorageSize] ArgumentsConf StorageSize
         * @property {number|null} [FreeSlot] ArgumentsConf FreeSlot
         * @property {number|null} [MaxSwarmSize] ArgumentsConf MaxSwarmSize
         * @property {number|null} [SendMessageTimeout] ArgumentsConf SendMessageTimeout
         * @property {number|null} [ConnectionTimeout] ArgumentsConf ConnectionTimeout
         * @property {number|null} [pingPongCheckInterval] ArgumentsConf pingPongCheckInterval
         * @property {number|null} [pingPongInterval] ArgumentsConf pingPongInterval
         * @property {number|null} [pingpongMaxAllowedDrift] ArgumentsConf pingpongMaxAllowedDrift
         * @property {number|null} [offerMaxPercentage] ArgumentsConf offerMaxPercentage
         * @property {number|null} [iceCandidateCacheTTL] ArgumentsConf iceCandidateCacheTTL
         */

        /**
         * Constructs a new ArgumentsConf.
         * @memberof manager
         * @classdesc Represents an ArgumentsConf.
         * @implements IArgumentsConf
         * @constructor
         * @param {manager.IArgumentsConf=} [properties] Properties to set
         */
        function ArgumentsConf(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsConf Version.
         * @member {string} Version
         * @memberof manager.ArgumentsConf
         * @instance
         */
        ArgumentsConf.prototype.Version = "";

        /**
         * ArgumentsConf PartSize.
         * @member {number} PartSize
         * @memberof manager.ArgumentsConf
         * @instance
         */
        ArgumentsConf.prototype.PartSize = 0;

        /**
         * ArgumentsConf TimeoutV2V.
         * @member {number} TimeoutV2V
         * @memberof manager.ArgumentsConf
         * @instance
         */
        ArgumentsConf.prototype.TimeoutV2V = 0;

        /**
         * ArgumentsConf TimeoutDirectDownload.
         * @member {number} TimeoutDirectDownload
         * @memberof manager.ArgumentsConf
         * @instance
         */
        ArgumentsConf.prototype.TimeoutDirectDownload = 0;

        /**
         * ArgumentsConf StorageSize.
         * @member {number} StorageSize
         * @memberof manager.ArgumentsConf
         * @instance
         */
        ArgumentsConf.prototype.StorageSize = 0;

        /**
         * ArgumentsConf FreeSlot.
         * @member {number} FreeSlot
         * @memberof manager.ArgumentsConf
         * @instance
         */
        ArgumentsConf.prototype.FreeSlot = 0;

        /**
         * ArgumentsConf MaxSwarmSize.
         * @member {number} MaxSwarmSize
         * @memberof manager.ArgumentsConf
         * @instance
         */
        ArgumentsConf.prototype.MaxSwarmSize = 0;

        /**
         * ArgumentsConf SendMessageTimeout.
         * @member {number} SendMessageTimeout
         * @memberof manager.ArgumentsConf
         * @instance
         */
        ArgumentsConf.prototype.SendMessageTimeout = 0;

        /**
         * ArgumentsConf ConnectionTimeout.
         * @member {number} ConnectionTimeout
         * @memberof manager.ArgumentsConf
         * @instance
         */
        ArgumentsConf.prototype.ConnectionTimeout = 0;

        /**
         * ArgumentsConf pingPongCheckInterval.
         * @member {number} pingPongCheckInterval
         * @memberof manager.ArgumentsConf
         * @instance
         */
        ArgumentsConf.prototype.pingPongCheckInterval = 0;

        /**
         * ArgumentsConf pingPongInterval.
         * @member {number} pingPongInterval
         * @memberof manager.ArgumentsConf
         * @instance
         */
        ArgumentsConf.prototype.pingPongInterval = 0;

        /**
         * ArgumentsConf pingpongMaxAllowedDrift.
         * @member {number} pingpongMaxAllowedDrift
         * @memberof manager.ArgumentsConf
         * @instance
         */
        ArgumentsConf.prototype.pingpongMaxAllowedDrift = 0;

        /**
         * ArgumentsConf offerMaxPercentage.
         * @member {number} offerMaxPercentage
         * @memberof manager.ArgumentsConf
         * @instance
         */
        ArgumentsConf.prototype.offerMaxPercentage = 0;

        /**
         * ArgumentsConf iceCandidateCacheTTL.
         * @member {number} iceCandidateCacheTTL
         * @memberof manager.ArgumentsConf
         * @instance
         */
        ArgumentsConf.prototype.iceCandidateCacheTTL = 0;

        /**
         * Creates a new ArgumentsConf instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsConf
         * @static
         * @param {manager.IArgumentsConf=} [properties] Properties to set
         * @returns {manager.ArgumentsConf} ArgumentsConf instance
         */
        ArgumentsConf.create = function create(properties) {
            return new ArgumentsConf(properties);
        };

        /**
         * Encodes the specified ArgumentsConf message. Does not implicitly {@link manager.ArgumentsConf.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsConf
         * @static
         * @param {manager.IArgumentsConf} message ArgumentsConf message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsConf.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Version != null && message.hasOwnProperty("Version"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.Version);
            if (message.PartSize != null && message.hasOwnProperty("PartSize"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.PartSize);
            if (message.TimeoutV2V != null && message.hasOwnProperty("TimeoutV2V"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.TimeoutV2V);
            if (message.TimeoutDirectDownload != null && message.hasOwnProperty("TimeoutDirectDownload"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.TimeoutDirectDownload);
            if (message.StorageSize != null && message.hasOwnProperty("StorageSize"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.StorageSize);
            if (message.FreeSlot != null && message.hasOwnProperty("FreeSlot"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.FreeSlot);
            if (message.MaxSwarmSize != null && message.hasOwnProperty("MaxSwarmSize"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.MaxSwarmSize);
            if (message.SendMessageTimeout != null && message.hasOwnProperty("SendMessageTimeout"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.SendMessageTimeout);
            if (message.ConnectionTimeout != null && message.hasOwnProperty("ConnectionTimeout"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.ConnectionTimeout);
            if (message.pingPongCheckInterval != null && message.hasOwnProperty("pingPongCheckInterval"))
                writer.uint32(/* id 11, wireType 0 =*/88).uint32(message.pingPongCheckInterval);
            if (message.pingPongInterval != null && message.hasOwnProperty("pingPongInterval"))
                writer.uint32(/* id 12, wireType 0 =*/96).uint32(message.pingPongInterval);
            if (message.pingpongMaxAllowedDrift != null && message.hasOwnProperty("pingpongMaxAllowedDrift"))
                writer.uint32(/* id 13, wireType 0 =*/104).uint32(message.pingpongMaxAllowedDrift);
            if (message.offerMaxPercentage != null && message.hasOwnProperty("offerMaxPercentage"))
                writer.uint32(/* id 14, wireType 1 =*/113).double(message.offerMaxPercentage);
            if (message.iceCandidateCacheTTL != null && message.hasOwnProperty("iceCandidateCacheTTL"))
                writer.uint32(/* id 15, wireType 0 =*/120).uint32(message.iceCandidateCacheTTL);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsConf message, length delimited. Does not implicitly {@link manager.ArgumentsConf.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsConf
         * @static
         * @param {manager.IArgumentsConf} message ArgumentsConf message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsConf.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsConf message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsConf
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsConf} ArgumentsConf
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsConf.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsConf();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Version = reader.string();
                    break;
                case 3:
                    message.PartSize = reader.uint32();
                    break;
                case 4:
                    message.TimeoutV2V = reader.uint32();
                    break;
                case 5:
                    message.TimeoutDirectDownload = reader.uint32();
                    break;
                case 6:
                    message.StorageSize = reader.uint32();
                    break;
                case 7:
                    message.FreeSlot = reader.uint32();
                    break;
                case 8:
                    message.MaxSwarmSize = reader.uint32();
                    break;
                case 9:
                    message.SendMessageTimeout = reader.uint32();
                    break;
                case 10:
                    message.ConnectionTimeout = reader.uint32();
                    break;
                case 11:
                    message.pingPongCheckInterval = reader.uint32();
                    break;
                case 12:
                    message.pingPongInterval = reader.uint32();
                    break;
                case 13:
                    message.pingpongMaxAllowedDrift = reader.uint32();
                    break;
                case 14:
                    message.offerMaxPercentage = reader.double();
                    break;
                case 15:
                    message.iceCandidateCacheTTL = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsConf message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsConf
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsConf} ArgumentsConf
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsConf.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsConf message.
         * @function verify
         * @memberof manager.ArgumentsConf
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsConf.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Version != null && message.hasOwnProperty("Version"))
                if (!$util.isString(message.Version))
                    return "Version: string expected";
            if (message.PartSize != null && message.hasOwnProperty("PartSize"))
                if (!$util.isInteger(message.PartSize))
                    return "PartSize: integer expected";
            if (message.TimeoutV2V != null && message.hasOwnProperty("TimeoutV2V"))
                if (!$util.isInteger(message.TimeoutV2V))
                    return "TimeoutV2V: integer expected";
            if (message.TimeoutDirectDownload != null && message.hasOwnProperty("TimeoutDirectDownload"))
                if (!$util.isInteger(message.TimeoutDirectDownload))
                    return "TimeoutDirectDownload: integer expected";
            if (message.StorageSize != null && message.hasOwnProperty("StorageSize"))
                if (!$util.isInteger(message.StorageSize))
                    return "StorageSize: integer expected";
            if (message.FreeSlot != null && message.hasOwnProperty("FreeSlot"))
                if (!$util.isInteger(message.FreeSlot))
                    return "FreeSlot: integer expected";
            if (message.MaxSwarmSize != null && message.hasOwnProperty("MaxSwarmSize"))
                if (!$util.isInteger(message.MaxSwarmSize))
                    return "MaxSwarmSize: integer expected";
            if (message.SendMessageTimeout != null && message.hasOwnProperty("SendMessageTimeout"))
                if (!$util.isInteger(message.SendMessageTimeout))
                    return "SendMessageTimeout: integer expected";
            if (message.ConnectionTimeout != null && message.hasOwnProperty("ConnectionTimeout"))
                if (!$util.isInteger(message.ConnectionTimeout))
                    return "ConnectionTimeout: integer expected";
            if (message.pingPongCheckInterval != null && message.hasOwnProperty("pingPongCheckInterval"))
                if (!$util.isInteger(message.pingPongCheckInterval))
                    return "pingPongCheckInterval: integer expected";
            if (message.pingPongInterval != null && message.hasOwnProperty("pingPongInterval"))
                if (!$util.isInteger(message.pingPongInterval))
                    return "pingPongInterval: integer expected";
            if (message.pingpongMaxAllowedDrift != null && message.hasOwnProperty("pingpongMaxAllowedDrift"))
                if (!$util.isInteger(message.pingpongMaxAllowedDrift))
                    return "pingpongMaxAllowedDrift: integer expected";
            if (message.offerMaxPercentage != null && message.hasOwnProperty("offerMaxPercentage"))
                if (typeof message.offerMaxPercentage !== "number")
                    return "offerMaxPercentage: number expected";
            if (message.iceCandidateCacheTTL != null && message.hasOwnProperty("iceCandidateCacheTTL"))
                if (!$util.isInteger(message.iceCandidateCacheTTL))
                    return "iceCandidateCacheTTL: integer expected";
            return null;
        };

        /**
         * Creates an ArgumentsConf message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsConf
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsConf} ArgumentsConf
         */
        ArgumentsConf.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsConf)
                return object;
            var message = new $root.manager.ArgumentsConf();
            if (object.Version != null)
                message.Version = String(object.Version);
            if (object.PartSize != null)
                message.PartSize = object.PartSize >>> 0;
            if (object.TimeoutV2V != null)
                message.TimeoutV2V = object.TimeoutV2V >>> 0;
            if (object.TimeoutDirectDownload != null)
                message.TimeoutDirectDownload = object.TimeoutDirectDownload >>> 0;
            if (object.StorageSize != null)
                message.StorageSize = object.StorageSize >>> 0;
            if (object.FreeSlot != null)
                message.FreeSlot = object.FreeSlot >>> 0;
            if (object.MaxSwarmSize != null)
                message.MaxSwarmSize = object.MaxSwarmSize >>> 0;
            if (object.SendMessageTimeout != null)
                message.SendMessageTimeout = object.SendMessageTimeout >>> 0;
            if (object.ConnectionTimeout != null)
                message.ConnectionTimeout = object.ConnectionTimeout >>> 0;
            if (object.pingPongCheckInterval != null)
                message.pingPongCheckInterval = object.pingPongCheckInterval >>> 0;
            if (object.pingPongInterval != null)
                message.pingPongInterval = object.pingPongInterval >>> 0;
            if (object.pingpongMaxAllowedDrift != null)
                message.pingpongMaxAllowedDrift = object.pingpongMaxAllowedDrift >>> 0;
            if (object.offerMaxPercentage != null)
                message.offerMaxPercentage = Number(object.offerMaxPercentage);
            if (object.iceCandidateCacheTTL != null)
                message.iceCandidateCacheTTL = object.iceCandidateCacheTTL >>> 0;
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsConf message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsConf
         * @static
         * @param {manager.ArgumentsConf} message ArgumentsConf
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsConf.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.Version = "";
                object.PartSize = 0;
                object.TimeoutV2V = 0;
                object.TimeoutDirectDownload = 0;
                object.StorageSize = 0;
                object.FreeSlot = 0;
                object.MaxSwarmSize = 0;
                object.SendMessageTimeout = 0;
                object.ConnectionTimeout = 0;
                object.pingPongCheckInterval = 0;
                object.pingPongInterval = 0;
                object.pingpongMaxAllowedDrift = 0;
                object.offerMaxPercentage = 0;
                object.iceCandidateCacheTTL = 0;
            }
            if (message.Version != null && message.hasOwnProperty("Version"))
                object.Version = message.Version;
            if (message.PartSize != null && message.hasOwnProperty("PartSize"))
                object.PartSize = message.PartSize;
            if (message.TimeoutV2V != null && message.hasOwnProperty("TimeoutV2V"))
                object.TimeoutV2V = message.TimeoutV2V;
            if (message.TimeoutDirectDownload != null && message.hasOwnProperty("TimeoutDirectDownload"))
                object.TimeoutDirectDownload = message.TimeoutDirectDownload;
            if (message.StorageSize != null && message.hasOwnProperty("StorageSize"))
                object.StorageSize = message.StorageSize;
            if (message.FreeSlot != null && message.hasOwnProperty("FreeSlot"))
                object.FreeSlot = message.FreeSlot;
            if (message.MaxSwarmSize != null && message.hasOwnProperty("MaxSwarmSize"))
                object.MaxSwarmSize = message.MaxSwarmSize;
            if (message.SendMessageTimeout != null && message.hasOwnProperty("SendMessageTimeout"))
                object.SendMessageTimeout = message.SendMessageTimeout;
            if (message.ConnectionTimeout != null && message.hasOwnProperty("ConnectionTimeout"))
                object.ConnectionTimeout = message.ConnectionTimeout;
            if (message.pingPongCheckInterval != null && message.hasOwnProperty("pingPongCheckInterval"))
                object.pingPongCheckInterval = message.pingPongCheckInterval;
            if (message.pingPongInterval != null && message.hasOwnProperty("pingPongInterval"))
                object.pingPongInterval = message.pingPongInterval;
            if (message.pingpongMaxAllowedDrift != null && message.hasOwnProperty("pingpongMaxAllowedDrift"))
                object.pingpongMaxAllowedDrift = message.pingpongMaxAllowedDrift;
            if (message.offerMaxPercentage != null && message.hasOwnProperty("offerMaxPercentage"))
                object.offerMaxPercentage = options.json && !isFinite(message.offerMaxPercentage) ? String(message.offerMaxPercentage) : message.offerMaxPercentage;
            if (message.iceCandidateCacheTTL != null && message.hasOwnProperty("iceCandidateCacheTTL"))
                object.iceCandidateCacheTTL = message.iceCandidateCacheTTL;
            return object;
        };

        /**
         * Converts this ArgumentsConf to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsConf
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsConf.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsConf;
    })();

    manager.ArgumentsEvent = (function() {

        /**
         * Properties of an ArgumentsEvent.
         * @memberof manager
         * @interface IArgumentsEvent
         * @property {manager.ArgumentsEvent.EventClass|null} [EventName] ArgumentsEvent EventName
         * @property {manager.ArgumentsEvent.Actions|null} [Action] ArgumentsEvent Action
         * @property {string|null} [PeerID] ArgumentsEvent PeerID
         * @property {string|null} [RemotePeerID] ArgumentsEvent RemotePeerID
         * @property {manager.IArgumentsP2PPart|null} [ArgumentsP2PPart] ArgumentsEvent ArgumentsP2PPart
         * @property {manager.IArgumentsP2PReq|null} [ArgumentsP2PReq] ArgumentsEvent ArgumentsP2PReq
         * @property {manager.IPeeringStats|null} [ArgumentsP2PStats] ArgumentsEvent ArgumentsP2PStats
         * @property {manager.IArgumentsPeerInfo|null} [ArgumentsPeerInfo] ArgumentsEvent ArgumentsPeerInfo
         * @property {manager.IPeerVisit|null} [ArgumentsPeerVisit] ArgumentsEvent ArgumentsPeerVisit
         * @property {manager.IArgumentsRegistered|null} [ArgumentsRegistered] ArgumentsEvent ArgumentsRegistered
         * @property {manager.IResourceExchanges|null} [ArgumentsResourceDownloaded] ArgumentsEvent ArgumentsResourceDownloaded
         * @property {manager.IArgumentsResourceExchanges|null} [ArgumentsResourceExchanges] ArgumentsEvent ArgumentsResourceExchanges
         * @property {manager.IResourceExchanges|null} [ArgumentsResourceUploaded] ArgumentsEvent ArgumentsResourceUploaded
         * @property {manager.IArgumentsPlayerInfo|null} [ArgumentsPlayerInfo] ArgumentsEvent ArgumentsPlayerInfo
         * @property {manager.IArgumentsSwarmSizeUpdate|null} [ArgumentsSwarmSizeUpdate] ArgumentsEvent ArgumentsSwarmSizeUpdate
         * @property {manager.ISlotInfo|null} [ArgumentsSlotUpdate] ArgumentsEvent ArgumentsSlotUpdate
         * @property {manager.IArgumentsConf|null} [ArgumentsConf] ArgumentsEvent ArgumentsConf
         * @property {manager.IPeerState|null} [ArgumentsPeerState] ArgumentsEvent ArgumentsPeerState
         * @property {manager.IPlayerState|null} [ArgumentsPlayerState] ArgumentsEvent ArgumentsPlayerState
         * @property {manager.INetworkInfo|null} [ArgumentsNetworkInfo] ArgumentsEvent ArgumentsNetworkInfo
         * @property {manager.IPingPongExchange|null} [ArgumentsPingPongExchange] ArgumentsEvent ArgumentsPingPongExchange
         * @property {manager.IResourceAvailability|null} [ArgumentsResourceAvailability] ArgumentsEvent ArgumentsResourceAvailability
         */

        /**
         * Constructs a new ArgumentsEvent.
         * @memberof manager
         * @classdesc Represents an ArgumentsEvent.
         * @implements IArgumentsEvent
         * @constructor
         * @param {manager.IArgumentsEvent=} [properties] Properties to set
         */
        function ArgumentsEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsEvent EventName.
         * @member {manager.ArgumentsEvent.EventClass} EventName
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.EventName = 0;

        /**
         * ArgumentsEvent Action.
         * @member {manager.ArgumentsEvent.Actions} Action
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.Action = 0;

        /**
         * ArgumentsEvent PeerID.
         * @member {string} PeerID
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.PeerID = "";

        /**
         * ArgumentsEvent RemotePeerID.
         * @member {string} RemotePeerID
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.RemotePeerID = "";

        /**
         * ArgumentsEvent ArgumentsP2PPart.
         * @member {manager.IArgumentsP2PPart|null|undefined} ArgumentsP2PPart
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsP2PPart = null;

        /**
         * ArgumentsEvent ArgumentsP2PReq.
         * @member {manager.IArgumentsP2PReq|null|undefined} ArgumentsP2PReq
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsP2PReq = null;

        /**
         * ArgumentsEvent ArgumentsP2PStats.
         * @member {manager.IPeeringStats|null|undefined} ArgumentsP2PStats
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsP2PStats = null;

        /**
         * ArgumentsEvent ArgumentsPeerInfo.
         * @member {manager.IArgumentsPeerInfo|null|undefined} ArgumentsPeerInfo
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsPeerInfo = null;

        /**
         * ArgumentsEvent ArgumentsPeerVisit.
         * @member {manager.IPeerVisit|null|undefined} ArgumentsPeerVisit
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsPeerVisit = null;

        /**
         * ArgumentsEvent ArgumentsRegistered.
         * @member {manager.IArgumentsRegistered|null|undefined} ArgumentsRegistered
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsRegistered = null;

        /**
         * ArgumentsEvent ArgumentsResourceDownloaded.
         * @member {manager.IResourceExchanges|null|undefined} ArgumentsResourceDownloaded
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsResourceDownloaded = null;

        /**
         * ArgumentsEvent ArgumentsResourceExchanges.
         * @member {manager.IArgumentsResourceExchanges|null|undefined} ArgumentsResourceExchanges
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsResourceExchanges = null;

        /**
         * ArgumentsEvent ArgumentsResourceUploaded.
         * @member {manager.IResourceExchanges|null|undefined} ArgumentsResourceUploaded
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsResourceUploaded = null;

        /**
         * ArgumentsEvent ArgumentsPlayerInfo.
         * @member {manager.IArgumentsPlayerInfo|null|undefined} ArgumentsPlayerInfo
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsPlayerInfo = null;

        /**
         * ArgumentsEvent ArgumentsSwarmSizeUpdate.
         * @member {manager.IArgumentsSwarmSizeUpdate|null|undefined} ArgumentsSwarmSizeUpdate
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsSwarmSizeUpdate = null;

        /**
         * ArgumentsEvent ArgumentsSlotUpdate.
         * @member {manager.ISlotInfo|null|undefined} ArgumentsSlotUpdate
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsSlotUpdate = null;

        /**
         * ArgumentsEvent ArgumentsConf.
         * @member {manager.IArgumentsConf|null|undefined} ArgumentsConf
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsConf = null;

        /**
         * ArgumentsEvent ArgumentsPeerState.
         * @member {manager.IPeerState|null|undefined} ArgumentsPeerState
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsPeerState = null;

        /**
         * ArgumentsEvent ArgumentsPlayerState.
         * @member {manager.IPlayerState|null|undefined} ArgumentsPlayerState
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsPlayerState = null;

        /**
         * ArgumentsEvent ArgumentsNetworkInfo.
         * @member {manager.INetworkInfo|null|undefined} ArgumentsNetworkInfo
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsNetworkInfo = null;

        /**
         * ArgumentsEvent ArgumentsPingPongExchange.
         * @member {manager.IPingPongExchange|null|undefined} ArgumentsPingPongExchange
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsPingPongExchange = null;

        /**
         * ArgumentsEvent ArgumentsResourceAvailability.
         * @member {manager.IResourceAvailability|null|undefined} ArgumentsResourceAvailability
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        ArgumentsEvent.prototype.ArgumentsResourceAvailability = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ArgumentsEvent Additional.
         * @member {"ArgumentsP2PPart"|"ArgumentsP2PReq"|"ArgumentsP2PStats"|"ArgumentsPeerInfo"|"ArgumentsPeerVisit"|"ArgumentsRegistered"|"ArgumentsResourceDownloaded"|"ArgumentsResourceExchanges"|"ArgumentsResourceUploaded"|"ArgumentsPlayerInfo"|"ArgumentsSwarmSizeUpdate"|"ArgumentsSlotUpdate"|"ArgumentsConf"|"ArgumentsPeerState"|"ArgumentsPlayerState"|"ArgumentsNetworkInfo"|"ArgumentsPingPongExchange"|"ArgumentsResourceAvailability"|undefined} Additional
         * @memberof manager.ArgumentsEvent
         * @instance
         */
        Object.defineProperty(ArgumentsEvent.prototype, "Additional", {
            get: $util.oneOfGetter($oneOfFields = ["ArgumentsP2PPart", "ArgumentsP2PReq", "ArgumentsP2PStats", "ArgumentsPeerInfo", "ArgumentsPeerVisit", "ArgumentsRegistered", "ArgumentsResourceDownloaded", "ArgumentsResourceExchanges", "ArgumentsResourceUploaded", "ArgumentsPlayerInfo", "ArgumentsSwarmSizeUpdate", "ArgumentsSlotUpdate", "ArgumentsConf", "ArgumentsPeerState", "ArgumentsPlayerState", "ArgumentsNetworkInfo", "ArgumentsPingPongExchange", "ArgumentsResourceAvailability"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ArgumentsEvent instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsEvent
         * @static
         * @param {manager.IArgumentsEvent=} [properties] Properties to set
         * @returns {manager.ArgumentsEvent} ArgumentsEvent instance
         */
        ArgumentsEvent.create = function create(properties) {
            return new ArgumentsEvent(properties);
        };

        /**
         * Encodes the specified ArgumentsEvent message. Does not implicitly {@link manager.ArgumentsEvent.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsEvent
         * @static
         * @param {manager.IArgumentsEvent} message ArgumentsEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.EventName != null && message.hasOwnProperty("EventName"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.EventName);
            if (message.Action != null && message.hasOwnProperty("Action"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Action);
            if (message.PeerID != null && message.hasOwnProperty("PeerID"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.PeerID);
            if (message.RemotePeerID != null && message.hasOwnProperty("RemotePeerID"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.RemotePeerID);
            if (message.ArgumentsP2PPart != null && message.hasOwnProperty("ArgumentsP2PPart"))
                $root.manager.ArgumentsP2PPart.encode(message.ArgumentsP2PPart, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.ArgumentsP2PReq != null && message.hasOwnProperty("ArgumentsP2PReq"))
                $root.manager.ArgumentsP2PReq.encode(message.ArgumentsP2PReq, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.ArgumentsPeerInfo != null && message.hasOwnProperty("ArgumentsPeerInfo"))
                $root.manager.ArgumentsPeerInfo.encode(message.ArgumentsPeerInfo, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.ArgumentsP2PStats != null && message.hasOwnProperty("ArgumentsP2PStats"))
                $root.manager.PeeringStats.encode(message.ArgumentsP2PStats, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.ArgumentsRegistered != null && message.hasOwnProperty("ArgumentsRegistered"))
                $root.manager.ArgumentsRegistered.encode(message.ArgumentsRegistered, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.ArgumentsPeerVisit != null && message.hasOwnProperty("ArgumentsPeerVisit"))
                $root.manager.PeerVisit.encode(message.ArgumentsPeerVisit, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.ArgumentsResourceDownloaded != null && message.hasOwnProperty("ArgumentsResourceDownloaded"))
                $root.manager.ResourceExchanges.encode(message.ArgumentsResourceDownloaded, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.ArgumentsPlayerInfo != null && message.hasOwnProperty("ArgumentsPlayerInfo"))
                $root.manager.ArgumentsPlayerInfo.encode(message.ArgumentsPlayerInfo, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.ArgumentsResourceUploaded != null && message.hasOwnProperty("ArgumentsResourceUploaded"))
                $root.manager.ResourceExchanges.encode(message.ArgumentsResourceUploaded, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.ArgumentsSwarmSizeUpdate != null && message.hasOwnProperty("ArgumentsSwarmSizeUpdate"))
                $root.manager.ArgumentsSwarmSizeUpdate.encode(message.ArgumentsSwarmSizeUpdate, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.ArgumentsSlotUpdate != null && message.hasOwnProperty("ArgumentsSlotUpdate"))
                $root.manager.SlotInfo.encode(message.ArgumentsSlotUpdate, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.ArgumentsConf != null && message.hasOwnProperty("ArgumentsConf"))
                $root.manager.ArgumentsConf.encode(message.ArgumentsConf, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.ArgumentsPeerState != null && message.hasOwnProperty("ArgumentsPeerState"))
                $root.manager.PeerState.encode(message.ArgumentsPeerState, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.ArgumentsPlayerState != null && message.hasOwnProperty("ArgumentsPlayerState"))
                $root.manager.PlayerState.encode(message.ArgumentsPlayerState, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.ArgumentsNetworkInfo != null && message.hasOwnProperty("ArgumentsNetworkInfo"))
                $root.manager.NetworkInfo.encode(message.ArgumentsNetworkInfo, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.ArgumentsPingPongExchange != null && message.hasOwnProperty("ArgumentsPingPongExchange"))
                $root.manager.PingPongExchange.encode(message.ArgumentsPingPongExchange, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.ArgumentsResourceAvailability != null && message.hasOwnProperty("ArgumentsResourceAvailability"))
                $root.manager.ResourceAvailability.encode(message.ArgumentsResourceAvailability, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.ArgumentsResourceExchanges != null && message.hasOwnProperty("ArgumentsResourceExchanges"))
                $root.manager.ArgumentsResourceExchanges.encode(message.ArgumentsResourceExchanges, writer.uint32(/* id 111, wireType 2 =*/890).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ArgumentsEvent message, length delimited. Does not implicitly {@link manager.ArgumentsEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsEvent
         * @static
         * @param {manager.IArgumentsEvent} message ArgumentsEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsEvent message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsEvent} ArgumentsEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.EventName = reader.int32();
                    break;
                case 2:
                    message.Action = reader.int32();
                    break;
                case 3:
                    message.PeerID = reader.string();
                    break;
                case 4:
                    message.RemotePeerID = reader.string();
                    break;
                case 5:
                    message.ArgumentsP2PPart = $root.manager.ArgumentsP2PPart.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.ArgumentsP2PReq = $root.manager.ArgumentsP2PReq.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.ArgumentsP2PStats = $root.manager.PeeringStats.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.ArgumentsPeerInfo = $root.manager.ArgumentsPeerInfo.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.ArgumentsPeerVisit = $root.manager.PeerVisit.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.ArgumentsRegistered = $root.manager.ArgumentsRegistered.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.ArgumentsResourceDownloaded = $root.manager.ResourceExchanges.decode(reader, reader.uint32());
                    break;
                case 111:
                    message.ArgumentsResourceExchanges = $root.manager.ArgumentsResourceExchanges.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.ArgumentsResourceUploaded = $root.manager.ResourceExchanges.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.ArgumentsPlayerInfo = $root.manager.ArgumentsPlayerInfo.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.ArgumentsSwarmSizeUpdate = $root.manager.ArgumentsSwarmSizeUpdate.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.ArgumentsSlotUpdate = $root.manager.SlotInfo.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.ArgumentsConf = $root.manager.ArgumentsConf.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.ArgumentsPeerState = $root.manager.PeerState.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.ArgumentsPlayerState = $root.manager.PlayerState.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.ArgumentsNetworkInfo = $root.manager.NetworkInfo.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.ArgumentsPingPongExchange = $root.manager.PingPongExchange.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.ArgumentsResourceAvailability = $root.manager.ResourceAvailability.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsEvent} ArgumentsEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsEvent message.
         * @function verify
         * @memberof manager.ArgumentsEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.EventName != null && message.hasOwnProperty("EventName"))
                switch (message.EventName) {
                default:
                    return "EventName: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                    break;
                }
            if (message.Action != null && message.hasOwnProperty("Action"))
                switch (message.Action) {
                default:
                    return "Action: enum value expected";
                case 0:
                case 1:
                case 2:
                case 10:
                case 11:
                case 13:
                case 14:
                case 141:
                case 12:
                case 121:
                case 15:
                case 16:
                case 17:
                case 18:
                case 20:
                case 201:
                case 202:
                case 203:
                case 21:
                case 22:
                case 23:
                case 24:
                case 211:
                case 25:
                case 26:
                    break;
                }
            if (message.PeerID != null && message.hasOwnProperty("PeerID"))
                if (!$util.isString(message.PeerID))
                    return "PeerID: string expected";
            if (message.RemotePeerID != null && message.hasOwnProperty("RemotePeerID"))
                if (!$util.isString(message.RemotePeerID))
                    return "RemotePeerID: string expected";
            if (message.ArgumentsP2PPart != null && message.hasOwnProperty("ArgumentsP2PPart")) {
                properties.Additional = 1;
                {
                    var error = $root.manager.ArgumentsP2PPart.verify(message.ArgumentsP2PPart);
                    if (error)
                        return "ArgumentsP2PPart." + error;
                }
            }
            if (message.ArgumentsP2PReq != null && message.hasOwnProperty("ArgumentsP2PReq")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.ArgumentsP2PReq.verify(message.ArgumentsP2PReq);
                    if (error)
                        return "ArgumentsP2PReq." + error;
                }
            }
            if (message.ArgumentsP2PStats != null && message.hasOwnProperty("ArgumentsP2PStats")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.PeeringStats.verify(message.ArgumentsP2PStats);
                    if (error)
                        return "ArgumentsP2PStats." + error;
                }
            }
            if (message.ArgumentsPeerInfo != null && message.hasOwnProperty("ArgumentsPeerInfo")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.ArgumentsPeerInfo.verify(message.ArgumentsPeerInfo);
                    if (error)
                        return "ArgumentsPeerInfo." + error;
                }
            }
            if (message.ArgumentsPeerVisit != null && message.hasOwnProperty("ArgumentsPeerVisit")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.PeerVisit.verify(message.ArgumentsPeerVisit);
                    if (error)
                        return "ArgumentsPeerVisit." + error;
                }
            }
            if (message.ArgumentsRegistered != null && message.hasOwnProperty("ArgumentsRegistered")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.ArgumentsRegistered.verify(message.ArgumentsRegistered);
                    if (error)
                        return "ArgumentsRegistered." + error;
                }
            }
            if (message.ArgumentsResourceDownloaded != null && message.hasOwnProperty("ArgumentsResourceDownloaded")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.ResourceExchanges.verify(message.ArgumentsResourceDownloaded);
                    if (error)
                        return "ArgumentsResourceDownloaded." + error;
                }
            }
            if (message.ArgumentsResourceExchanges != null && message.hasOwnProperty("ArgumentsResourceExchanges")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.ArgumentsResourceExchanges.verify(message.ArgumentsResourceExchanges);
                    if (error)
                        return "ArgumentsResourceExchanges." + error;
                }
            }
            if (message.ArgumentsResourceUploaded != null && message.hasOwnProperty("ArgumentsResourceUploaded")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.ResourceExchanges.verify(message.ArgumentsResourceUploaded);
                    if (error)
                        return "ArgumentsResourceUploaded." + error;
                }
            }
            if (message.ArgumentsPlayerInfo != null && message.hasOwnProperty("ArgumentsPlayerInfo")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.ArgumentsPlayerInfo.verify(message.ArgumentsPlayerInfo);
                    if (error)
                        return "ArgumentsPlayerInfo." + error;
                }
            }
            if (message.ArgumentsSwarmSizeUpdate != null && message.hasOwnProperty("ArgumentsSwarmSizeUpdate")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.ArgumentsSwarmSizeUpdate.verify(message.ArgumentsSwarmSizeUpdate);
                    if (error)
                        return "ArgumentsSwarmSizeUpdate." + error;
                }
            }
            if (message.ArgumentsSlotUpdate != null && message.hasOwnProperty("ArgumentsSlotUpdate")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.SlotInfo.verify(message.ArgumentsSlotUpdate);
                    if (error)
                        return "ArgumentsSlotUpdate." + error;
                }
            }
            if (message.ArgumentsConf != null && message.hasOwnProperty("ArgumentsConf")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.ArgumentsConf.verify(message.ArgumentsConf);
                    if (error)
                        return "ArgumentsConf." + error;
                }
            }
            if (message.ArgumentsPeerState != null && message.hasOwnProperty("ArgumentsPeerState")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.PeerState.verify(message.ArgumentsPeerState);
                    if (error)
                        return "ArgumentsPeerState." + error;
                }
            }
            if (message.ArgumentsPlayerState != null && message.hasOwnProperty("ArgumentsPlayerState")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.PlayerState.verify(message.ArgumentsPlayerState);
                    if (error)
                        return "ArgumentsPlayerState." + error;
                }
            }
            if (message.ArgumentsNetworkInfo != null && message.hasOwnProperty("ArgumentsNetworkInfo")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.NetworkInfo.verify(message.ArgumentsNetworkInfo);
                    if (error)
                        return "ArgumentsNetworkInfo." + error;
                }
            }
            if (message.ArgumentsPingPongExchange != null && message.hasOwnProperty("ArgumentsPingPongExchange")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.PingPongExchange.verify(message.ArgumentsPingPongExchange);
                    if (error)
                        return "ArgumentsPingPongExchange." + error;
                }
            }
            if (message.ArgumentsResourceAvailability != null && message.hasOwnProperty("ArgumentsResourceAvailability")) {
                if (properties.Additional === 1)
                    return "Additional: multiple values";
                properties.Additional = 1;
                {
                    var error = $root.manager.ResourceAvailability.verify(message.ArgumentsResourceAvailability);
                    if (error)
                        return "ArgumentsResourceAvailability." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ArgumentsEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsEvent} ArgumentsEvent
         */
        ArgumentsEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsEvent)
                return object;
            var message = new $root.manager.ArgumentsEvent();
            switch (object.EventName) {
            case "RTC":
            case 0:
                message.EventName = 0;
                break;
            case "PEER":
            case 1:
                message.EventName = 1;
                break;
            case "RESOURCE":
            case 2:
                message.EventName = 2;
                break;
            case "PLAYER":
            case 3:
                message.EventName = 3;
                break;
            case "P2P":
            case 4:
                message.EventName = 4;
                break;
            case "SWARM":
            case 5:
                message.EventName = 5;
                break;
            case "LIB":
            case 6:
                message.EventName = 6;
                break;
            case "NETWORK":
            case 7:
                message.EventName = 7;
                break;
            case "PINGPONG":
            case 8:
                message.EventName = 8;
                break;
            }
            switch (object.Action) {
            case "INIT":
            case 0:
                message.Action = 0;
                break;
            case "OPEN":
            case 1:
                message.Action = 1;
                break;
            case "CLOSE":
            case 2:
                message.Action = 2;
                break;
            case "SEND":
            case 10:
                message.Action = 10;
                break;
            case "RCVD":
            case 11:
                message.Action = 11;
                break;
            case "BUSY":
            case 13:
                message.Action = 13;
                break;
            case "REQUEST":
            case 14:
                message.Action = 14;
                break;
            case "STATS":
            case 141:
                message.Action = 141;
                break;
            case "DOWNLOADED":
            case 12:
                message.Action = 12;
                break;
            case "EXCHANGE":
            case 121:
                message.Action = 121;
                break;
            case "UPLOADED":
            case 15:
                message.Action = 15;
                break;
            case "SLOTUPDATE":
            case 16:
                message.Action = 16;
                break;
            case "STORAGE":
            case 17:
                message.Action = 17;
                break;
            case "AVAILABILITY":
            case 18:
                message.Action = 18;
                break;
            case "INFO":
            case 20:
                message.Action = 20;
                break;
            case "REGISTERED":
            case 201:
                message.Action = 201;
                break;
            case "VISIT":
            case 202:
                message.Action = 202;
                break;
            case "STATE":
            case 203:
                message.Action = 203;
                break;
            case "PLAY":
            case 21:
                message.Action = 21;
                break;
            case "PAUSE":
            case 22:
                message.Action = 22;
                break;
            case "REBUFFER":
            case 23:
                message.Action = 23;
                break;
            case "STOP":
            case 24:
                message.Action = 24;
                break;
            case "FLUCTUATE":
            case 211:
                message.Action = 211;
                break;
            case "SIZEUPDATE":
            case 25:
                message.Action = 25;
                break;
            case "CONF":
            case 26:
                message.Action = 26;
                break;
            }
            if (object.PeerID != null)
                message.PeerID = String(object.PeerID);
            if (object.RemotePeerID != null)
                message.RemotePeerID = String(object.RemotePeerID);
            if (object.ArgumentsP2PPart != null) {
                if (typeof object.ArgumentsP2PPart !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsP2PPart: object expected");
                message.ArgumentsP2PPart = $root.manager.ArgumentsP2PPart.fromObject(object.ArgumentsP2PPart);
            }
            if (object.ArgumentsP2PReq != null) {
                if (typeof object.ArgumentsP2PReq !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsP2PReq: object expected");
                message.ArgumentsP2PReq = $root.manager.ArgumentsP2PReq.fromObject(object.ArgumentsP2PReq);
            }
            if (object.ArgumentsP2PStats != null) {
                if (typeof object.ArgumentsP2PStats !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsP2PStats: object expected");
                message.ArgumentsP2PStats = $root.manager.PeeringStats.fromObject(object.ArgumentsP2PStats);
            }
            if (object.ArgumentsPeerInfo != null) {
                if (typeof object.ArgumentsPeerInfo !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsPeerInfo: object expected");
                message.ArgumentsPeerInfo = $root.manager.ArgumentsPeerInfo.fromObject(object.ArgumentsPeerInfo);
            }
            if (object.ArgumentsPeerVisit != null) {
                if (typeof object.ArgumentsPeerVisit !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsPeerVisit: object expected");
                message.ArgumentsPeerVisit = $root.manager.PeerVisit.fromObject(object.ArgumentsPeerVisit);
            }
            if (object.ArgumentsRegistered != null) {
                if (typeof object.ArgumentsRegistered !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsRegistered: object expected");
                message.ArgumentsRegistered = $root.manager.ArgumentsRegistered.fromObject(object.ArgumentsRegistered);
            }
            if (object.ArgumentsResourceDownloaded != null) {
                if (typeof object.ArgumentsResourceDownloaded !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsResourceDownloaded: object expected");
                message.ArgumentsResourceDownloaded = $root.manager.ResourceExchanges.fromObject(object.ArgumentsResourceDownloaded);
            }
            if (object.ArgumentsResourceExchanges != null) {
                if (typeof object.ArgumentsResourceExchanges !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsResourceExchanges: object expected");
                message.ArgumentsResourceExchanges = $root.manager.ArgumentsResourceExchanges.fromObject(object.ArgumentsResourceExchanges);
            }
            if (object.ArgumentsResourceUploaded != null) {
                if (typeof object.ArgumentsResourceUploaded !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsResourceUploaded: object expected");
                message.ArgumentsResourceUploaded = $root.manager.ResourceExchanges.fromObject(object.ArgumentsResourceUploaded);
            }
            if (object.ArgumentsPlayerInfo != null) {
                if (typeof object.ArgumentsPlayerInfo !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsPlayerInfo: object expected");
                message.ArgumentsPlayerInfo = $root.manager.ArgumentsPlayerInfo.fromObject(object.ArgumentsPlayerInfo);
            }
            if (object.ArgumentsSwarmSizeUpdate != null) {
                if (typeof object.ArgumentsSwarmSizeUpdate !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsSwarmSizeUpdate: object expected");
                message.ArgumentsSwarmSizeUpdate = $root.manager.ArgumentsSwarmSizeUpdate.fromObject(object.ArgumentsSwarmSizeUpdate);
            }
            if (object.ArgumentsSlotUpdate != null) {
                if (typeof object.ArgumentsSlotUpdate !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsSlotUpdate: object expected");
                message.ArgumentsSlotUpdate = $root.manager.SlotInfo.fromObject(object.ArgumentsSlotUpdate);
            }
            if (object.ArgumentsConf != null) {
                if (typeof object.ArgumentsConf !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsConf: object expected");
                message.ArgumentsConf = $root.manager.ArgumentsConf.fromObject(object.ArgumentsConf);
            }
            if (object.ArgumentsPeerState != null) {
                if (typeof object.ArgumentsPeerState !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsPeerState: object expected");
                message.ArgumentsPeerState = $root.manager.PeerState.fromObject(object.ArgumentsPeerState);
            }
            if (object.ArgumentsPlayerState != null) {
                if (typeof object.ArgumentsPlayerState !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsPlayerState: object expected");
                message.ArgumentsPlayerState = $root.manager.PlayerState.fromObject(object.ArgumentsPlayerState);
            }
            if (object.ArgumentsNetworkInfo != null) {
                if (typeof object.ArgumentsNetworkInfo !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsNetworkInfo: object expected");
                message.ArgumentsNetworkInfo = $root.manager.NetworkInfo.fromObject(object.ArgumentsNetworkInfo);
            }
            if (object.ArgumentsPingPongExchange != null) {
                if (typeof object.ArgumentsPingPongExchange !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsPingPongExchange: object expected");
                message.ArgumentsPingPongExchange = $root.manager.PingPongExchange.fromObject(object.ArgumentsPingPongExchange);
            }
            if (object.ArgumentsResourceAvailability != null) {
                if (typeof object.ArgumentsResourceAvailability !== "object")
                    throw TypeError(".manager.ArgumentsEvent.ArgumentsResourceAvailability: object expected");
                message.ArgumentsResourceAvailability = $root.manager.ResourceAvailability.fromObject(object.ArgumentsResourceAvailability);
            }
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsEvent
         * @static
         * @param {manager.ArgumentsEvent} message ArgumentsEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.EventName = options.enums === String ? "RTC" : 0;
                object.Action = options.enums === String ? "INIT" : 0;
                object.PeerID = "";
                object.RemotePeerID = "";
            }
            if (message.EventName != null && message.hasOwnProperty("EventName"))
                object.EventName = options.enums === String ? $root.manager.ArgumentsEvent.EventClass[message.EventName] : message.EventName;
            if (message.Action != null && message.hasOwnProperty("Action"))
                object.Action = options.enums === String ? $root.manager.ArgumentsEvent.Actions[message.Action] : message.Action;
            if (message.PeerID != null && message.hasOwnProperty("PeerID"))
                object.PeerID = message.PeerID;
            if (message.RemotePeerID != null && message.hasOwnProperty("RemotePeerID"))
                object.RemotePeerID = message.RemotePeerID;
            if (message.ArgumentsP2PPart != null && message.hasOwnProperty("ArgumentsP2PPart")) {
                object.ArgumentsP2PPart = $root.manager.ArgumentsP2PPart.toObject(message.ArgumentsP2PPart, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsP2PPart";
            }
            if (message.ArgumentsP2PReq != null && message.hasOwnProperty("ArgumentsP2PReq")) {
                object.ArgumentsP2PReq = $root.manager.ArgumentsP2PReq.toObject(message.ArgumentsP2PReq, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsP2PReq";
            }
            if (message.ArgumentsPeerInfo != null && message.hasOwnProperty("ArgumentsPeerInfo")) {
                object.ArgumentsPeerInfo = $root.manager.ArgumentsPeerInfo.toObject(message.ArgumentsPeerInfo, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsPeerInfo";
            }
            if (message.ArgumentsP2PStats != null && message.hasOwnProperty("ArgumentsP2PStats")) {
                object.ArgumentsP2PStats = $root.manager.PeeringStats.toObject(message.ArgumentsP2PStats, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsP2PStats";
            }
            if (message.ArgumentsRegistered != null && message.hasOwnProperty("ArgumentsRegistered")) {
                object.ArgumentsRegistered = $root.manager.ArgumentsRegistered.toObject(message.ArgumentsRegistered, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsRegistered";
            }
            if (message.ArgumentsPeerVisit != null && message.hasOwnProperty("ArgumentsPeerVisit")) {
                object.ArgumentsPeerVisit = $root.manager.PeerVisit.toObject(message.ArgumentsPeerVisit, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsPeerVisit";
            }
            if (message.ArgumentsResourceDownloaded != null && message.hasOwnProperty("ArgumentsResourceDownloaded")) {
                object.ArgumentsResourceDownloaded = $root.manager.ResourceExchanges.toObject(message.ArgumentsResourceDownloaded, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsResourceDownloaded";
            }
            if (message.ArgumentsPlayerInfo != null && message.hasOwnProperty("ArgumentsPlayerInfo")) {
                object.ArgumentsPlayerInfo = $root.manager.ArgumentsPlayerInfo.toObject(message.ArgumentsPlayerInfo, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsPlayerInfo";
            }
            if (message.ArgumentsResourceUploaded != null && message.hasOwnProperty("ArgumentsResourceUploaded")) {
                object.ArgumentsResourceUploaded = $root.manager.ResourceExchanges.toObject(message.ArgumentsResourceUploaded, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsResourceUploaded";
            }
            if (message.ArgumentsSwarmSizeUpdate != null && message.hasOwnProperty("ArgumentsSwarmSizeUpdate")) {
                object.ArgumentsSwarmSizeUpdate = $root.manager.ArgumentsSwarmSizeUpdate.toObject(message.ArgumentsSwarmSizeUpdate, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsSwarmSizeUpdate";
            }
            if (message.ArgumentsSlotUpdate != null && message.hasOwnProperty("ArgumentsSlotUpdate")) {
                object.ArgumentsSlotUpdate = $root.manager.SlotInfo.toObject(message.ArgumentsSlotUpdate, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsSlotUpdate";
            }
            if (message.ArgumentsConf != null && message.hasOwnProperty("ArgumentsConf")) {
                object.ArgumentsConf = $root.manager.ArgumentsConf.toObject(message.ArgumentsConf, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsConf";
            }
            if (message.ArgumentsPeerState != null && message.hasOwnProperty("ArgumentsPeerState")) {
                object.ArgumentsPeerState = $root.manager.PeerState.toObject(message.ArgumentsPeerState, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsPeerState";
            }
            if (message.ArgumentsPlayerState != null && message.hasOwnProperty("ArgumentsPlayerState")) {
                object.ArgumentsPlayerState = $root.manager.PlayerState.toObject(message.ArgumentsPlayerState, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsPlayerState";
            }
            if (message.ArgumentsNetworkInfo != null && message.hasOwnProperty("ArgumentsNetworkInfo")) {
                object.ArgumentsNetworkInfo = $root.manager.NetworkInfo.toObject(message.ArgumentsNetworkInfo, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsNetworkInfo";
            }
            if (message.ArgumentsPingPongExchange != null && message.hasOwnProperty("ArgumentsPingPongExchange")) {
                object.ArgumentsPingPongExchange = $root.manager.PingPongExchange.toObject(message.ArgumentsPingPongExchange, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsPingPongExchange";
            }
            if (message.ArgumentsResourceAvailability != null && message.hasOwnProperty("ArgumentsResourceAvailability")) {
                object.ArgumentsResourceAvailability = $root.manager.ResourceAvailability.toObject(message.ArgumentsResourceAvailability, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsResourceAvailability";
            }
            if (message.ArgumentsResourceExchanges != null && message.hasOwnProperty("ArgumentsResourceExchanges")) {
                object.ArgumentsResourceExchanges = $root.manager.ArgumentsResourceExchanges.toObject(message.ArgumentsResourceExchanges, options);
                if (options.oneofs)
                    object.Additional = "ArgumentsResourceExchanges";
            }
            return object;
        };

        /**
         * Converts this ArgumentsEvent to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * EventClass enum.
         * @name manager.ArgumentsEvent.EventClass
         * @enum {string}
         * @property {number} RTC=0 RTC value
         * @property {number} PEER=1 PEER value
         * @property {number} RESOURCE=2 RESOURCE value
         * @property {number} PLAYER=3 PLAYER value
         * @property {number} P2P=4 P2P value
         * @property {number} SWARM=5 SWARM value
         * @property {number} LIB=6 LIB value
         * @property {number} NETWORK=7 NETWORK value
         * @property {number} PINGPONG=8 PINGPONG value
         */
        ArgumentsEvent.EventClass = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "RTC"] = 0;
            values[valuesById[1] = "PEER"] = 1;
            values[valuesById[2] = "RESOURCE"] = 2;
            values[valuesById[3] = "PLAYER"] = 3;
            values[valuesById[4] = "P2P"] = 4;
            values[valuesById[5] = "SWARM"] = 5;
            values[valuesById[6] = "LIB"] = 6;
            values[valuesById[7] = "NETWORK"] = 7;
            values[valuesById[8] = "PINGPONG"] = 8;
            return values;
        })();

        /**
         * Actions enum.
         * @name manager.ArgumentsEvent.Actions
         * @enum {string}
         * @property {number} INIT=0 INIT value
         * @property {number} OPEN=1 OPEN value
         * @property {number} CLOSE=2 CLOSE value
         * @property {number} SEND=10 SEND value
         * @property {number} RCVD=11 RCVD value
         * @property {number} BUSY=13 BUSY value
         * @property {number} REQUEST=14 REQUEST value
         * @property {number} STATS=141 STATS value
         * @property {number} DOWNLOADED=12 DOWNLOADED value
         * @property {number} EXCHANGE=121 EXCHANGE value
         * @property {number} UPLOADED=15 UPLOADED value
         * @property {number} SLOTUPDATE=16 SLOTUPDATE value
         * @property {number} STORAGE=17 STORAGE value
         * @property {number} AVAILABILITY=18 AVAILABILITY value
         * @property {number} INFO=20 INFO value
         * @property {number} REGISTERED=201 REGISTERED value
         * @property {number} VISIT=202 VISIT value
         * @property {number} STATE=203 STATE value
         * @property {number} PLAY=21 PLAY value
         * @property {number} PAUSE=22 PAUSE value
         * @property {number} REBUFFER=23 REBUFFER value
         * @property {number} STOP=24 STOP value
         * @property {number} FLUCTUATE=211 FLUCTUATE value
         * @property {number} SIZEUPDATE=25 SIZEUPDATE value
         * @property {number} CONF=26 CONF value
         */
        ArgumentsEvent.Actions = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INIT"] = 0;
            values[valuesById[1] = "OPEN"] = 1;
            values[valuesById[2] = "CLOSE"] = 2;
            values[valuesById[10] = "SEND"] = 10;
            values[valuesById[11] = "RCVD"] = 11;
            values[valuesById[13] = "BUSY"] = 13;
            values[valuesById[14] = "REQUEST"] = 14;
            values[valuesById[141] = "STATS"] = 141;
            values[valuesById[12] = "DOWNLOADED"] = 12;
            values[valuesById[121] = "EXCHANGE"] = 121;
            values[valuesById[15] = "UPLOADED"] = 15;
            values[valuesById[16] = "SLOTUPDATE"] = 16;
            values[valuesById[17] = "STORAGE"] = 17;
            values[valuesById[18] = "AVAILABILITY"] = 18;
            values[valuesById[20] = "INFO"] = 20;
            values[valuesById[201] = "REGISTERED"] = 201;
            values[valuesById[202] = "VISIT"] = 202;
            values[valuesById[203] = "STATE"] = 203;
            values[valuesById[21] = "PLAY"] = 21;
            values[valuesById[22] = "PAUSE"] = 22;
            values[valuesById[23] = "REBUFFER"] = 23;
            values[valuesById[24] = "STOP"] = 24;
            values[valuesById[211] = "FLUCTUATE"] = 211;
            values[valuesById[25] = "SIZEUPDATE"] = 25;
            values[valuesById[26] = "CONF"] = 26;
            return values;
        })();

        return ArgumentsEvent;
    })();

    manager.ArgumentsP2PReq = (function() {

        /**
         * Properties of an ArgumentsP2PReq.
         * @memberof manager
         * @interface IArgumentsP2PReq
         * @property {string|null} [URL] ArgumentsP2PReq URL
         * @property {string|null} [ToPeerID] ArgumentsP2PReq ToPeerID
         * @property {number|null} [Timeout] ArgumentsP2PReq Timeout
         */

        /**
         * Constructs a new ArgumentsP2PReq.
         * @memberof manager
         * @classdesc Represents an ArgumentsP2PReq.
         * @implements IArgumentsP2PReq
         * @constructor
         * @param {manager.IArgumentsP2PReq=} [properties] Properties to set
         */
        function ArgumentsP2PReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsP2PReq URL.
         * @member {string} URL
         * @memberof manager.ArgumentsP2PReq
         * @instance
         */
        ArgumentsP2PReq.prototype.URL = "";

        /**
         * ArgumentsP2PReq ToPeerID.
         * @member {string} ToPeerID
         * @memberof manager.ArgumentsP2PReq
         * @instance
         */
        ArgumentsP2PReq.prototype.ToPeerID = "";

        /**
         * ArgumentsP2PReq Timeout.
         * @member {number} Timeout
         * @memberof manager.ArgumentsP2PReq
         * @instance
         */
        ArgumentsP2PReq.prototype.Timeout = 0;

        /**
         * Creates a new ArgumentsP2PReq instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsP2PReq
         * @static
         * @param {manager.IArgumentsP2PReq=} [properties] Properties to set
         * @returns {manager.ArgumentsP2PReq} ArgumentsP2PReq instance
         */
        ArgumentsP2PReq.create = function create(properties) {
            return new ArgumentsP2PReq(properties);
        };

        /**
         * Encodes the specified ArgumentsP2PReq message. Does not implicitly {@link manager.ArgumentsP2PReq.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsP2PReq
         * @static
         * @param {manager.IArgumentsP2PReq} message ArgumentsP2PReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsP2PReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.URL != null && message.hasOwnProperty("URL"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.URL);
            if (message.ToPeerID != null && message.hasOwnProperty("ToPeerID"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.ToPeerID);
            if (message.Timeout != null && message.hasOwnProperty("Timeout"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.Timeout);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsP2PReq message, length delimited. Does not implicitly {@link manager.ArgumentsP2PReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsP2PReq
         * @static
         * @param {manager.IArgumentsP2PReq} message ArgumentsP2PReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsP2PReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsP2PReq message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsP2PReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsP2PReq} ArgumentsP2PReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsP2PReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsP2PReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.URL = reader.string();
                    break;
                case 2:
                    message.ToPeerID = reader.string();
                    break;
                case 3:
                    message.Timeout = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsP2PReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsP2PReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsP2PReq} ArgumentsP2PReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsP2PReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsP2PReq message.
         * @function verify
         * @memberof manager.ArgumentsP2PReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsP2PReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.URL != null && message.hasOwnProperty("URL"))
                if (!$util.isString(message.URL))
                    return "URL: string expected";
            if (message.ToPeerID != null && message.hasOwnProperty("ToPeerID"))
                if (!$util.isString(message.ToPeerID))
                    return "ToPeerID: string expected";
            if (message.Timeout != null && message.hasOwnProperty("Timeout"))
                if (!$util.isInteger(message.Timeout))
                    return "Timeout: integer expected";
            return null;
        };

        /**
         * Creates an ArgumentsP2PReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsP2PReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsP2PReq} ArgumentsP2PReq
         */
        ArgumentsP2PReq.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsP2PReq)
                return object;
            var message = new $root.manager.ArgumentsP2PReq();
            if (object.URL != null)
                message.URL = String(object.URL);
            if (object.ToPeerID != null)
                message.ToPeerID = String(object.ToPeerID);
            if (object.Timeout != null)
                message.Timeout = object.Timeout >>> 0;
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsP2PReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsP2PReq
         * @static
         * @param {manager.ArgumentsP2PReq} message ArgumentsP2PReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsP2PReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.URL = "";
                object.ToPeerID = "";
                object.Timeout = 0;
            }
            if (message.URL != null && message.hasOwnProperty("URL"))
                object.URL = message.URL;
            if (message.ToPeerID != null && message.hasOwnProperty("ToPeerID"))
                object.ToPeerID = message.ToPeerID;
            if (message.Timeout != null && message.hasOwnProperty("Timeout"))
                object.Timeout = message.Timeout;
            return object;
        };

        /**
         * Converts this ArgumentsP2PReq to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsP2PReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsP2PReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsP2PReq;
    })();

    manager.ArgumentsSwarmSizeUpdate = (function() {

        /**
         * Properties of an ArgumentsSwarmSizeUpdate.
         * @memberof manager
         * @interface IArgumentsSwarmSizeUpdate
         * @property {number|null} [Size] ArgumentsSwarmSizeUpdate Size
         */

        /**
         * Constructs a new ArgumentsSwarmSizeUpdate.
         * @memberof manager
         * @classdesc Represents an ArgumentsSwarmSizeUpdate.
         * @implements IArgumentsSwarmSizeUpdate
         * @constructor
         * @param {manager.IArgumentsSwarmSizeUpdate=} [properties] Properties to set
         */
        function ArgumentsSwarmSizeUpdate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsSwarmSizeUpdate Size.
         * @member {number} Size
         * @memberof manager.ArgumentsSwarmSizeUpdate
         * @instance
         */
        ArgumentsSwarmSizeUpdate.prototype.Size = 0;

        /**
         * Creates a new ArgumentsSwarmSizeUpdate instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsSwarmSizeUpdate
         * @static
         * @param {manager.IArgumentsSwarmSizeUpdate=} [properties] Properties to set
         * @returns {manager.ArgumentsSwarmSizeUpdate} ArgumentsSwarmSizeUpdate instance
         */
        ArgumentsSwarmSizeUpdate.create = function create(properties) {
            return new ArgumentsSwarmSizeUpdate(properties);
        };

        /**
         * Encodes the specified ArgumentsSwarmSizeUpdate message. Does not implicitly {@link manager.ArgumentsSwarmSizeUpdate.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsSwarmSizeUpdate
         * @static
         * @param {manager.IArgumentsSwarmSizeUpdate} message ArgumentsSwarmSizeUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsSwarmSizeUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Size != null && message.hasOwnProperty("Size"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.Size);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsSwarmSizeUpdate message, length delimited. Does not implicitly {@link manager.ArgumentsSwarmSizeUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsSwarmSizeUpdate
         * @static
         * @param {manager.IArgumentsSwarmSizeUpdate} message ArgumentsSwarmSizeUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsSwarmSizeUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsSwarmSizeUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsSwarmSizeUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsSwarmSizeUpdate} ArgumentsSwarmSizeUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsSwarmSizeUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsSwarmSizeUpdate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Size = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsSwarmSizeUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsSwarmSizeUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsSwarmSizeUpdate} ArgumentsSwarmSizeUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsSwarmSizeUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsSwarmSizeUpdate message.
         * @function verify
         * @memberof manager.ArgumentsSwarmSizeUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsSwarmSizeUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Size != null && message.hasOwnProperty("Size"))
                if (!$util.isInteger(message.Size))
                    return "Size: integer expected";
            return null;
        };

        /**
         * Creates an ArgumentsSwarmSizeUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsSwarmSizeUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsSwarmSizeUpdate} ArgumentsSwarmSizeUpdate
         */
        ArgumentsSwarmSizeUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsSwarmSizeUpdate)
                return object;
            var message = new $root.manager.ArgumentsSwarmSizeUpdate();
            if (object.Size != null)
                message.Size = object.Size >>> 0;
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsSwarmSizeUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsSwarmSizeUpdate
         * @static
         * @param {manager.ArgumentsSwarmSizeUpdate} message ArgumentsSwarmSizeUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsSwarmSizeUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.Size = 0;
            if (message.Size != null && message.hasOwnProperty("Size"))
                object.Size = message.Size;
            return object;
        };

        /**
         * Converts this ArgumentsSwarmSizeUpdate to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsSwarmSizeUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsSwarmSizeUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsSwarmSizeUpdate;
    })();

    manager.SlotInfo = (function() {

        /**
         * Properties of a SlotInfo.
         * @memberof manager
         * @interface ISlotInfo
         * @property {number|null} [FreeSlot] SlotInfo FreeSlot
         * @property {number|null} [OccupiedSlot] SlotInfo OccupiedSlot
         */

        /**
         * Constructs a new SlotInfo.
         * @memberof manager
         * @classdesc Represents a SlotInfo.
         * @implements ISlotInfo
         * @constructor
         * @param {manager.ISlotInfo=} [properties] Properties to set
         */
        function SlotInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SlotInfo FreeSlot.
         * @member {number} FreeSlot
         * @memberof manager.SlotInfo
         * @instance
         */
        SlotInfo.prototype.FreeSlot = 0;

        /**
         * SlotInfo OccupiedSlot.
         * @member {number} OccupiedSlot
         * @memberof manager.SlotInfo
         * @instance
         */
        SlotInfo.prototype.OccupiedSlot = 0;

        /**
         * Creates a new SlotInfo instance using the specified properties.
         * @function create
         * @memberof manager.SlotInfo
         * @static
         * @param {manager.ISlotInfo=} [properties] Properties to set
         * @returns {manager.SlotInfo} SlotInfo instance
         */
        SlotInfo.create = function create(properties) {
            return new SlotInfo(properties);
        };

        /**
         * Encodes the specified SlotInfo message. Does not implicitly {@link manager.SlotInfo.verify|verify} messages.
         * @function encode
         * @memberof manager.SlotInfo
         * @static
         * @param {manager.ISlotInfo} message SlotInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SlotInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.FreeSlot != null && message.hasOwnProperty("FreeSlot"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.FreeSlot);
            if (message.OccupiedSlot != null && message.hasOwnProperty("OccupiedSlot"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.OccupiedSlot);
            return writer;
        };

        /**
         * Encodes the specified SlotInfo message, length delimited. Does not implicitly {@link manager.SlotInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.SlotInfo
         * @static
         * @param {manager.ISlotInfo} message SlotInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SlotInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SlotInfo message from the specified reader or buffer.
         * @function decode
         * @memberof manager.SlotInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.SlotInfo} SlotInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SlotInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.SlotInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.FreeSlot = reader.uint32();
                    break;
                case 2:
                    message.OccupiedSlot = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SlotInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.SlotInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.SlotInfo} SlotInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SlotInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SlotInfo message.
         * @function verify
         * @memberof manager.SlotInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SlotInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.FreeSlot != null && message.hasOwnProperty("FreeSlot"))
                if (!$util.isInteger(message.FreeSlot))
                    return "FreeSlot: integer expected";
            if (message.OccupiedSlot != null && message.hasOwnProperty("OccupiedSlot"))
                if (!$util.isInteger(message.OccupiedSlot))
                    return "OccupiedSlot: integer expected";
            return null;
        };

        /**
         * Creates a SlotInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.SlotInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.SlotInfo} SlotInfo
         */
        SlotInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.SlotInfo)
                return object;
            var message = new $root.manager.SlotInfo();
            if (object.FreeSlot != null)
                message.FreeSlot = object.FreeSlot >>> 0;
            if (object.OccupiedSlot != null)
                message.OccupiedSlot = object.OccupiedSlot >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a SlotInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.SlotInfo
         * @static
         * @param {manager.SlotInfo} message SlotInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SlotInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.FreeSlot = 0;
                object.OccupiedSlot = 0;
            }
            if (message.FreeSlot != null && message.hasOwnProperty("FreeSlot"))
                object.FreeSlot = message.FreeSlot;
            if (message.OccupiedSlot != null && message.hasOwnProperty("OccupiedSlot"))
                object.OccupiedSlot = message.OccupiedSlot;
            return object;
        };

        /**
         * Converts this SlotInfo to JSON.
         * @function toJSON
         * @memberof manager.SlotInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SlotInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SlotInfo;
    })();

    manager.ArgumentsPeerInfo = (function() {

        /**
         * Properties of an ArgumentsPeerInfo.
         * @memberof manager
         * @interface IArgumentsPeerInfo
         * @property {boolean|null} [Radio] ArgumentsPeerInfo Radio
         * @property {boolean|null} [VOD] ArgumentsPeerInfo VOD
         * @property {string|null} [OS] ArgumentsPeerInfo OS
         * @property {string|null} [Browser] ArgumentsPeerInfo Browser
         * @property {string|null} [StreamProto] ArgumentsPeerInfo StreamProto
         * @property {number|null} [StartUpTime] ArgumentsPeerInfo StartUpTime
         * @property {string|null} [Content] ArgumentsPeerInfo Content
         * @property {string|null} [Origin] ArgumentsPeerInfo Origin
         * @property {string|null} [Version] ArgumentsPeerInfo Version
         * @property {number|Long|null} [Timezone] ArgumentsPeerInfo Timezone
         */

        /**
         * Constructs a new ArgumentsPeerInfo.
         * @memberof manager
         * @classdesc Represents an ArgumentsPeerInfo.
         * @implements IArgumentsPeerInfo
         * @constructor
         * @param {manager.IArgumentsPeerInfo=} [properties] Properties to set
         */
        function ArgumentsPeerInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsPeerInfo Radio.
         * @member {boolean} Radio
         * @memberof manager.ArgumentsPeerInfo
         * @instance
         */
        ArgumentsPeerInfo.prototype.Radio = false;

        /**
         * ArgumentsPeerInfo VOD.
         * @member {boolean} VOD
         * @memberof manager.ArgumentsPeerInfo
         * @instance
         */
        ArgumentsPeerInfo.prototype.VOD = false;

        /**
         * ArgumentsPeerInfo OS.
         * @member {string} OS
         * @memberof manager.ArgumentsPeerInfo
         * @instance
         */
        ArgumentsPeerInfo.prototype.OS = "";

        /**
         * ArgumentsPeerInfo Browser.
         * @member {string} Browser
         * @memberof manager.ArgumentsPeerInfo
         * @instance
         */
        ArgumentsPeerInfo.prototype.Browser = "";

        /**
         * ArgumentsPeerInfo StreamProto.
         * @member {string} StreamProto
         * @memberof manager.ArgumentsPeerInfo
         * @instance
         */
        ArgumentsPeerInfo.prototype.StreamProto = "";

        /**
         * ArgumentsPeerInfo StartUpTime.
         * @member {number} StartUpTime
         * @memberof manager.ArgumentsPeerInfo
         * @instance
         */
        ArgumentsPeerInfo.prototype.StartUpTime = 0;

        /**
         * ArgumentsPeerInfo Content.
         * @member {string} Content
         * @memberof manager.ArgumentsPeerInfo
         * @instance
         */
        ArgumentsPeerInfo.prototype.Content = "";

        /**
         * ArgumentsPeerInfo Origin.
         * @member {string} Origin
         * @memberof manager.ArgumentsPeerInfo
         * @instance
         */
        ArgumentsPeerInfo.prototype.Origin = "";

        /**
         * ArgumentsPeerInfo Version.
         * @member {string} Version
         * @memberof manager.ArgumentsPeerInfo
         * @instance
         */
        ArgumentsPeerInfo.prototype.Version = "";

        /**
         * ArgumentsPeerInfo Timezone.
         * @member {number|Long} Timezone
         * @memberof manager.ArgumentsPeerInfo
         * @instance
         */
        ArgumentsPeerInfo.prototype.Timezone = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ArgumentsPeerInfo instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsPeerInfo
         * @static
         * @param {manager.IArgumentsPeerInfo=} [properties] Properties to set
         * @returns {manager.ArgumentsPeerInfo} ArgumentsPeerInfo instance
         */
        ArgumentsPeerInfo.create = function create(properties) {
            return new ArgumentsPeerInfo(properties);
        };

        /**
         * Encodes the specified ArgumentsPeerInfo message. Does not implicitly {@link manager.ArgumentsPeerInfo.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsPeerInfo
         * @static
         * @param {manager.IArgumentsPeerInfo} message ArgumentsPeerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsPeerInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Radio != null && message.hasOwnProperty("Radio"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.Radio);
            if (message.VOD != null && message.hasOwnProperty("VOD"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.VOD);
            if (message.OS != null && message.hasOwnProperty("OS"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.OS);
            if (message.Browser != null && message.hasOwnProperty("Browser"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.Browser);
            if (message.StreamProto != null && message.hasOwnProperty("StreamProto"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.StreamProto);
            if (message.StartUpTime != null && message.hasOwnProperty("StartUpTime"))
                writer.uint32(/* id 7, wireType 5 =*/61).float(message.StartUpTime);
            if (message.Content != null && message.hasOwnProperty("Content"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.Content);
            if (message.Origin != null && message.hasOwnProperty("Origin"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.Origin);
            if (message.Version != null && message.hasOwnProperty("Version"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.Version);
            if (message.Timezone != null && message.hasOwnProperty("Timezone"))
                writer.uint32(/* id 11, wireType 0 =*/88).int64(message.Timezone);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsPeerInfo message, length delimited. Does not implicitly {@link manager.ArgumentsPeerInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsPeerInfo
         * @static
         * @param {manager.IArgumentsPeerInfo} message ArgumentsPeerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsPeerInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsPeerInfo message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsPeerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsPeerInfo} ArgumentsPeerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsPeerInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsPeerInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.Radio = reader.bool();
                    break;
                case 3:
                    message.VOD = reader.bool();
                    break;
                case 4:
                    message.OS = reader.string();
                    break;
                case 5:
                    message.Browser = reader.string();
                    break;
                case 6:
                    message.StreamProto = reader.string();
                    break;
                case 7:
                    message.StartUpTime = reader.float();
                    break;
                case 8:
                    message.Content = reader.string();
                    break;
                case 9:
                    message.Origin = reader.string();
                    break;
                case 10:
                    message.Version = reader.string();
                    break;
                case 11:
                    message.Timezone = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsPeerInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsPeerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsPeerInfo} ArgumentsPeerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsPeerInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsPeerInfo message.
         * @function verify
         * @memberof manager.ArgumentsPeerInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsPeerInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Radio != null && message.hasOwnProperty("Radio"))
                if (typeof message.Radio !== "boolean")
                    return "Radio: boolean expected";
            if (message.VOD != null && message.hasOwnProperty("VOD"))
                if (typeof message.VOD !== "boolean")
                    return "VOD: boolean expected";
            if (message.OS != null && message.hasOwnProperty("OS"))
                if (!$util.isString(message.OS))
                    return "OS: string expected";
            if (message.Browser != null && message.hasOwnProperty("Browser"))
                if (!$util.isString(message.Browser))
                    return "Browser: string expected";
            if (message.StreamProto != null && message.hasOwnProperty("StreamProto"))
                if (!$util.isString(message.StreamProto))
                    return "StreamProto: string expected";
            if (message.StartUpTime != null && message.hasOwnProperty("StartUpTime"))
                if (typeof message.StartUpTime !== "number")
                    return "StartUpTime: number expected";
            if (message.Content != null && message.hasOwnProperty("Content"))
                if (!$util.isString(message.Content))
                    return "Content: string expected";
            if (message.Origin != null && message.hasOwnProperty("Origin"))
                if (!$util.isString(message.Origin))
                    return "Origin: string expected";
            if (message.Version != null && message.hasOwnProperty("Version"))
                if (!$util.isString(message.Version))
                    return "Version: string expected";
            if (message.Timezone != null && message.hasOwnProperty("Timezone"))
                if (!$util.isInteger(message.Timezone) && !(message.Timezone && $util.isInteger(message.Timezone.low) && $util.isInteger(message.Timezone.high)))
                    return "Timezone: integer|Long expected";
            return null;
        };

        /**
         * Creates an ArgumentsPeerInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsPeerInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsPeerInfo} ArgumentsPeerInfo
         */
        ArgumentsPeerInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsPeerInfo)
                return object;
            var message = new $root.manager.ArgumentsPeerInfo();
            if (object.Radio != null)
                message.Radio = Boolean(object.Radio);
            if (object.VOD != null)
                message.VOD = Boolean(object.VOD);
            if (object.OS != null)
                message.OS = String(object.OS);
            if (object.Browser != null)
                message.Browser = String(object.Browser);
            if (object.StreamProto != null)
                message.StreamProto = String(object.StreamProto);
            if (object.StartUpTime != null)
                message.StartUpTime = Number(object.StartUpTime);
            if (object.Content != null)
                message.Content = String(object.Content);
            if (object.Origin != null)
                message.Origin = String(object.Origin);
            if (object.Version != null)
                message.Version = String(object.Version);
            if (object.Timezone != null)
                if ($util.Long)
                    (message.Timezone = $util.Long.fromValue(object.Timezone)).unsigned = false;
                else if (typeof object.Timezone === "string")
                    message.Timezone = parseInt(object.Timezone, 10);
                else if (typeof object.Timezone === "number")
                    message.Timezone = object.Timezone;
                else if (typeof object.Timezone === "object")
                    message.Timezone = new $util.LongBits(object.Timezone.low >>> 0, object.Timezone.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsPeerInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsPeerInfo
         * @static
         * @param {manager.ArgumentsPeerInfo} message ArgumentsPeerInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsPeerInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.Radio = false;
                object.VOD = false;
                object.OS = "";
                object.Browser = "";
                object.StreamProto = "";
                object.StartUpTime = 0;
                object.Content = "";
                object.Origin = "";
                object.Version = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.Timezone = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.Timezone = options.longs === String ? "0" : 0;
            }
            if (message.Radio != null && message.hasOwnProperty("Radio"))
                object.Radio = message.Radio;
            if (message.VOD != null && message.hasOwnProperty("VOD"))
                object.VOD = message.VOD;
            if (message.OS != null && message.hasOwnProperty("OS"))
                object.OS = message.OS;
            if (message.Browser != null && message.hasOwnProperty("Browser"))
                object.Browser = message.Browser;
            if (message.StreamProto != null && message.hasOwnProperty("StreamProto"))
                object.StreamProto = message.StreamProto;
            if (message.StartUpTime != null && message.hasOwnProperty("StartUpTime"))
                object.StartUpTime = options.json && !isFinite(message.StartUpTime) ? String(message.StartUpTime) : message.StartUpTime;
            if (message.Content != null && message.hasOwnProperty("Content"))
                object.Content = message.Content;
            if (message.Origin != null && message.hasOwnProperty("Origin"))
                object.Origin = message.Origin;
            if (message.Version != null && message.hasOwnProperty("Version"))
                object.Version = message.Version;
            if (message.Timezone != null && message.hasOwnProperty("Timezone"))
                if (typeof message.Timezone === "number")
                    object.Timezone = options.longs === String ? String(message.Timezone) : message.Timezone;
                else
                    object.Timezone = options.longs === String ? $util.Long.prototype.toString.call(message.Timezone) : options.longs === Number ? new $util.LongBits(message.Timezone.low >>> 0, message.Timezone.high >>> 0).toNumber() : message.Timezone;
            return object;
        };

        /**
         * Converts this ArgumentsPeerInfo to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsPeerInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsPeerInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsPeerInfo;
    })();

    manager.ArgumentsP2PPart = (function() {

        /**
         * Properties of an ArgumentsP2PPart.
         * @memberof manager
         * @interface IArgumentsP2PPart
         * @property {string|null} [URL] ArgumentsP2PPart URL
         * @property {number|null} [PartNum] ArgumentsP2PPart PartNum
         * @property {number|null} [TotalPart] ArgumentsP2PPart TotalPart
         * @property {number|null} [TimeSpent] ArgumentsP2PPart TimeSpent
         */

        /**
         * Constructs a new ArgumentsP2PPart.
         * @memberof manager
         * @classdesc Represents an ArgumentsP2PPart.
         * @implements IArgumentsP2PPart
         * @constructor
         * @param {manager.IArgumentsP2PPart=} [properties] Properties to set
         */
        function ArgumentsP2PPart(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsP2PPart URL.
         * @member {string} URL
         * @memberof manager.ArgumentsP2PPart
         * @instance
         */
        ArgumentsP2PPart.prototype.URL = "";

        /**
         * ArgumentsP2PPart PartNum.
         * @member {number} PartNum
         * @memberof manager.ArgumentsP2PPart
         * @instance
         */
        ArgumentsP2PPart.prototype.PartNum = 0;

        /**
         * ArgumentsP2PPart TotalPart.
         * @member {number} TotalPart
         * @memberof manager.ArgumentsP2PPart
         * @instance
         */
        ArgumentsP2PPart.prototype.TotalPart = 0;

        /**
         * ArgumentsP2PPart TimeSpent.
         * @member {number} TimeSpent
         * @memberof manager.ArgumentsP2PPart
         * @instance
         */
        ArgumentsP2PPart.prototype.TimeSpent = 0;

        /**
         * Creates a new ArgumentsP2PPart instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsP2PPart
         * @static
         * @param {manager.IArgumentsP2PPart=} [properties] Properties to set
         * @returns {manager.ArgumentsP2PPart} ArgumentsP2PPart instance
         */
        ArgumentsP2PPart.create = function create(properties) {
            return new ArgumentsP2PPart(properties);
        };

        /**
         * Encodes the specified ArgumentsP2PPart message. Does not implicitly {@link manager.ArgumentsP2PPart.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsP2PPart
         * @static
         * @param {manager.IArgumentsP2PPart} message ArgumentsP2PPart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsP2PPart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.URL != null && message.hasOwnProperty("URL"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.URL);
            if (message.PartNum != null && message.hasOwnProperty("PartNum"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.PartNum);
            if (message.TotalPart != null && message.hasOwnProperty("TotalPart"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.TotalPart);
            if (message.TimeSpent != null && message.hasOwnProperty("TimeSpent"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.TimeSpent);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsP2PPart message, length delimited. Does not implicitly {@link manager.ArgumentsP2PPart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsP2PPart
         * @static
         * @param {manager.IArgumentsP2PPart} message ArgumentsP2PPart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsP2PPart.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsP2PPart message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsP2PPart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsP2PPart} ArgumentsP2PPart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsP2PPart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsP2PPart();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.URL = reader.string();
                    break;
                case 3:
                    message.PartNum = reader.uint32();
                    break;
                case 4:
                    message.TotalPart = reader.uint32();
                    break;
                case 5:
                    message.TimeSpent = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsP2PPart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsP2PPart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsP2PPart} ArgumentsP2PPart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsP2PPart.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsP2PPart message.
         * @function verify
         * @memberof manager.ArgumentsP2PPart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsP2PPart.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.URL != null && message.hasOwnProperty("URL"))
                if (!$util.isString(message.URL))
                    return "URL: string expected";
            if (message.PartNum != null && message.hasOwnProperty("PartNum"))
                if (!$util.isInteger(message.PartNum))
                    return "PartNum: integer expected";
            if (message.TotalPart != null && message.hasOwnProperty("TotalPart"))
                if (!$util.isInteger(message.TotalPart))
                    return "TotalPart: integer expected";
            if (message.TimeSpent != null && message.hasOwnProperty("TimeSpent"))
                if (!$util.isInteger(message.TimeSpent))
                    return "TimeSpent: integer expected";
            return null;
        };

        /**
         * Creates an ArgumentsP2PPart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsP2PPart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsP2PPart} ArgumentsP2PPart
         */
        ArgumentsP2PPart.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsP2PPart)
                return object;
            var message = new $root.manager.ArgumentsP2PPart();
            if (object.URL != null)
                message.URL = String(object.URL);
            if (object.PartNum != null)
                message.PartNum = object.PartNum >>> 0;
            if (object.TotalPart != null)
                message.TotalPart = object.TotalPart >>> 0;
            if (object.TimeSpent != null)
                message.TimeSpent = object.TimeSpent >>> 0;
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsP2PPart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsP2PPart
         * @static
         * @param {manager.ArgumentsP2PPart} message ArgumentsP2PPart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsP2PPart.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.URL = "";
                object.PartNum = 0;
                object.TotalPart = 0;
                object.TimeSpent = 0;
            }
            if (message.URL != null && message.hasOwnProperty("URL"))
                object.URL = message.URL;
            if (message.PartNum != null && message.hasOwnProperty("PartNum"))
                object.PartNum = message.PartNum;
            if (message.TotalPart != null && message.hasOwnProperty("TotalPart"))
                object.TotalPart = message.TotalPart;
            if (message.TimeSpent != null && message.hasOwnProperty("TimeSpent"))
                object.TimeSpent = message.TimeSpent;
            return object;
        };

        /**
         * Converts this ArgumentsP2PPart to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsP2PPart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsP2PPart.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsP2PPart;
    })();

    manager.ArgumentsPlayerInfo = (function() {

        /**
         * Properties of an ArgumentsPlayerInfo.
         * @memberof manager
         * @interface IArgumentsPlayerInfo
         * @property {manager.PlayingState|null} [PlayingState] ArgumentsPlayerInfo PlayingState
         * @property {number|null} [WatchingTime] ArgumentsPlayerInfo WatchingTime
         * @property {number|null} [BufferLength] ArgumentsPlayerInfo BufferLength
         * @property {number|null} [Rebuffers] ArgumentsPlayerInfo Rebuffers
         * @property {number|null} [RebufferingTime] ArgumentsPlayerInfo RebufferingTime
         * @property {number|null} [BandwidthFluctuation] ArgumentsPlayerInfo BandwidthFluctuation
         * @property {number|null} [Bandwidth] ArgumentsPlayerInfo Bandwidth
         */

        /**
         * Constructs a new ArgumentsPlayerInfo.
         * @memberof manager
         * @classdesc Represents an ArgumentsPlayerInfo.
         * @implements IArgumentsPlayerInfo
         * @constructor
         * @param {manager.IArgumentsPlayerInfo=} [properties] Properties to set
         */
        function ArgumentsPlayerInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsPlayerInfo PlayingState.
         * @member {manager.PlayingState} PlayingState
         * @memberof manager.ArgumentsPlayerInfo
         * @instance
         */
        ArgumentsPlayerInfo.prototype.PlayingState = 0;

        /**
         * ArgumentsPlayerInfo WatchingTime.
         * @member {number} WatchingTime
         * @memberof manager.ArgumentsPlayerInfo
         * @instance
         */
        ArgumentsPlayerInfo.prototype.WatchingTime = 0;

        /**
         * ArgumentsPlayerInfo BufferLength.
         * @member {number} BufferLength
         * @memberof manager.ArgumentsPlayerInfo
         * @instance
         */
        ArgumentsPlayerInfo.prototype.BufferLength = 0;

        /**
         * ArgumentsPlayerInfo Rebuffers.
         * @member {number} Rebuffers
         * @memberof manager.ArgumentsPlayerInfo
         * @instance
         */
        ArgumentsPlayerInfo.prototype.Rebuffers = 0;

        /**
         * ArgumentsPlayerInfo RebufferingTime.
         * @member {number} RebufferingTime
         * @memberof manager.ArgumentsPlayerInfo
         * @instance
         */
        ArgumentsPlayerInfo.prototype.RebufferingTime = 0;

        /**
         * ArgumentsPlayerInfo BandwidthFluctuation.
         * @member {number} BandwidthFluctuation
         * @memberof manager.ArgumentsPlayerInfo
         * @instance
         */
        ArgumentsPlayerInfo.prototype.BandwidthFluctuation = 0;

        /**
         * ArgumentsPlayerInfo Bandwidth.
         * @member {number} Bandwidth
         * @memberof manager.ArgumentsPlayerInfo
         * @instance
         */
        ArgumentsPlayerInfo.prototype.Bandwidth = 0;

        /**
         * Creates a new ArgumentsPlayerInfo instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsPlayerInfo
         * @static
         * @param {manager.IArgumentsPlayerInfo=} [properties] Properties to set
         * @returns {manager.ArgumentsPlayerInfo} ArgumentsPlayerInfo instance
         */
        ArgumentsPlayerInfo.create = function create(properties) {
            return new ArgumentsPlayerInfo(properties);
        };

        /**
         * Encodes the specified ArgumentsPlayerInfo message. Does not implicitly {@link manager.ArgumentsPlayerInfo.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsPlayerInfo
         * @static
         * @param {manager.IArgumentsPlayerInfo} message ArgumentsPlayerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsPlayerInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.PlayingState != null && message.hasOwnProperty("PlayingState"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.PlayingState);
            if (message.WatchingTime != null && message.hasOwnProperty("WatchingTime"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.WatchingTime);
            if (message.BufferLength != null && message.hasOwnProperty("BufferLength"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.BufferLength);
            if (message.Rebuffers != null && message.hasOwnProperty("Rebuffers"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.Rebuffers);
            if (message.RebufferingTime != null && message.hasOwnProperty("RebufferingTime"))
                writer.uint32(/* id 5, wireType 5 =*/45).float(message.RebufferingTime);
            if (message.BandwidthFluctuation != null && message.hasOwnProperty("BandwidthFluctuation"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.BandwidthFluctuation);
            if (message.Bandwidth != null && message.hasOwnProperty("Bandwidth"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.Bandwidth);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsPlayerInfo message, length delimited. Does not implicitly {@link manager.ArgumentsPlayerInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsPlayerInfo
         * @static
         * @param {manager.IArgumentsPlayerInfo} message ArgumentsPlayerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsPlayerInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsPlayerInfo message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsPlayerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsPlayerInfo} ArgumentsPlayerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsPlayerInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsPlayerInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.PlayingState = reader.int32();
                    break;
                case 2:
                    message.WatchingTime = reader.float();
                    break;
                case 3:
                    message.BufferLength = reader.float();
                    break;
                case 4:
                    message.Rebuffers = reader.uint32();
                    break;
                case 5:
                    message.RebufferingTime = reader.float();
                    break;
                case 6:
                    message.BandwidthFluctuation = reader.uint32();
                    break;
                case 7:
                    message.Bandwidth = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsPlayerInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsPlayerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsPlayerInfo} ArgumentsPlayerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsPlayerInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsPlayerInfo message.
         * @function verify
         * @memberof manager.ArgumentsPlayerInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsPlayerInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.PlayingState != null && message.hasOwnProperty("PlayingState"))
                switch (message.PlayingState) {
                default:
                    return "PlayingState: enum value expected";
                case 0:
                case 1:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.WatchingTime != null && message.hasOwnProperty("WatchingTime"))
                if (typeof message.WatchingTime !== "number")
                    return "WatchingTime: number expected";
            if (message.BufferLength != null && message.hasOwnProperty("BufferLength"))
                if (typeof message.BufferLength !== "number")
                    return "BufferLength: number expected";
            if (message.Rebuffers != null && message.hasOwnProperty("Rebuffers"))
                if (!$util.isInteger(message.Rebuffers))
                    return "Rebuffers: integer expected";
            if (message.RebufferingTime != null && message.hasOwnProperty("RebufferingTime"))
                if (typeof message.RebufferingTime !== "number")
                    return "RebufferingTime: number expected";
            if (message.BandwidthFluctuation != null && message.hasOwnProperty("BandwidthFluctuation"))
                if (!$util.isInteger(message.BandwidthFluctuation))
                    return "BandwidthFluctuation: integer expected";
            if (message.Bandwidth != null && message.hasOwnProperty("Bandwidth"))
                if (!$util.isInteger(message.Bandwidth))
                    return "Bandwidth: integer expected";
            return null;
        };

        /**
         * Creates an ArgumentsPlayerInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsPlayerInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsPlayerInfo} ArgumentsPlayerInfo
         */
        ArgumentsPlayerInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsPlayerInfo)
                return object;
            var message = new $root.manager.ArgumentsPlayerInfo();
            switch (object.PlayingState) {
            case "IDLE":
            case 0:
                message.PlayingState = 0;
                break;
            case "PLAYING":
            case 1:
                message.PlayingState = 1;
                break;
            case "BUFFERING":
            case 3:
                message.PlayingState = 3;
                break;
            case "PAUSED":
            case 4:
                message.PlayingState = 4;
                break;
            case "STOP":
            case 5:
                message.PlayingState = 5;
                break;
            }
            if (object.WatchingTime != null)
                message.WatchingTime = Number(object.WatchingTime);
            if (object.BufferLength != null)
                message.BufferLength = Number(object.BufferLength);
            if (object.Rebuffers != null)
                message.Rebuffers = object.Rebuffers >>> 0;
            if (object.RebufferingTime != null)
                message.RebufferingTime = Number(object.RebufferingTime);
            if (object.BandwidthFluctuation != null)
                message.BandwidthFluctuation = object.BandwidthFluctuation >>> 0;
            if (object.Bandwidth != null)
                message.Bandwidth = object.Bandwidth >>> 0;
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsPlayerInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsPlayerInfo
         * @static
         * @param {manager.ArgumentsPlayerInfo} message ArgumentsPlayerInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsPlayerInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.PlayingState = options.enums === String ? "IDLE" : 0;
                object.WatchingTime = 0;
                object.BufferLength = 0;
                object.Rebuffers = 0;
                object.RebufferingTime = 0;
                object.BandwidthFluctuation = 0;
                object.Bandwidth = 0;
            }
            if (message.PlayingState != null && message.hasOwnProperty("PlayingState"))
                object.PlayingState = options.enums === String ? $root.manager.PlayingState[message.PlayingState] : message.PlayingState;
            if (message.WatchingTime != null && message.hasOwnProperty("WatchingTime"))
                object.WatchingTime = options.json && !isFinite(message.WatchingTime) ? String(message.WatchingTime) : message.WatchingTime;
            if (message.BufferLength != null && message.hasOwnProperty("BufferLength"))
                object.BufferLength = options.json && !isFinite(message.BufferLength) ? String(message.BufferLength) : message.BufferLength;
            if (message.Rebuffers != null && message.hasOwnProperty("Rebuffers"))
                object.Rebuffers = message.Rebuffers;
            if (message.RebufferingTime != null && message.hasOwnProperty("RebufferingTime"))
                object.RebufferingTime = options.json && !isFinite(message.RebufferingTime) ? String(message.RebufferingTime) : message.RebufferingTime;
            if (message.BandwidthFluctuation != null && message.hasOwnProperty("BandwidthFluctuation"))
                object.BandwidthFluctuation = message.BandwidthFluctuation;
            if (message.Bandwidth != null && message.hasOwnProperty("Bandwidth"))
                object.Bandwidth = message.Bandwidth;
            return object;
        };

        /**
         * Converts this ArgumentsPlayerInfo to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsPlayerInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsPlayerInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsPlayerInfo;
    })();

    manager.ArgumentsResourceExchanges = (function() {

        /**
         * Properties of an ArgumentsResourceExchanges.
         * @memberof manager
         * @interface IArgumentsResourceExchanges
         * @property {Array.<manager.IResourceExchanges>|null} [Lists] ArgumentsResourceExchanges Lists
         * @property {string|null} [Codec] ArgumentsResourceExchanges Codec
         * @property {number|null} [Bandwidth] ArgumentsResourceExchanges Bandwidth
         */

        /**
         * Constructs a new ArgumentsResourceExchanges.
         * @memberof manager
         * @classdesc Represents an ArgumentsResourceExchanges.
         * @implements IArgumentsResourceExchanges
         * @constructor
         * @param {manager.IArgumentsResourceExchanges=} [properties] Properties to set
         */
        function ArgumentsResourceExchanges(properties) {
            this.Lists = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsResourceExchanges Lists.
         * @member {Array.<manager.IResourceExchanges>} Lists
         * @memberof manager.ArgumentsResourceExchanges
         * @instance
         */
        ArgumentsResourceExchanges.prototype.Lists = $util.emptyArray;

        /**
         * ArgumentsResourceExchanges Codec.
         * @member {string} Codec
         * @memberof manager.ArgumentsResourceExchanges
         * @instance
         */
        ArgumentsResourceExchanges.prototype.Codec = "";

        /**
         * ArgumentsResourceExchanges Bandwidth.
         * @member {number} Bandwidth
         * @memberof manager.ArgumentsResourceExchanges
         * @instance
         */
        ArgumentsResourceExchanges.prototype.Bandwidth = 0;

        /**
         * Creates a new ArgumentsResourceExchanges instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsResourceExchanges
         * @static
         * @param {manager.IArgumentsResourceExchanges=} [properties] Properties to set
         * @returns {manager.ArgumentsResourceExchanges} ArgumentsResourceExchanges instance
         */
        ArgumentsResourceExchanges.create = function create(properties) {
            return new ArgumentsResourceExchanges(properties);
        };

        /**
         * Encodes the specified ArgumentsResourceExchanges message. Does not implicitly {@link manager.ArgumentsResourceExchanges.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsResourceExchanges
         * @static
         * @param {manager.IArgumentsResourceExchanges} message ArgumentsResourceExchanges message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsResourceExchanges.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Lists != null && message.Lists.length)
                for (var i = 0; i < message.Lists.length; ++i)
                    $root.manager.ResourceExchanges.encode(message.Lists[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.Codec != null && message.hasOwnProperty("Codec"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.Codec);
            if (message.Bandwidth != null && message.hasOwnProperty("Bandwidth"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.Bandwidth);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsResourceExchanges message, length delimited. Does not implicitly {@link manager.ArgumentsResourceExchanges.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsResourceExchanges
         * @static
         * @param {manager.IArgumentsResourceExchanges} message ArgumentsResourceExchanges message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsResourceExchanges.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsResourceExchanges message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsResourceExchanges
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsResourceExchanges} ArgumentsResourceExchanges
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsResourceExchanges.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsResourceExchanges();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.Lists && message.Lists.length))
                        message.Lists = [];
                    message.Lists.push($root.manager.ResourceExchanges.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.Codec = reader.string();
                    break;
                case 3:
                    message.Bandwidth = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsResourceExchanges message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsResourceExchanges
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsResourceExchanges} ArgumentsResourceExchanges
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsResourceExchanges.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsResourceExchanges message.
         * @function verify
         * @memberof manager.ArgumentsResourceExchanges
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsResourceExchanges.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Lists != null && message.hasOwnProperty("Lists")) {
                if (!Array.isArray(message.Lists))
                    return "Lists: array expected";
                for (var i = 0; i < message.Lists.length; ++i) {
                    var error = $root.manager.ResourceExchanges.verify(message.Lists[i]);
                    if (error)
                        return "Lists." + error;
                }
            }
            if (message.Codec != null && message.hasOwnProperty("Codec"))
                if (!$util.isString(message.Codec))
                    return "Codec: string expected";
            if (message.Bandwidth != null && message.hasOwnProperty("Bandwidth"))
                if (!$util.isInteger(message.Bandwidth))
                    return "Bandwidth: integer expected";
            return null;
        };

        /**
         * Creates an ArgumentsResourceExchanges message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsResourceExchanges
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsResourceExchanges} ArgumentsResourceExchanges
         */
        ArgumentsResourceExchanges.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsResourceExchanges)
                return object;
            var message = new $root.manager.ArgumentsResourceExchanges();
            if (object.Lists) {
                if (!Array.isArray(object.Lists))
                    throw TypeError(".manager.ArgumentsResourceExchanges.Lists: array expected");
                message.Lists = [];
                for (var i = 0; i < object.Lists.length; ++i) {
                    if (typeof object.Lists[i] !== "object")
                        throw TypeError(".manager.ArgumentsResourceExchanges.Lists: object expected");
                    message.Lists[i] = $root.manager.ResourceExchanges.fromObject(object.Lists[i]);
                }
            }
            if (object.Codec != null)
                message.Codec = String(object.Codec);
            if (object.Bandwidth != null)
                message.Bandwidth = object.Bandwidth >>> 0;
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsResourceExchanges message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsResourceExchanges
         * @static
         * @param {manager.ArgumentsResourceExchanges} message ArgumentsResourceExchanges
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsResourceExchanges.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.Lists = [];
            if (options.defaults) {
                object.Codec = "";
                object.Bandwidth = 0;
            }
            if (message.Lists && message.Lists.length) {
                object.Lists = [];
                for (var j = 0; j < message.Lists.length; ++j)
                    object.Lists[j] = $root.manager.ResourceExchanges.toObject(message.Lists[j], options);
            }
            if (message.Codec != null && message.hasOwnProperty("Codec"))
                object.Codec = message.Codec;
            if (message.Bandwidth != null && message.hasOwnProperty("Bandwidth"))
                object.Bandwidth = message.Bandwidth;
            return object;
        };

        /**
         * Converts this ArgumentsResourceExchanges to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsResourceExchanges
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsResourceExchanges.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsResourceExchanges;
    })();

    manager.ArgumentsHereAreSomePeers = (function() {

        /**
         * Properties of an ArgumentsHereAreSomePeers.
         * @memberof manager
         * @interface IArgumentsHereAreSomePeers
         * @property {Array.<string>|null} [Peers] ArgumentsHereAreSomePeers Peers
         */

        /**
         * Constructs a new ArgumentsHereAreSomePeers.
         * @memberof manager
         * @classdesc Represents an ArgumentsHereAreSomePeers.
         * @implements IArgumentsHereAreSomePeers
         * @constructor
         * @param {manager.IArgumentsHereAreSomePeers=} [properties] Properties to set
         */
        function ArgumentsHereAreSomePeers(properties) {
            this.Peers = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsHereAreSomePeers Peers.
         * @member {Array.<string>} Peers
         * @memberof manager.ArgumentsHereAreSomePeers
         * @instance
         */
        ArgumentsHereAreSomePeers.prototype.Peers = $util.emptyArray;

        /**
         * Creates a new ArgumentsHereAreSomePeers instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsHereAreSomePeers
         * @static
         * @param {manager.IArgumentsHereAreSomePeers=} [properties] Properties to set
         * @returns {manager.ArgumentsHereAreSomePeers} ArgumentsHereAreSomePeers instance
         */
        ArgumentsHereAreSomePeers.create = function create(properties) {
            return new ArgumentsHereAreSomePeers(properties);
        };

        /**
         * Encodes the specified ArgumentsHereAreSomePeers message. Does not implicitly {@link manager.ArgumentsHereAreSomePeers.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsHereAreSomePeers
         * @static
         * @param {manager.IArgumentsHereAreSomePeers} message ArgumentsHereAreSomePeers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsHereAreSomePeers.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Peers != null && message.Peers.length)
                for (var i = 0; i < message.Peers.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.Peers[i]);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsHereAreSomePeers message, length delimited. Does not implicitly {@link manager.ArgumentsHereAreSomePeers.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsHereAreSomePeers
         * @static
         * @param {manager.IArgumentsHereAreSomePeers} message ArgumentsHereAreSomePeers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsHereAreSomePeers.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsHereAreSomePeers message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsHereAreSomePeers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsHereAreSomePeers} ArgumentsHereAreSomePeers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsHereAreSomePeers.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsHereAreSomePeers();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.Peers && message.Peers.length))
                        message.Peers = [];
                    message.Peers.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsHereAreSomePeers message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsHereAreSomePeers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsHereAreSomePeers} ArgumentsHereAreSomePeers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsHereAreSomePeers.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsHereAreSomePeers message.
         * @function verify
         * @memberof manager.ArgumentsHereAreSomePeers
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsHereAreSomePeers.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Peers != null && message.hasOwnProperty("Peers")) {
                if (!Array.isArray(message.Peers))
                    return "Peers: array expected";
                for (var i = 0; i < message.Peers.length; ++i)
                    if (!$util.isString(message.Peers[i]))
                        return "Peers: string[] expected";
            }
            return null;
        };

        /**
         * Creates an ArgumentsHereAreSomePeers message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsHereAreSomePeers
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsHereAreSomePeers} ArgumentsHereAreSomePeers
         */
        ArgumentsHereAreSomePeers.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsHereAreSomePeers)
                return object;
            var message = new $root.manager.ArgumentsHereAreSomePeers();
            if (object.Peers) {
                if (!Array.isArray(object.Peers))
                    throw TypeError(".manager.ArgumentsHereAreSomePeers.Peers: array expected");
                message.Peers = [];
                for (var i = 0; i < object.Peers.length; ++i)
                    message.Peers[i] = String(object.Peers[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsHereAreSomePeers message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsHereAreSomePeers
         * @static
         * @param {manager.ArgumentsHereAreSomePeers} message ArgumentsHereAreSomePeers
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsHereAreSomePeers.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.Peers = [];
            if (message.Peers && message.Peers.length) {
                object.Peers = [];
                for (var j = 0; j < message.Peers.length; ++j)
                    object.Peers[j] = message.Peers[j];
            }
            return object;
        };

        /**
         * Converts this ArgumentsHereAreSomePeers to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsHereAreSomePeers
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsHereAreSomePeers.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsHereAreSomePeers;
    })();

    manager.ArgumentsItsAMatch = (function() {

        /**
         * Properties of an ArgumentsItsAMatch.
         * @memberof manager
         * @interface IArgumentsItsAMatch
         * @property {string|null} [Peer] ArgumentsItsAMatch Peer
         * @property {string|null} [Offer] ArgumentsItsAMatch Offer
         * @property {Array.<string>|null} [Candidates] ArgumentsItsAMatch Candidates
         */

        /**
         * Constructs a new ArgumentsItsAMatch.
         * @memberof manager
         * @classdesc Represents an ArgumentsItsAMatch.
         * @implements IArgumentsItsAMatch
         * @constructor
         * @param {manager.IArgumentsItsAMatch=} [properties] Properties to set
         */
        function ArgumentsItsAMatch(properties) {
            this.Candidates = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsItsAMatch Peer.
         * @member {string} Peer
         * @memberof manager.ArgumentsItsAMatch
         * @instance
         */
        ArgumentsItsAMatch.prototype.Peer = "";

        /**
         * ArgumentsItsAMatch Offer.
         * @member {string} Offer
         * @memberof manager.ArgumentsItsAMatch
         * @instance
         */
        ArgumentsItsAMatch.prototype.Offer = "";

        /**
         * ArgumentsItsAMatch Candidates.
         * @member {Array.<string>} Candidates
         * @memberof manager.ArgumentsItsAMatch
         * @instance
         */
        ArgumentsItsAMatch.prototype.Candidates = $util.emptyArray;

        /**
         * Creates a new ArgumentsItsAMatch instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsItsAMatch
         * @static
         * @param {manager.IArgumentsItsAMatch=} [properties] Properties to set
         * @returns {manager.ArgumentsItsAMatch} ArgumentsItsAMatch instance
         */
        ArgumentsItsAMatch.create = function create(properties) {
            return new ArgumentsItsAMatch(properties);
        };

        /**
         * Encodes the specified ArgumentsItsAMatch message. Does not implicitly {@link manager.ArgumentsItsAMatch.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsItsAMatch
         * @static
         * @param {manager.IArgumentsItsAMatch} message ArgumentsItsAMatch message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsItsAMatch.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Peer != null && message.hasOwnProperty("Peer"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.Peer);
            if (message.Offer != null && message.hasOwnProperty("Offer"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.Offer);
            if (message.Candidates != null && message.Candidates.length)
                for (var i = 0; i < message.Candidates.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.Candidates[i]);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsItsAMatch message, length delimited. Does not implicitly {@link manager.ArgumentsItsAMatch.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsItsAMatch
         * @static
         * @param {manager.IArgumentsItsAMatch} message ArgumentsItsAMatch message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsItsAMatch.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsItsAMatch message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsItsAMatch
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsItsAMatch} ArgumentsItsAMatch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsItsAMatch.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsItsAMatch();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Peer = reader.string();
                    break;
                case 2:
                    message.Offer = reader.string();
                    break;
                case 3:
                    if (!(message.Candidates && message.Candidates.length))
                        message.Candidates = [];
                    message.Candidates.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsItsAMatch message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsItsAMatch
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsItsAMatch} ArgumentsItsAMatch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsItsAMatch.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsItsAMatch message.
         * @function verify
         * @memberof manager.ArgumentsItsAMatch
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsItsAMatch.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Peer != null && message.hasOwnProperty("Peer"))
                if (!$util.isString(message.Peer))
                    return "Peer: string expected";
            if (message.Offer != null && message.hasOwnProperty("Offer"))
                if (!$util.isString(message.Offer))
                    return "Offer: string expected";
            if (message.Candidates != null && message.hasOwnProperty("Candidates")) {
                if (!Array.isArray(message.Candidates))
                    return "Candidates: array expected";
                for (var i = 0; i < message.Candidates.length; ++i)
                    if (!$util.isString(message.Candidates[i]))
                        return "Candidates: string[] expected";
            }
            return null;
        };

        /**
         * Creates an ArgumentsItsAMatch message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsItsAMatch
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsItsAMatch} ArgumentsItsAMatch
         */
        ArgumentsItsAMatch.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsItsAMatch)
                return object;
            var message = new $root.manager.ArgumentsItsAMatch();
            if (object.Peer != null)
                message.Peer = String(object.Peer);
            if (object.Offer != null)
                message.Offer = String(object.Offer);
            if (object.Candidates) {
                if (!Array.isArray(object.Candidates))
                    throw TypeError(".manager.ArgumentsItsAMatch.Candidates: array expected");
                message.Candidates = [];
                for (var i = 0; i < object.Candidates.length; ++i)
                    message.Candidates[i] = String(object.Candidates[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsItsAMatch message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsItsAMatch
         * @static
         * @param {manager.ArgumentsItsAMatch} message ArgumentsItsAMatch
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsItsAMatch.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.Candidates = [];
            if (options.defaults) {
                object.Peer = "";
                object.Offer = "";
            }
            if (message.Peer != null && message.hasOwnProperty("Peer"))
                object.Peer = message.Peer;
            if (message.Offer != null && message.hasOwnProperty("Offer"))
                object.Offer = message.Offer;
            if (message.Candidates && message.Candidates.length) {
                object.Candidates = [];
                for (var j = 0; j < message.Candidates.length; ++j)
                    object.Candidates[j] = message.Candidates[j];
            }
            return object;
        };

        /**
         * Converts this ArgumentsItsAMatch to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsItsAMatch
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsItsAMatch.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsItsAMatch;
    })();

    /**
     * PairingRequestType enum.
     * @name manager.PairingRequestType
     * @enum {string}
     * @property {number} PairingRequestType_offer=0 PairingRequestType_offer value
     * @property {number} PairingRequestType_answer=1 PairingRequestType_answer value
     */
    manager.PairingRequestType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PairingRequestType_offer"] = 0;
        values[valuesById[1] = "PairingRequestType_answer"] = 1;
        return values;
    })();

    manager.ArgumentsPairingRequest = (function() {

        /**
         * Properties of an ArgumentsPairingRequest.
         * @memberof manager
         * @interface IArgumentsPairingRequest
         * @property {manager.PairingRequestType|null} [Type] ArgumentsPairingRequest Type
         * @property {string|null} [Peer] ArgumentsPairingRequest Peer
         * @property {string|null} [Offer] ArgumentsPairingRequest Offer
         * @property {Array.<string>|null} [Candidates] ArgumentsPairingRequest Candidates
         */

        /**
         * Constructs a new ArgumentsPairingRequest.
         * @memberof manager
         * @classdesc Represents an ArgumentsPairingRequest.
         * @implements IArgumentsPairingRequest
         * @constructor
         * @param {manager.IArgumentsPairingRequest=} [properties] Properties to set
         */
        function ArgumentsPairingRequest(properties) {
            this.Candidates = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsPairingRequest Type.
         * @member {manager.PairingRequestType} Type
         * @memberof manager.ArgumentsPairingRequest
         * @instance
         */
        ArgumentsPairingRequest.prototype.Type = 0;

        /**
         * ArgumentsPairingRequest Peer.
         * @member {string} Peer
         * @memberof manager.ArgumentsPairingRequest
         * @instance
         */
        ArgumentsPairingRequest.prototype.Peer = "";

        /**
         * ArgumentsPairingRequest Offer.
         * @member {string} Offer
         * @memberof manager.ArgumentsPairingRequest
         * @instance
         */
        ArgumentsPairingRequest.prototype.Offer = "";

        /**
         * ArgumentsPairingRequest Candidates.
         * @member {Array.<string>} Candidates
         * @memberof manager.ArgumentsPairingRequest
         * @instance
         */
        ArgumentsPairingRequest.prototype.Candidates = $util.emptyArray;

        /**
         * Creates a new ArgumentsPairingRequest instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsPairingRequest
         * @static
         * @param {manager.IArgumentsPairingRequest=} [properties] Properties to set
         * @returns {manager.ArgumentsPairingRequest} ArgumentsPairingRequest instance
         */
        ArgumentsPairingRequest.create = function create(properties) {
            return new ArgumentsPairingRequest(properties);
        };

        /**
         * Encodes the specified ArgumentsPairingRequest message. Does not implicitly {@link manager.ArgumentsPairingRequest.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsPairingRequest
         * @static
         * @param {manager.IArgumentsPairingRequest} message ArgumentsPairingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsPairingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Type != null && message.hasOwnProperty("Type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Type);
            if (message.Peer != null && message.hasOwnProperty("Peer"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.Peer);
            if (message.Offer != null && message.hasOwnProperty("Offer"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.Offer);
            if (message.Candidates != null && message.Candidates.length)
                for (var i = 0; i < message.Candidates.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.Candidates[i]);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsPairingRequest message, length delimited. Does not implicitly {@link manager.ArgumentsPairingRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsPairingRequest
         * @static
         * @param {manager.IArgumentsPairingRequest} message ArgumentsPairingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsPairingRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsPairingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsPairingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsPairingRequest} ArgumentsPairingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsPairingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsPairingRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Type = reader.int32();
                    break;
                case 2:
                    message.Peer = reader.string();
                    break;
                case 3:
                    message.Offer = reader.string();
                    break;
                case 4:
                    if (!(message.Candidates && message.Candidates.length))
                        message.Candidates = [];
                    message.Candidates.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsPairingRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsPairingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsPairingRequest} ArgumentsPairingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsPairingRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsPairingRequest message.
         * @function verify
         * @memberof manager.ArgumentsPairingRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsPairingRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Type != null && message.hasOwnProperty("Type"))
                switch (message.Type) {
                default:
                    return "Type: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.Peer != null && message.hasOwnProperty("Peer"))
                if (!$util.isString(message.Peer))
                    return "Peer: string expected";
            if (message.Offer != null && message.hasOwnProperty("Offer"))
                if (!$util.isString(message.Offer))
                    return "Offer: string expected";
            if (message.Candidates != null && message.hasOwnProperty("Candidates")) {
                if (!Array.isArray(message.Candidates))
                    return "Candidates: array expected";
                for (var i = 0; i < message.Candidates.length; ++i)
                    if (!$util.isString(message.Candidates[i]))
                        return "Candidates: string[] expected";
            }
            return null;
        };

        /**
         * Creates an ArgumentsPairingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsPairingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsPairingRequest} ArgumentsPairingRequest
         */
        ArgumentsPairingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsPairingRequest)
                return object;
            var message = new $root.manager.ArgumentsPairingRequest();
            switch (object.Type) {
            case "PairingRequestType_offer":
            case 0:
                message.Type = 0;
                break;
            case "PairingRequestType_answer":
            case 1:
                message.Type = 1;
                break;
            }
            if (object.Peer != null)
                message.Peer = String(object.Peer);
            if (object.Offer != null)
                message.Offer = String(object.Offer);
            if (object.Candidates) {
                if (!Array.isArray(object.Candidates))
                    throw TypeError(".manager.ArgumentsPairingRequest.Candidates: array expected");
                message.Candidates = [];
                for (var i = 0; i < object.Candidates.length; ++i)
                    message.Candidates[i] = String(object.Candidates[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsPairingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsPairingRequest
         * @static
         * @param {manager.ArgumentsPairingRequest} message ArgumentsPairingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsPairingRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.Candidates = [];
            if (options.defaults) {
                object.Type = options.enums === String ? "PairingRequestType_offer" : 0;
                object.Peer = "";
                object.Offer = "";
            }
            if (message.Type != null && message.hasOwnProperty("Type"))
                object.Type = options.enums === String ? $root.manager.PairingRequestType[message.Type] : message.Type;
            if (message.Peer != null && message.hasOwnProperty("Peer"))
                object.Peer = message.Peer;
            if (message.Offer != null && message.hasOwnProperty("Offer"))
                object.Offer = message.Offer;
            if (message.Candidates && message.Candidates.length) {
                object.Candidates = [];
                for (var j = 0; j < message.Candidates.length; ++j)
                    object.Candidates[j] = message.Candidates[j];
            }
            return object;
        };

        /**
         * Converts this ArgumentsPairingRequest to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsPairingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsPairingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsPairingRequest;
    })();

    manager.ArgumentsRegister = (function() {

        /**
         * Properties of an ArgumentsRegister.
         * @memberof manager
         * @interface IArgumentsRegister
         * @property {string|null} [Url] ArgumentsRegister Url
         * @property {Array.<manager.IIceCandidate>|null} [Candidates] ArgumentsRegister Candidates
         * @property {number|Long|null} [Bandwidth] ArgumentsRegister Bandwidth
         * @property {string|null} [Codec] ArgumentsRegister Codec
         * @property {number|Long|null} [TimeShift] ArgumentsRegister TimeShift
         */

        /**
         * Constructs a new ArgumentsRegister.
         * @memberof manager
         * @classdesc Represents an ArgumentsRegister.
         * @implements IArgumentsRegister
         * @constructor
         * @param {manager.IArgumentsRegister=} [properties] Properties to set
         */
        function ArgumentsRegister(properties) {
            this.Candidates = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsRegister Url.
         * @member {string} Url
         * @memberof manager.ArgumentsRegister
         * @instance
         */
        ArgumentsRegister.prototype.Url = "";

        /**
         * ArgumentsRegister Candidates.
         * @member {Array.<manager.IIceCandidate>} Candidates
         * @memberof manager.ArgumentsRegister
         * @instance
         */
        ArgumentsRegister.prototype.Candidates = $util.emptyArray;

        /**
         * ArgumentsRegister Bandwidth.
         * @member {number|Long} Bandwidth
         * @memberof manager.ArgumentsRegister
         * @instance
         */
        ArgumentsRegister.prototype.Bandwidth = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ArgumentsRegister Codec.
         * @member {string} Codec
         * @memberof manager.ArgumentsRegister
         * @instance
         */
        ArgumentsRegister.prototype.Codec = "";

        /**
         * ArgumentsRegister TimeShift.
         * @member {number|Long} TimeShift
         * @memberof manager.ArgumentsRegister
         * @instance
         */
        ArgumentsRegister.prototype.TimeShift = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ArgumentsRegister instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsRegister
         * @static
         * @param {manager.IArgumentsRegister=} [properties] Properties to set
         * @returns {manager.ArgumentsRegister} ArgumentsRegister instance
         */
        ArgumentsRegister.create = function create(properties) {
            return new ArgumentsRegister(properties);
        };

        /**
         * Encodes the specified ArgumentsRegister message. Does not implicitly {@link manager.ArgumentsRegister.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsRegister
         * @static
         * @param {manager.IArgumentsRegister} message ArgumentsRegister message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsRegister.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Url != null && message.hasOwnProperty("Url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.Url);
            if (message.Candidates != null && message.Candidates.length)
                for (var i = 0; i < message.Candidates.length; ++i)
                    $root.manager.IceCandidate.encode(message.Candidates[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.Bandwidth != null && message.hasOwnProperty("Bandwidth"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.Bandwidth);
            if (message.Codec != null && message.hasOwnProperty("Codec"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.Codec);
            if (message.TimeShift != null && message.hasOwnProperty("TimeShift"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.TimeShift);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsRegister message, length delimited. Does not implicitly {@link manager.ArgumentsRegister.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsRegister
         * @static
         * @param {manager.IArgumentsRegister} message ArgumentsRegister message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsRegister.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsRegister message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsRegister
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsRegister} ArgumentsRegister
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsRegister.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsRegister();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.Url = reader.string();
                    break;
                case 3:
                    if (!(message.Candidates && message.Candidates.length))
                        message.Candidates = [];
                    message.Candidates.push($root.manager.IceCandidate.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.Bandwidth = reader.uint64();
                    break;
                case 5:
                    message.Codec = reader.string();
                    break;
                case 6:
                    message.TimeShift = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsRegister message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsRegister
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsRegister} ArgumentsRegister
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsRegister.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsRegister message.
         * @function verify
         * @memberof manager.ArgumentsRegister
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsRegister.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Url != null && message.hasOwnProperty("Url"))
                if (!$util.isString(message.Url))
                    return "Url: string expected";
            if (message.Candidates != null && message.hasOwnProperty("Candidates")) {
                if (!Array.isArray(message.Candidates))
                    return "Candidates: array expected";
                for (var i = 0; i < message.Candidates.length; ++i) {
                    var error = $root.manager.IceCandidate.verify(message.Candidates[i]);
                    if (error)
                        return "Candidates." + error;
                }
            }
            if (message.Bandwidth != null && message.hasOwnProperty("Bandwidth"))
                if (!$util.isInteger(message.Bandwidth) && !(message.Bandwidth && $util.isInteger(message.Bandwidth.low) && $util.isInteger(message.Bandwidth.high)))
                    return "Bandwidth: integer|Long expected";
            if (message.Codec != null && message.hasOwnProperty("Codec"))
                if (!$util.isString(message.Codec))
                    return "Codec: string expected";
            if (message.TimeShift != null && message.hasOwnProperty("TimeShift"))
                if (!$util.isInteger(message.TimeShift) && !(message.TimeShift && $util.isInteger(message.TimeShift.low) && $util.isInteger(message.TimeShift.high)))
                    return "TimeShift: integer|Long expected";
            return null;
        };

        /**
         * Creates an ArgumentsRegister message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsRegister
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsRegister} ArgumentsRegister
         */
        ArgumentsRegister.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsRegister)
                return object;
            var message = new $root.manager.ArgumentsRegister();
            if (object.Url != null)
                message.Url = String(object.Url);
            if (object.Candidates) {
                if (!Array.isArray(object.Candidates))
                    throw TypeError(".manager.ArgumentsRegister.Candidates: array expected");
                message.Candidates = [];
                for (var i = 0; i < object.Candidates.length; ++i) {
                    if (typeof object.Candidates[i] !== "object")
                        throw TypeError(".manager.ArgumentsRegister.Candidates: object expected");
                    message.Candidates[i] = $root.manager.IceCandidate.fromObject(object.Candidates[i]);
                }
            }
            if (object.Bandwidth != null)
                if ($util.Long)
                    (message.Bandwidth = $util.Long.fromValue(object.Bandwidth)).unsigned = true;
                else if (typeof object.Bandwidth === "string")
                    message.Bandwidth = parseInt(object.Bandwidth, 10);
                else if (typeof object.Bandwidth === "number")
                    message.Bandwidth = object.Bandwidth;
                else if (typeof object.Bandwidth === "object")
                    message.Bandwidth = new $util.LongBits(object.Bandwidth.low >>> 0, object.Bandwidth.high >>> 0).toNumber(true);
            if (object.Codec != null)
                message.Codec = String(object.Codec);
            if (object.TimeShift != null)
                if ($util.Long)
                    (message.TimeShift = $util.Long.fromValue(object.TimeShift)).unsigned = true;
                else if (typeof object.TimeShift === "string")
                    message.TimeShift = parseInt(object.TimeShift, 10);
                else if (typeof object.TimeShift === "number")
                    message.TimeShift = object.TimeShift;
                else if (typeof object.TimeShift === "object")
                    message.TimeShift = new $util.LongBits(object.TimeShift.low >>> 0, object.TimeShift.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsRegister message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsRegister
         * @static
         * @param {manager.ArgumentsRegister} message ArgumentsRegister
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsRegister.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.Candidates = [];
            if (options.defaults) {
                object.Url = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.Bandwidth = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.Bandwidth = options.longs === String ? "0" : 0;
                object.Codec = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.TimeShift = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.TimeShift = options.longs === String ? "0" : 0;
            }
            if (message.Url != null && message.hasOwnProperty("Url"))
                object.Url = message.Url;
            if (message.Candidates && message.Candidates.length) {
                object.Candidates = [];
                for (var j = 0; j < message.Candidates.length; ++j)
                    object.Candidates[j] = $root.manager.IceCandidate.toObject(message.Candidates[j], options);
            }
            if (message.Bandwidth != null && message.hasOwnProperty("Bandwidth"))
                if (typeof message.Bandwidth === "number")
                    object.Bandwidth = options.longs === String ? String(message.Bandwidth) : message.Bandwidth;
                else
                    object.Bandwidth = options.longs === String ? $util.Long.prototype.toString.call(message.Bandwidth) : options.longs === Number ? new $util.LongBits(message.Bandwidth.low >>> 0, message.Bandwidth.high >>> 0).toNumber(true) : message.Bandwidth;
            if (message.Codec != null && message.hasOwnProperty("Codec"))
                object.Codec = message.Codec;
            if (message.TimeShift != null && message.hasOwnProperty("TimeShift"))
                if (typeof message.TimeShift === "number")
                    object.TimeShift = options.longs === String ? String(message.TimeShift) : message.TimeShift;
                else
                    object.TimeShift = options.longs === String ? $util.Long.prototype.toString.call(message.TimeShift) : options.longs === Number ? new $util.LongBits(message.TimeShift.low >>> 0, message.TimeShift.high >>> 0).toNumber(true) : message.TimeShift;
            return object;
        };

        /**
         * Converts this ArgumentsRegister to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsRegister
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsRegister.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsRegister;
    })();

    manager.ArgumentsRegistered = (function() {

        /**
         * Properties of an ArgumentsRegistered.
         * @memberof manager
         * @interface IArgumentsRegistered
         * @property {string|null} [ID] ArgumentsRegistered ID
         * @property {string|null} [ISP] ArgumentsRegistered ISP
         */

        /**
         * Constructs a new ArgumentsRegistered.
         * @memberof manager
         * @classdesc Represents an ArgumentsRegistered.
         * @implements IArgumentsRegistered
         * @constructor
         * @param {manager.IArgumentsRegistered=} [properties] Properties to set
         */
        function ArgumentsRegistered(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsRegistered ID.
         * @member {string} ID
         * @memberof manager.ArgumentsRegistered
         * @instance
         */
        ArgumentsRegistered.prototype.ID = "";

        /**
         * ArgumentsRegistered ISP.
         * @member {string} ISP
         * @memberof manager.ArgumentsRegistered
         * @instance
         */
        ArgumentsRegistered.prototype.ISP = "";

        /**
         * Creates a new ArgumentsRegistered instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsRegistered
         * @static
         * @param {manager.IArgumentsRegistered=} [properties] Properties to set
         * @returns {manager.ArgumentsRegistered} ArgumentsRegistered instance
         */
        ArgumentsRegistered.create = function create(properties) {
            return new ArgumentsRegistered(properties);
        };

        /**
         * Encodes the specified ArgumentsRegistered message. Does not implicitly {@link manager.ArgumentsRegistered.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsRegistered
         * @static
         * @param {manager.IArgumentsRegistered} message ArgumentsRegistered message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsRegistered.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ID != null && message.hasOwnProperty("ID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ID);
            if (message.ISP != null && message.hasOwnProperty("ISP"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.ISP);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsRegistered message, length delimited. Does not implicitly {@link manager.ArgumentsRegistered.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsRegistered
         * @static
         * @param {manager.IArgumentsRegistered} message ArgumentsRegistered message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsRegistered.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsRegistered message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsRegistered
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsRegistered} ArgumentsRegistered
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsRegistered.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsRegistered();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ID = reader.string();
                    break;
                case 2:
                    message.ISP = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsRegistered message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsRegistered
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsRegistered} ArgumentsRegistered
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsRegistered.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsRegistered message.
         * @function verify
         * @memberof manager.ArgumentsRegistered
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsRegistered.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ID != null && message.hasOwnProperty("ID"))
                if (!$util.isString(message.ID))
                    return "ID: string expected";
            if (message.ISP != null && message.hasOwnProperty("ISP"))
                if (!$util.isString(message.ISP))
                    return "ISP: string expected";
            return null;
        };

        /**
         * Creates an ArgumentsRegistered message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsRegistered
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsRegistered} ArgumentsRegistered
         */
        ArgumentsRegistered.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsRegistered)
                return object;
            var message = new $root.manager.ArgumentsRegistered();
            if (object.ID != null)
                message.ID = String(object.ID);
            if (object.ISP != null)
                message.ISP = String(object.ISP);
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsRegistered message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsRegistered
         * @static
         * @param {manager.ArgumentsRegistered} message ArgumentsRegistered
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsRegistered.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.ID = "";
                object.ISP = "";
            }
            if (message.ID != null && message.hasOwnProperty("ID"))
                object.ID = message.ID;
            if (message.ISP != null && message.hasOwnProperty("ISP"))
                object.ISP = message.ISP;
            return object;
        };

        /**
         * Converts this ArgumentsRegistered to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsRegistered
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsRegistered.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsRegistered;
    })();

    /**
     * UnregisterReason enum.
     * @name manager.UnregisterReason
     * @enum {string}
     * @property {number} UnknownUnregisterReason=0 UnknownUnregisterReason value
     * @property {number} UnregisterPlaybackPaused=1 UnregisterPlaybackPaused value
     * @property {number} UnregisterFinished=2 UnregisterFinished value
     * @property {number} UnregisterClosed=3 UnregisterClosed value
     * @property {number} UnregisterSwitching=4 UnregisterSwitching value
     */
    manager.UnregisterReason = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UnknownUnregisterReason"] = 0;
        values[valuesById[1] = "UnregisterPlaybackPaused"] = 1;
        values[valuesById[2] = "UnregisterFinished"] = 2;
        values[valuesById[3] = "UnregisterClosed"] = 3;
        values[valuesById[4] = "UnregisterSwitching"] = 4;
        return values;
    })();

    manager.ArgumentsUnregister = (function() {

        /**
         * Properties of an ArgumentsUnregister.
         * @memberof manager
         * @interface IArgumentsUnregister
         * @property {manager.UnregisterReason|null} [Reason] ArgumentsUnregister Reason
         */

        /**
         * Constructs a new ArgumentsUnregister.
         * @memberof manager
         * @classdesc Represents an ArgumentsUnregister.
         * @implements IArgumentsUnregister
         * @constructor
         * @param {manager.IArgumentsUnregister=} [properties] Properties to set
         */
        function ArgumentsUnregister(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsUnregister Reason.
         * @member {manager.UnregisterReason} Reason
         * @memberof manager.ArgumentsUnregister
         * @instance
         */
        ArgumentsUnregister.prototype.Reason = 0;

        /**
         * Creates a new ArgumentsUnregister instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsUnregister
         * @static
         * @param {manager.IArgumentsUnregister=} [properties] Properties to set
         * @returns {manager.ArgumentsUnregister} ArgumentsUnregister instance
         */
        ArgumentsUnregister.create = function create(properties) {
            return new ArgumentsUnregister(properties);
        };

        /**
         * Encodes the specified ArgumentsUnregister message. Does not implicitly {@link manager.ArgumentsUnregister.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsUnregister
         * @static
         * @param {manager.IArgumentsUnregister} message ArgumentsUnregister message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsUnregister.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Reason != null && message.hasOwnProperty("Reason"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Reason);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsUnregister message, length delimited. Does not implicitly {@link manager.ArgumentsUnregister.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsUnregister
         * @static
         * @param {manager.IArgumentsUnregister} message ArgumentsUnregister message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsUnregister.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsUnregister message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsUnregister
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsUnregister} ArgumentsUnregister
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsUnregister.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsUnregister();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Reason = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsUnregister message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsUnregister
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsUnregister} ArgumentsUnregister
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsUnregister.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsUnregister message.
         * @function verify
         * @memberof manager.ArgumentsUnregister
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsUnregister.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Reason != null && message.hasOwnProperty("Reason"))
                switch (message.Reason) {
                default:
                    return "Reason: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            return null;
        };

        /**
         * Creates an ArgumentsUnregister message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsUnregister
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsUnregister} ArgumentsUnregister
         */
        ArgumentsUnregister.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsUnregister)
                return object;
            var message = new $root.manager.ArgumentsUnregister();
            switch (object.Reason) {
            case "UnknownUnregisterReason":
            case 0:
                message.Reason = 0;
                break;
            case "UnregisterPlaybackPaused":
            case 1:
                message.Reason = 1;
                break;
            case "UnregisterFinished":
            case 2:
                message.Reason = 2;
                break;
            case "UnregisterClosed":
            case 3:
                message.Reason = 3;
                break;
            case "UnregisterSwitching":
            case 4:
                message.Reason = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsUnregister message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsUnregister
         * @static
         * @param {manager.ArgumentsUnregister} message ArgumentsUnregister
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsUnregister.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.Reason = options.enums === String ? "UnknownUnregisterReason" : 0;
            if (message.Reason != null && message.hasOwnProperty("Reason"))
                object.Reason = options.enums === String ? $root.manager.UnregisterReason[message.Reason] : message.Reason;
            return object;
        };

        /**
         * Converts this ArgumentsUnregister to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsUnregister
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsUnregister.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsUnregister;
    })();

    manager.ArgumentsSwipeRight = (function() {

        /**
         * Properties of an ArgumentsSwipeRight.
         * @memberof manager
         * @interface IArgumentsSwipeRight
         * @property {string|null} [Peer] ArgumentsSwipeRight Peer
         * @property {string|null} [Offer] ArgumentsSwipeRight Offer
         * @property {Array.<string>|null} [Candidates] ArgumentsSwipeRight Candidates
         */

        /**
         * Constructs a new ArgumentsSwipeRight.
         * @memberof manager
         * @classdesc Represents an ArgumentsSwipeRight.
         * @implements IArgumentsSwipeRight
         * @constructor
         * @param {manager.IArgumentsSwipeRight=} [properties] Properties to set
         */
        function ArgumentsSwipeRight(properties) {
            this.Candidates = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsSwipeRight Peer.
         * @member {string} Peer
         * @memberof manager.ArgumentsSwipeRight
         * @instance
         */
        ArgumentsSwipeRight.prototype.Peer = "";

        /**
         * ArgumentsSwipeRight Offer.
         * @member {string} Offer
         * @memberof manager.ArgumentsSwipeRight
         * @instance
         */
        ArgumentsSwipeRight.prototype.Offer = "";

        /**
         * ArgumentsSwipeRight Candidates.
         * @member {Array.<string>} Candidates
         * @memberof manager.ArgumentsSwipeRight
         * @instance
         */
        ArgumentsSwipeRight.prototype.Candidates = $util.emptyArray;

        /**
         * Creates a new ArgumentsSwipeRight instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsSwipeRight
         * @static
         * @param {manager.IArgumentsSwipeRight=} [properties] Properties to set
         * @returns {manager.ArgumentsSwipeRight} ArgumentsSwipeRight instance
         */
        ArgumentsSwipeRight.create = function create(properties) {
            return new ArgumentsSwipeRight(properties);
        };

        /**
         * Encodes the specified ArgumentsSwipeRight message. Does not implicitly {@link manager.ArgumentsSwipeRight.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsSwipeRight
         * @static
         * @param {manager.IArgumentsSwipeRight} message ArgumentsSwipeRight message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsSwipeRight.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Peer != null && message.hasOwnProperty("Peer"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.Peer);
            if (message.Offer != null && message.hasOwnProperty("Offer"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.Offer);
            if (message.Candidates != null && message.Candidates.length)
                for (var i = 0; i < message.Candidates.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.Candidates[i]);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsSwipeRight message, length delimited. Does not implicitly {@link manager.ArgumentsSwipeRight.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsSwipeRight
         * @static
         * @param {manager.IArgumentsSwipeRight} message ArgumentsSwipeRight message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsSwipeRight.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsSwipeRight message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsSwipeRight
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsSwipeRight} ArgumentsSwipeRight
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsSwipeRight.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsSwipeRight();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Peer = reader.string();
                    break;
                case 2:
                    message.Offer = reader.string();
                    break;
                case 3:
                    if (!(message.Candidates && message.Candidates.length))
                        message.Candidates = [];
                    message.Candidates.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsSwipeRight message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsSwipeRight
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsSwipeRight} ArgumentsSwipeRight
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsSwipeRight.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsSwipeRight message.
         * @function verify
         * @memberof manager.ArgumentsSwipeRight
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsSwipeRight.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Peer != null && message.hasOwnProperty("Peer"))
                if (!$util.isString(message.Peer))
                    return "Peer: string expected";
            if (message.Offer != null && message.hasOwnProperty("Offer"))
                if (!$util.isString(message.Offer))
                    return "Offer: string expected";
            if (message.Candidates != null && message.hasOwnProperty("Candidates")) {
                if (!Array.isArray(message.Candidates))
                    return "Candidates: array expected";
                for (var i = 0; i < message.Candidates.length; ++i)
                    if (!$util.isString(message.Candidates[i]))
                        return "Candidates: string[] expected";
            }
            return null;
        };

        /**
         * Creates an ArgumentsSwipeRight message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsSwipeRight
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsSwipeRight} ArgumentsSwipeRight
         */
        ArgumentsSwipeRight.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsSwipeRight)
                return object;
            var message = new $root.manager.ArgumentsSwipeRight();
            if (object.Peer != null)
                message.Peer = String(object.Peer);
            if (object.Offer != null)
                message.Offer = String(object.Offer);
            if (object.Candidates) {
                if (!Array.isArray(object.Candidates))
                    throw TypeError(".manager.ArgumentsSwipeRight.Candidates: array expected");
                message.Candidates = [];
                for (var i = 0; i < object.Candidates.length; ++i)
                    message.Candidates[i] = String(object.Candidates[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsSwipeRight message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsSwipeRight
         * @static
         * @param {manager.ArgumentsSwipeRight} message ArgumentsSwipeRight
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsSwipeRight.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.Candidates = [];
            if (options.defaults) {
                object.Peer = "";
                object.Offer = "";
            }
            if (message.Peer != null && message.hasOwnProperty("Peer"))
                object.Peer = message.Peer;
            if (message.Offer != null && message.hasOwnProperty("Offer"))
                object.Offer = message.Offer;
            if (message.Candidates && message.Candidates.length) {
                object.Candidates = [];
                for (var j = 0; j < message.Candidates.length; ++j)
                    object.Candidates[j] = message.Candidates[j];
            }
            return object;
        };

        /**
         * Converts this ArgumentsSwipeRight to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsSwipeRight
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsSwipeRight.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsSwipeRight;
    })();

    /**
     * SwipeLeftReason enum.
     * @name manager.SwipeLeftReason
     * @enum {string}
     * @property {number} UnknownSwipeLeftReason=0 UnknownSwipeLeftReason value
     * @property {number} SwipeLeftNotReachable=1 SwipeLeftNotReachable value
     * @property {number} SwipeLeftTimedOut=2 SwipeLeftTimedOut value
     * @property {number} SwipeLeftNotInteresting=3 SwipeLeftNotInteresting value
     */
    manager.SwipeLeftReason = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UnknownSwipeLeftReason"] = 0;
        values[valuesById[1] = "SwipeLeftNotReachable"] = 1;
        values[valuesById[2] = "SwipeLeftTimedOut"] = 2;
        values[valuesById[3] = "SwipeLeftNotInteresting"] = 3;
        return values;
    })();

    manager.ArgumentsSwipeLeft = (function() {

        /**
         * Properties of an ArgumentsSwipeLeft.
         * @memberof manager
         * @interface IArgumentsSwipeLeft
         * @property {string|null} [Peer] ArgumentsSwipeLeft Peer
         * @property {manager.SwipeLeftReason|null} [Reason] ArgumentsSwipeLeft Reason
         */

        /**
         * Constructs a new ArgumentsSwipeLeft.
         * @memberof manager
         * @classdesc Represents an ArgumentsSwipeLeft.
         * @implements IArgumentsSwipeLeft
         * @constructor
         * @param {manager.IArgumentsSwipeLeft=} [properties] Properties to set
         */
        function ArgumentsSwipeLeft(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArgumentsSwipeLeft Peer.
         * @member {string} Peer
         * @memberof manager.ArgumentsSwipeLeft
         * @instance
         */
        ArgumentsSwipeLeft.prototype.Peer = "";

        /**
         * ArgumentsSwipeLeft Reason.
         * @member {manager.SwipeLeftReason} Reason
         * @memberof manager.ArgumentsSwipeLeft
         * @instance
         */
        ArgumentsSwipeLeft.prototype.Reason = 0;

        /**
         * Creates a new ArgumentsSwipeLeft instance using the specified properties.
         * @function create
         * @memberof manager.ArgumentsSwipeLeft
         * @static
         * @param {manager.IArgumentsSwipeLeft=} [properties] Properties to set
         * @returns {manager.ArgumentsSwipeLeft} ArgumentsSwipeLeft instance
         */
        ArgumentsSwipeLeft.create = function create(properties) {
            return new ArgumentsSwipeLeft(properties);
        };

        /**
         * Encodes the specified ArgumentsSwipeLeft message. Does not implicitly {@link manager.ArgumentsSwipeLeft.verify|verify} messages.
         * @function encode
         * @memberof manager.ArgumentsSwipeLeft
         * @static
         * @param {manager.IArgumentsSwipeLeft} message ArgumentsSwipeLeft message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsSwipeLeft.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Peer != null && message.hasOwnProperty("Peer"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.Peer);
            if (message.Reason != null && message.hasOwnProperty("Reason"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Reason);
            return writer;
        };

        /**
         * Encodes the specified ArgumentsSwipeLeft message, length delimited. Does not implicitly {@link manager.ArgumentsSwipeLeft.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ArgumentsSwipeLeft
         * @static
         * @param {manager.IArgumentsSwipeLeft} message ArgumentsSwipeLeft message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ArgumentsSwipeLeft.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ArgumentsSwipeLeft message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ArgumentsSwipeLeft
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ArgumentsSwipeLeft} ArgumentsSwipeLeft
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsSwipeLeft.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ArgumentsSwipeLeft();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Peer = reader.string();
                    break;
                case 2:
                    message.Reason = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ArgumentsSwipeLeft message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ArgumentsSwipeLeft
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ArgumentsSwipeLeft} ArgumentsSwipeLeft
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ArgumentsSwipeLeft.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ArgumentsSwipeLeft message.
         * @function verify
         * @memberof manager.ArgumentsSwipeLeft
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ArgumentsSwipeLeft.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Peer != null && message.hasOwnProperty("Peer"))
                if (!$util.isString(message.Peer))
                    return "Peer: string expected";
            if (message.Reason != null && message.hasOwnProperty("Reason"))
                switch (message.Reason) {
                default:
                    return "Reason: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates an ArgumentsSwipeLeft message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ArgumentsSwipeLeft
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ArgumentsSwipeLeft} ArgumentsSwipeLeft
         */
        ArgumentsSwipeLeft.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ArgumentsSwipeLeft)
                return object;
            var message = new $root.manager.ArgumentsSwipeLeft();
            if (object.Peer != null)
                message.Peer = String(object.Peer);
            switch (object.Reason) {
            case "UnknownSwipeLeftReason":
            case 0:
                message.Reason = 0;
                break;
            case "SwipeLeftNotReachable":
            case 1:
                message.Reason = 1;
                break;
            case "SwipeLeftTimedOut":
            case 2:
                message.Reason = 2;
                break;
            case "SwipeLeftNotInteresting":
            case 3:
                message.Reason = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an ArgumentsSwipeLeft message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ArgumentsSwipeLeft
         * @static
         * @param {manager.ArgumentsSwipeLeft} message ArgumentsSwipeLeft
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ArgumentsSwipeLeft.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.Peer = "";
                object.Reason = options.enums === String ? "UnknownSwipeLeftReason" : 0;
            }
            if (message.Peer != null && message.hasOwnProperty("Peer"))
                object.Peer = message.Peer;
            if (message.Reason != null && message.hasOwnProperty("Reason"))
                object.Reason = options.enums === String ? $root.manager.SwipeLeftReason[message.Reason] : message.Reason;
            return object;
        };

        /**
         * Converts this ArgumentsSwipeLeft to JSON.
         * @function toJSON
         * @memberof manager.ArgumentsSwipeLeft
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ArgumentsSwipeLeft.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ArgumentsSwipeLeft;
    })();

    /**
     * PlayingState enum.
     * @name manager.PlayingState
     * @enum {string}
     * @property {number} IDLE=0 IDLE value
     * @property {number} PLAYING=1 PLAYING value
     * @property {number} BUFFERING=3 BUFFERING value
     * @property {number} PAUSED=4 PAUSED value
     * @property {number} STOP=5 STOP value
     */
    manager.PlayingState = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "IDLE"] = 0;
        values[valuesById[1] = "PLAYING"] = 1;
        values[valuesById[3] = "BUFFERING"] = 3;
        values[valuesById[4] = "PAUSED"] = 4;
        values[valuesById[5] = "STOP"] = 5;
        return values;
    })();

    manager.ResourceExchanges = (function() {

        /**
         * Properties of a ResourceExchanges.
         * @memberof manager
         * @interface IResourceExchanges
         * @property {number|Long|null} [Ts] ResourceExchanges Ts
         * @property {string|null} [Pid] ResourceExchanges Pid
         * @property {string|null} [Pid2] ResourceExchanges Pid2
         * @property {string|null} [URL] ResourceExchanges URL
         * @property {number|null} [URLCRC16] ResourceExchanges URLCRC16
         * @property {number|null} [TimeSpentMs] ResourceExchanges TimeSpentMs
         * @property {number|null} [SizeBytes] ResourceExchanges SizeBytes
         * @property {string|null} [Mode] ResourceExchanges Mode
         * @property {boolean|null} [Upload] ResourceExchanges Upload
         * @property {Uint8Array|null} [Storage] ResourceExchanges Storage
         */

        /**
         * Constructs a new ResourceExchanges.
         * @memberof manager
         * @classdesc Represents a ResourceExchanges.
         * @implements IResourceExchanges
         * @constructor
         * @param {manager.IResourceExchanges=} [properties] Properties to set
         */
        function ResourceExchanges(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResourceExchanges Ts.
         * @member {number|Long} Ts
         * @memberof manager.ResourceExchanges
         * @instance
         */
        ResourceExchanges.prototype.Ts = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ResourceExchanges Pid.
         * @member {string} Pid
         * @memberof manager.ResourceExchanges
         * @instance
         */
        ResourceExchanges.prototype.Pid = "";

        /**
         * ResourceExchanges Pid2.
         * @member {string} Pid2
         * @memberof manager.ResourceExchanges
         * @instance
         */
        ResourceExchanges.prototype.Pid2 = "";

        /**
         * ResourceExchanges URL.
         * @member {string} URL
         * @memberof manager.ResourceExchanges
         * @instance
         */
        ResourceExchanges.prototype.URL = "";

        /**
         * ResourceExchanges URLCRC16.
         * @member {number} URLCRC16
         * @memberof manager.ResourceExchanges
         * @instance
         */
        ResourceExchanges.prototype.URLCRC16 = 0;

        /**
         * ResourceExchanges TimeSpentMs.
         * @member {number} TimeSpentMs
         * @memberof manager.ResourceExchanges
         * @instance
         */
        ResourceExchanges.prototype.TimeSpentMs = 0;

        /**
         * ResourceExchanges SizeBytes.
         * @member {number} SizeBytes
         * @memberof manager.ResourceExchanges
         * @instance
         */
        ResourceExchanges.prototype.SizeBytes = 0;

        /**
         * ResourceExchanges Mode.
         * @member {string} Mode
         * @memberof manager.ResourceExchanges
         * @instance
         */
        ResourceExchanges.prototype.Mode = "";

        /**
         * ResourceExchanges Upload.
         * @member {boolean} Upload
         * @memberof manager.ResourceExchanges
         * @instance
         */
        ResourceExchanges.prototype.Upload = false;

        /**
         * ResourceExchanges Storage.
         * @member {Uint8Array} Storage
         * @memberof manager.ResourceExchanges
         * @instance
         */
        ResourceExchanges.prototype.Storage = $util.newBuffer([]);

        /**
         * Creates a new ResourceExchanges instance using the specified properties.
         * @function create
         * @memberof manager.ResourceExchanges
         * @static
         * @param {manager.IResourceExchanges=} [properties] Properties to set
         * @returns {manager.ResourceExchanges} ResourceExchanges instance
         */
        ResourceExchanges.create = function create(properties) {
            return new ResourceExchanges(properties);
        };

        /**
         * Encodes the specified ResourceExchanges message. Does not implicitly {@link manager.ResourceExchanges.verify|verify} messages.
         * @function encode
         * @memberof manager.ResourceExchanges
         * @static
         * @param {manager.IResourceExchanges} message ResourceExchanges message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceExchanges.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Ts != null && message.hasOwnProperty("Ts"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.Ts);
            if (message.Pid != null && message.hasOwnProperty("Pid"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.Pid);
            if (message.Pid2 != null && message.hasOwnProperty("Pid2"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.Pid2);
            if (message.URL != null && message.hasOwnProperty("URL"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.URL);
            if (message.TimeSpentMs != null && message.hasOwnProperty("TimeSpentMs"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.TimeSpentMs);
            if (message.SizeBytes != null && message.hasOwnProperty("SizeBytes"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.SizeBytes);
            if (message.Mode != null && message.hasOwnProperty("Mode"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.Mode);
            if (message.Upload != null && message.hasOwnProperty("Upload"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.Upload);
            if (message.Storage != null && message.hasOwnProperty("Storage"))
                writer.uint32(/* id 12, wireType 2 =*/98).bytes(message.Storage);
            if (message.URLCRC16 != null && message.hasOwnProperty("URLCRC16"))
                writer.uint32(/* id 41, wireType 0 =*/328).int32(message.URLCRC16);
            return writer;
        };

        /**
         * Encodes the specified ResourceExchanges message, length delimited. Does not implicitly {@link manager.ResourceExchanges.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ResourceExchanges
         * @static
         * @param {manager.IResourceExchanges} message ResourceExchanges message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceExchanges.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResourceExchanges message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ResourceExchanges
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ResourceExchanges} ResourceExchanges
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceExchanges.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ResourceExchanges();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Ts = reader.int64();
                    break;
                case 2:
                    message.Pid = reader.string();
                    break;
                case 3:
                    message.Pid2 = reader.string();
                    break;
                case 4:
                    message.URL = reader.string();
                    break;
                case 41:
                    message.URLCRC16 = reader.int32();
                    break;
                case 5:
                    message.TimeSpentMs = reader.uint32();
                    break;
                case 7:
                    message.SizeBytes = reader.uint32();
                    break;
                case 9:
                    message.Mode = reader.string();
                    break;
                case 10:
                    message.Upload = reader.bool();
                    break;
                case 12:
                    message.Storage = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResourceExchanges message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ResourceExchanges
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ResourceExchanges} ResourceExchanges
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceExchanges.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResourceExchanges message.
         * @function verify
         * @memberof manager.ResourceExchanges
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResourceExchanges.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Ts != null && message.hasOwnProperty("Ts"))
                if (!$util.isInteger(message.Ts) && !(message.Ts && $util.isInteger(message.Ts.low) && $util.isInteger(message.Ts.high)))
                    return "Ts: integer|Long expected";
            if (message.Pid != null && message.hasOwnProperty("Pid"))
                if (!$util.isString(message.Pid))
                    return "Pid: string expected";
            if (message.Pid2 != null && message.hasOwnProperty("Pid2"))
                if (!$util.isString(message.Pid2))
                    return "Pid2: string expected";
            if (message.URL != null && message.hasOwnProperty("URL"))
                if (!$util.isString(message.URL))
                    return "URL: string expected";
            if (message.URLCRC16 != null && message.hasOwnProperty("URLCRC16"))
                if (!$util.isInteger(message.URLCRC16))
                    return "URLCRC16: integer expected";
            if (message.TimeSpentMs != null && message.hasOwnProperty("TimeSpentMs"))
                if (!$util.isInteger(message.TimeSpentMs))
                    return "TimeSpentMs: integer expected";
            if (message.SizeBytes != null && message.hasOwnProperty("SizeBytes"))
                if (!$util.isInteger(message.SizeBytes))
                    return "SizeBytes: integer expected";
            if (message.Mode != null && message.hasOwnProperty("Mode"))
                if (!$util.isString(message.Mode))
                    return "Mode: string expected";
            if (message.Upload != null && message.hasOwnProperty("Upload"))
                if (typeof message.Upload !== "boolean")
                    return "Upload: boolean expected";
            if (message.Storage != null && message.hasOwnProperty("Storage"))
                if (!(message.Storage && typeof message.Storage.length === "number" || $util.isString(message.Storage)))
                    return "Storage: buffer expected";
            return null;
        };

        /**
         * Creates a ResourceExchanges message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ResourceExchanges
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ResourceExchanges} ResourceExchanges
         */
        ResourceExchanges.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ResourceExchanges)
                return object;
            var message = new $root.manager.ResourceExchanges();
            if (object.Ts != null)
                if ($util.Long)
                    (message.Ts = $util.Long.fromValue(object.Ts)).unsigned = false;
                else if (typeof object.Ts === "string")
                    message.Ts = parseInt(object.Ts, 10);
                else if (typeof object.Ts === "number")
                    message.Ts = object.Ts;
                else if (typeof object.Ts === "object")
                    message.Ts = new $util.LongBits(object.Ts.low >>> 0, object.Ts.high >>> 0).toNumber();
            if (object.Pid != null)
                message.Pid = String(object.Pid);
            if (object.Pid2 != null)
                message.Pid2 = String(object.Pid2);
            if (object.URL != null)
                message.URL = String(object.URL);
            if (object.URLCRC16 != null)
                message.URLCRC16 = object.URLCRC16 | 0;
            if (object.TimeSpentMs != null)
                message.TimeSpentMs = object.TimeSpentMs >>> 0;
            if (object.SizeBytes != null)
                message.SizeBytes = object.SizeBytes >>> 0;
            if (object.Mode != null)
                message.Mode = String(object.Mode);
            if (object.Upload != null)
                message.Upload = Boolean(object.Upload);
            if (object.Storage != null)
                if (typeof object.Storage === "string")
                    $util.base64.decode(object.Storage, message.Storage = $util.newBuffer($util.base64.length(object.Storage)), 0);
                else if (object.Storage.length)
                    message.Storage = object.Storage;
            return message;
        };

        /**
         * Creates a plain object from a ResourceExchanges message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ResourceExchanges
         * @static
         * @param {manager.ResourceExchanges} message ResourceExchanges
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResourceExchanges.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.Ts = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.Ts = options.longs === String ? "0" : 0;
                object.Pid = "";
                object.Pid2 = "";
                object.URL = "";
                object.TimeSpentMs = 0;
                object.SizeBytes = 0;
                object.Mode = "";
                object.Upload = false;
                if (options.bytes === String)
                    object.Storage = "";
                else {
                    object.Storage = [];
                    if (options.bytes !== Array)
                        object.Storage = $util.newBuffer(object.Storage);
                }
                object.URLCRC16 = 0;
            }
            if (message.Ts != null && message.hasOwnProperty("Ts"))
                if (typeof message.Ts === "number")
                    object.Ts = options.longs === String ? String(message.Ts) : message.Ts;
                else
                    object.Ts = options.longs === String ? $util.Long.prototype.toString.call(message.Ts) : options.longs === Number ? new $util.LongBits(message.Ts.low >>> 0, message.Ts.high >>> 0).toNumber() : message.Ts;
            if (message.Pid != null && message.hasOwnProperty("Pid"))
                object.Pid = message.Pid;
            if (message.Pid2 != null && message.hasOwnProperty("Pid2"))
                object.Pid2 = message.Pid2;
            if (message.URL != null && message.hasOwnProperty("URL"))
                object.URL = message.URL;
            if (message.TimeSpentMs != null && message.hasOwnProperty("TimeSpentMs"))
                object.TimeSpentMs = message.TimeSpentMs;
            if (message.SizeBytes != null && message.hasOwnProperty("SizeBytes"))
                object.SizeBytes = message.SizeBytes;
            if (message.Mode != null && message.hasOwnProperty("Mode"))
                object.Mode = message.Mode;
            if (message.Upload != null && message.hasOwnProperty("Upload"))
                object.Upload = message.Upload;
            if (message.Storage != null && message.hasOwnProperty("Storage"))
                object.Storage = options.bytes === String ? $util.base64.encode(message.Storage, 0, message.Storage.length) : options.bytes === Array ? Array.prototype.slice.call(message.Storage) : message.Storage;
            if (message.URLCRC16 != null && message.hasOwnProperty("URLCRC16"))
                object.URLCRC16 = message.URLCRC16;
            return object;
        };

        /**
         * Converts this ResourceExchanges to JSON.
         * @function toJSON
         * @memberof manager.ResourceExchanges
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResourceExchanges.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResourceExchanges;
    })();

    manager.PeerVisit = (function() {

        /**
         * Properties of a PeerVisit.
         * @memberof manager
         * @interface IPeerVisit
         * @property {string|null} [Pid] PeerVisit Pid
         * @property {number|Long|null} [Ts] PeerVisit Ts
         * @property {string|null} [Os] PeerVisit Os
         * @property {string|null} [Browser] PeerVisit Browser
         * @property {string|null} [Content] PeerVisit Content
         * @property {string|null} [Proto] PeerVisit Proto
         * @property {string|null} [Origin] PeerVisit Origin
         * @property {string|null} [Room] PeerVisit Room
         * @property {string|null} [IP] PeerVisit IP
         * @property {string|null} [Geohash] PeerVisit Geohash
         * @property {string|null} [ISP] PeerVisit ISP
         * @property {string|null} [Version] PeerVisit Version
         * @property {number|Long|null} [Timezone] PeerVisit Timezone
         * @property {number|null} [StartupTime] PeerVisit StartupTime
         * @property {boolean|null} [VOD] PeerVisit VOD
         * @property {string|null} [Country] PeerVisit Country
         * @property {string|null} [City] PeerVisit City
         */

        /**
         * Constructs a new PeerVisit.
         * @memberof manager
         * @classdesc Represents a PeerVisit.
         * @implements IPeerVisit
         * @constructor
         * @param {manager.IPeerVisit=} [properties] Properties to set
         */
        function PeerVisit(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PeerVisit Pid.
         * @member {string} Pid
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.Pid = "";

        /**
         * PeerVisit Ts.
         * @member {number|Long} Ts
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.Ts = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PeerVisit Os.
         * @member {string} Os
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.Os = "";

        /**
         * PeerVisit Browser.
         * @member {string} Browser
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.Browser = "";

        /**
         * PeerVisit Content.
         * @member {string} Content
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.Content = "";

        /**
         * PeerVisit Proto.
         * @member {string} Proto
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.Proto = "";

        /**
         * PeerVisit Origin.
         * @member {string} Origin
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.Origin = "";

        /**
         * PeerVisit Room.
         * @member {string} Room
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.Room = "";

        /**
         * PeerVisit IP.
         * @member {string} IP
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.IP = "";

        /**
         * PeerVisit Geohash.
         * @member {string} Geohash
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.Geohash = "";

        /**
         * PeerVisit ISP.
         * @member {string} ISP
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.ISP = "";

        /**
         * PeerVisit Version.
         * @member {string} Version
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.Version = "";

        /**
         * PeerVisit Timezone.
         * @member {number|Long} Timezone
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.Timezone = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PeerVisit StartupTime.
         * @member {number} StartupTime
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.StartupTime = 0;

        /**
         * PeerVisit VOD.
         * @member {boolean} VOD
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.VOD = false;

        /**
         * PeerVisit Country.
         * @member {string} Country
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.Country = "";

        /**
         * PeerVisit City.
         * @member {string} City
         * @memberof manager.PeerVisit
         * @instance
         */
        PeerVisit.prototype.City = "";

        /**
         * Creates a new PeerVisit instance using the specified properties.
         * @function create
         * @memberof manager.PeerVisit
         * @static
         * @param {manager.IPeerVisit=} [properties] Properties to set
         * @returns {manager.PeerVisit} PeerVisit instance
         */
        PeerVisit.create = function create(properties) {
            return new PeerVisit(properties);
        };

        /**
         * Encodes the specified PeerVisit message. Does not implicitly {@link manager.PeerVisit.verify|verify} messages.
         * @function encode
         * @memberof manager.PeerVisit
         * @static
         * @param {manager.IPeerVisit} message PeerVisit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeerVisit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Pid != null && message.hasOwnProperty("Pid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.Pid);
            if (message.Ts != null && message.hasOwnProperty("Ts"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.Ts);
            if (message.Os != null && message.hasOwnProperty("Os"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.Os);
            if (message.Browser != null && message.hasOwnProperty("Browser"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.Browser);
            if (message.Content != null && message.hasOwnProperty("Content"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.Content);
            if (message.Proto != null && message.hasOwnProperty("Proto"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.Proto);
            if (message.Origin != null && message.hasOwnProperty("Origin"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.Origin);
            if (message.Room != null && message.hasOwnProperty("Room"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.Room);
            if (message.IP != null && message.hasOwnProperty("IP"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.IP);
            if (message.Geohash != null && message.hasOwnProperty("Geohash"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.Geohash);
            if (message.ISP != null && message.hasOwnProperty("ISP"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.ISP);
            if (message.Version != null && message.hasOwnProperty("Version"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.Version);
            if (message.Timezone != null && message.hasOwnProperty("Timezone"))
                writer.uint32(/* id 13, wireType 0 =*/104).int64(message.Timezone);
            if (message.StartupTime != null && message.hasOwnProperty("StartupTime"))
                writer.uint32(/* id 14, wireType 5 =*/117).float(message.StartupTime);
            if (message.VOD != null && message.hasOwnProperty("VOD"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.VOD);
            if (message.Country != null && message.hasOwnProperty("Country"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.Country);
            if (message.City != null && message.hasOwnProperty("City"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.City);
            return writer;
        };

        /**
         * Encodes the specified PeerVisit message, length delimited. Does not implicitly {@link manager.PeerVisit.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.PeerVisit
         * @static
         * @param {manager.IPeerVisit} message PeerVisit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeerVisit.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PeerVisit message from the specified reader or buffer.
         * @function decode
         * @memberof manager.PeerVisit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.PeerVisit} PeerVisit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeerVisit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.PeerVisit();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Pid = reader.string();
                    break;
                case 2:
                    message.Ts = reader.int64();
                    break;
                case 3:
                    message.Os = reader.string();
                    break;
                case 4:
                    message.Browser = reader.string();
                    break;
                case 5:
                    message.Content = reader.string();
                    break;
                case 6:
                    message.Proto = reader.string();
                    break;
                case 7:
                    message.Origin = reader.string();
                    break;
                case 8:
                    message.Room = reader.string();
                    break;
                case 9:
                    message.IP = reader.string();
                    break;
                case 10:
                    message.Geohash = reader.string();
                    break;
                case 11:
                    message.ISP = reader.string();
                    break;
                case 12:
                    message.Version = reader.string();
                    break;
                case 13:
                    message.Timezone = reader.int64();
                    break;
                case 14:
                    message.StartupTime = reader.float();
                    break;
                case 15:
                    message.VOD = reader.bool();
                    break;
                case 16:
                    message.Country = reader.string();
                    break;
                case 17:
                    message.City = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PeerVisit message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.PeerVisit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.PeerVisit} PeerVisit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeerVisit.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PeerVisit message.
         * @function verify
         * @memberof manager.PeerVisit
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PeerVisit.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Pid != null && message.hasOwnProperty("Pid"))
                if (!$util.isString(message.Pid))
                    return "Pid: string expected";
            if (message.Ts != null && message.hasOwnProperty("Ts"))
                if (!$util.isInteger(message.Ts) && !(message.Ts && $util.isInteger(message.Ts.low) && $util.isInteger(message.Ts.high)))
                    return "Ts: integer|Long expected";
            if (message.Os != null && message.hasOwnProperty("Os"))
                if (!$util.isString(message.Os))
                    return "Os: string expected";
            if (message.Browser != null && message.hasOwnProperty("Browser"))
                if (!$util.isString(message.Browser))
                    return "Browser: string expected";
            if (message.Content != null && message.hasOwnProperty("Content"))
                if (!$util.isString(message.Content))
                    return "Content: string expected";
            if (message.Proto != null && message.hasOwnProperty("Proto"))
                if (!$util.isString(message.Proto))
                    return "Proto: string expected";
            if (message.Origin != null && message.hasOwnProperty("Origin"))
                if (!$util.isString(message.Origin))
                    return "Origin: string expected";
            if (message.Room != null && message.hasOwnProperty("Room"))
                if (!$util.isString(message.Room))
                    return "Room: string expected";
            if (message.IP != null && message.hasOwnProperty("IP"))
                if (!$util.isString(message.IP))
                    return "IP: string expected";
            if (message.Geohash != null && message.hasOwnProperty("Geohash"))
                if (!$util.isString(message.Geohash))
                    return "Geohash: string expected";
            if (message.ISP != null && message.hasOwnProperty("ISP"))
                if (!$util.isString(message.ISP))
                    return "ISP: string expected";
            if (message.Version != null && message.hasOwnProperty("Version"))
                if (!$util.isString(message.Version))
                    return "Version: string expected";
            if (message.Timezone != null && message.hasOwnProperty("Timezone"))
                if (!$util.isInteger(message.Timezone) && !(message.Timezone && $util.isInteger(message.Timezone.low) && $util.isInteger(message.Timezone.high)))
                    return "Timezone: integer|Long expected";
            if (message.StartupTime != null && message.hasOwnProperty("StartupTime"))
                if (typeof message.StartupTime !== "number")
                    return "StartupTime: number expected";
            if (message.VOD != null && message.hasOwnProperty("VOD"))
                if (typeof message.VOD !== "boolean")
                    return "VOD: boolean expected";
            if (message.Country != null && message.hasOwnProperty("Country"))
                if (!$util.isString(message.Country))
                    return "Country: string expected";
            if (message.City != null && message.hasOwnProperty("City"))
                if (!$util.isString(message.City))
                    return "City: string expected";
            return null;
        };

        /**
         * Creates a PeerVisit message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.PeerVisit
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.PeerVisit} PeerVisit
         */
        PeerVisit.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.PeerVisit)
                return object;
            var message = new $root.manager.PeerVisit();
            if (object.Pid != null)
                message.Pid = String(object.Pid);
            if (object.Ts != null)
                if ($util.Long)
                    (message.Ts = $util.Long.fromValue(object.Ts)).unsigned = false;
                else if (typeof object.Ts === "string")
                    message.Ts = parseInt(object.Ts, 10);
                else if (typeof object.Ts === "number")
                    message.Ts = object.Ts;
                else if (typeof object.Ts === "object")
                    message.Ts = new $util.LongBits(object.Ts.low >>> 0, object.Ts.high >>> 0).toNumber();
            if (object.Os != null)
                message.Os = String(object.Os);
            if (object.Browser != null)
                message.Browser = String(object.Browser);
            if (object.Content != null)
                message.Content = String(object.Content);
            if (object.Proto != null)
                message.Proto = String(object.Proto);
            if (object.Origin != null)
                message.Origin = String(object.Origin);
            if (object.Room != null)
                message.Room = String(object.Room);
            if (object.IP != null)
                message.IP = String(object.IP);
            if (object.Geohash != null)
                message.Geohash = String(object.Geohash);
            if (object.ISP != null)
                message.ISP = String(object.ISP);
            if (object.Version != null)
                message.Version = String(object.Version);
            if (object.Timezone != null)
                if ($util.Long)
                    (message.Timezone = $util.Long.fromValue(object.Timezone)).unsigned = false;
                else if (typeof object.Timezone === "string")
                    message.Timezone = parseInt(object.Timezone, 10);
                else if (typeof object.Timezone === "number")
                    message.Timezone = object.Timezone;
                else if (typeof object.Timezone === "object")
                    message.Timezone = new $util.LongBits(object.Timezone.low >>> 0, object.Timezone.high >>> 0).toNumber();
            if (object.StartupTime != null)
                message.StartupTime = Number(object.StartupTime);
            if (object.VOD != null)
                message.VOD = Boolean(object.VOD);
            if (object.Country != null)
                message.Country = String(object.Country);
            if (object.City != null)
                message.City = String(object.City);
            return message;
        };

        /**
         * Creates a plain object from a PeerVisit message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.PeerVisit
         * @static
         * @param {manager.PeerVisit} message PeerVisit
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PeerVisit.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.Pid = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.Ts = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.Ts = options.longs === String ? "0" : 0;
                object.Os = "";
                object.Browser = "";
                object.Content = "";
                object.Proto = "";
                object.Origin = "";
                object.Room = "";
                object.IP = "";
                object.Geohash = "";
                object.ISP = "";
                object.Version = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.Timezone = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.Timezone = options.longs === String ? "0" : 0;
                object.StartupTime = 0;
                object.VOD = false;
                object.Country = "";
                object.City = "";
            }
            if (message.Pid != null && message.hasOwnProperty("Pid"))
                object.Pid = message.Pid;
            if (message.Ts != null && message.hasOwnProperty("Ts"))
                if (typeof message.Ts === "number")
                    object.Ts = options.longs === String ? String(message.Ts) : message.Ts;
                else
                    object.Ts = options.longs === String ? $util.Long.prototype.toString.call(message.Ts) : options.longs === Number ? new $util.LongBits(message.Ts.low >>> 0, message.Ts.high >>> 0).toNumber() : message.Ts;
            if (message.Os != null && message.hasOwnProperty("Os"))
                object.Os = message.Os;
            if (message.Browser != null && message.hasOwnProperty("Browser"))
                object.Browser = message.Browser;
            if (message.Content != null && message.hasOwnProperty("Content"))
                object.Content = message.Content;
            if (message.Proto != null && message.hasOwnProperty("Proto"))
                object.Proto = message.Proto;
            if (message.Origin != null && message.hasOwnProperty("Origin"))
                object.Origin = message.Origin;
            if (message.Room != null && message.hasOwnProperty("Room"))
                object.Room = message.Room;
            if (message.IP != null && message.hasOwnProperty("IP"))
                object.IP = message.IP;
            if (message.Geohash != null && message.hasOwnProperty("Geohash"))
                object.Geohash = message.Geohash;
            if (message.ISP != null && message.hasOwnProperty("ISP"))
                object.ISP = message.ISP;
            if (message.Version != null && message.hasOwnProperty("Version"))
                object.Version = message.Version;
            if (message.Timezone != null && message.hasOwnProperty("Timezone"))
                if (typeof message.Timezone === "number")
                    object.Timezone = options.longs === String ? String(message.Timezone) : message.Timezone;
                else
                    object.Timezone = options.longs === String ? $util.Long.prototype.toString.call(message.Timezone) : options.longs === Number ? new $util.LongBits(message.Timezone.low >>> 0, message.Timezone.high >>> 0).toNumber() : message.Timezone;
            if (message.StartupTime != null && message.hasOwnProperty("StartupTime"))
                object.StartupTime = options.json && !isFinite(message.StartupTime) ? String(message.StartupTime) : message.StartupTime;
            if (message.VOD != null && message.hasOwnProperty("VOD"))
                object.VOD = message.VOD;
            if (message.Country != null && message.hasOwnProperty("Country"))
                object.Country = message.Country;
            if (message.City != null && message.hasOwnProperty("City"))
                object.City = message.City;
            return object;
        };

        /**
         * Converts this PeerVisit to JSON.
         * @function toJSON
         * @memberof manager.PeerVisit
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PeerVisit.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PeerVisit;
    })();

    manager.PeeringStats = (function() {

        /**
         * Properties of a PeeringStats.
         * @memberof manager
         * @interface IPeeringStats
         * @property {number|null} [TimeConnection] PeeringStats TimeConnection
         * @property {Array.<number>|null} [MsgCounts] PeeringStats MsgCounts
         * @property {Array.<number>|null} [MsgTimeSpent] PeeringStats MsgTimeSpent
         * @property {string|null} [ReasonLeave] PeeringStats ReasonLeave
         */

        /**
         * Constructs a new PeeringStats.
         * @memberof manager
         * @classdesc Represents a PeeringStats.
         * @implements IPeeringStats
         * @constructor
         * @param {manager.IPeeringStats=} [properties] Properties to set
         */
        function PeeringStats(properties) {
            this.MsgCounts = [];
            this.MsgTimeSpent = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PeeringStats TimeConnection.
         * @member {number} TimeConnection
         * @memberof manager.PeeringStats
         * @instance
         */
        PeeringStats.prototype.TimeConnection = 0;

        /**
         * PeeringStats MsgCounts.
         * @member {Array.<number>} MsgCounts
         * @memberof manager.PeeringStats
         * @instance
         */
        PeeringStats.prototype.MsgCounts = $util.emptyArray;

        /**
         * PeeringStats MsgTimeSpent.
         * @member {Array.<number>} MsgTimeSpent
         * @memberof manager.PeeringStats
         * @instance
         */
        PeeringStats.prototype.MsgTimeSpent = $util.emptyArray;

        /**
         * PeeringStats ReasonLeave.
         * @member {string} ReasonLeave
         * @memberof manager.PeeringStats
         * @instance
         */
        PeeringStats.prototype.ReasonLeave = "";

        /**
         * Creates a new PeeringStats instance using the specified properties.
         * @function create
         * @memberof manager.PeeringStats
         * @static
         * @param {manager.IPeeringStats=} [properties] Properties to set
         * @returns {manager.PeeringStats} PeeringStats instance
         */
        PeeringStats.create = function create(properties) {
            return new PeeringStats(properties);
        };

        /**
         * Encodes the specified PeeringStats message. Does not implicitly {@link manager.PeeringStats.verify|verify} messages.
         * @function encode
         * @memberof manager.PeeringStats
         * @static
         * @param {manager.IPeeringStats} message PeeringStats message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeeringStats.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.TimeConnection != null && message.hasOwnProperty("TimeConnection"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.TimeConnection);
            if (message.MsgCounts != null && message.MsgCounts.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.MsgCounts.length; ++i)
                    writer.uint32(message.MsgCounts[i]);
                writer.ldelim();
            }
            if (message.MsgTimeSpent != null && message.MsgTimeSpent.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (var i = 0; i < message.MsgTimeSpent.length; ++i)
                    writer.uint32(message.MsgTimeSpent[i]);
                writer.ldelim();
            }
            if (message.ReasonLeave != null && message.hasOwnProperty("ReasonLeave"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.ReasonLeave);
            return writer;
        };

        /**
         * Encodes the specified PeeringStats message, length delimited. Does not implicitly {@link manager.PeeringStats.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.PeeringStats
         * @static
         * @param {manager.IPeeringStats} message PeeringStats message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeeringStats.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PeeringStats message from the specified reader or buffer.
         * @function decode
         * @memberof manager.PeeringStats
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.PeeringStats} PeeringStats
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeeringStats.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.PeeringStats();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.TimeConnection = reader.uint32();
                    break;
                case 3:
                    if (!(message.MsgCounts && message.MsgCounts.length))
                        message.MsgCounts = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.MsgCounts.push(reader.uint32());
                    } else
                        message.MsgCounts.push(reader.uint32());
                    break;
                case 4:
                    if (!(message.MsgTimeSpent && message.MsgTimeSpent.length))
                        message.MsgTimeSpent = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.MsgTimeSpent.push(reader.uint32());
                    } else
                        message.MsgTimeSpent.push(reader.uint32());
                    break;
                case 5:
                    message.ReasonLeave = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PeeringStats message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.PeeringStats
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.PeeringStats} PeeringStats
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeeringStats.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PeeringStats message.
         * @function verify
         * @memberof manager.PeeringStats
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PeeringStats.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.TimeConnection != null && message.hasOwnProperty("TimeConnection"))
                if (!$util.isInteger(message.TimeConnection))
                    return "TimeConnection: integer expected";
            if (message.MsgCounts != null && message.hasOwnProperty("MsgCounts")) {
                if (!Array.isArray(message.MsgCounts))
                    return "MsgCounts: array expected";
                for (var i = 0; i < message.MsgCounts.length; ++i)
                    if (!$util.isInteger(message.MsgCounts[i]))
                        return "MsgCounts: integer[] expected";
            }
            if (message.MsgTimeSpent != null && message.hasOwnProperty("MsgTimeSpent")) {
                if (!Array.isArray(message.MsgTimeSpent))
                    return "MsgTimeSpent: array expected";
                for (var i = 0; i < message.MsgTimeSpent.length; ++i)
                    if (!$util.isInteger(message.MsgTimeSpent[i]))
                        return "MsgTimeSpent: integer[] expected";
            }
            if (message.ReasonLeave != null && message.hasOwnProperty("ReasonLeave"))
                if (!$util.isString(message.ReasonLeave))
                    return "ReasonLeave: string expected";
            return null;
        };

        /**
         * Creates a PeeringStats message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.PeeringStats
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.PeeringStats} PeeringStats
         */
        PeeringStats.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.PeeringStats)
                return object;
            var message = new $root.manager.PeeringStats();
            if (object.TimeConnection != null)
                message.TimeConnection = object.TimeConnection >>> 0;
            if (object.MsgCounts) {
                if (!Array.isArray(object.MsgCounts))
                    throw TypeError(".manager.PeeringStats.MsgCounts: array expected");
                message.MsgCounts = [];
                for (var i = 0; i < object.MsgCounts.length; ++i)
                    message.MsgCounts[i] = object.MsgCounts[i] >>> 0;
            }
            if (object.MsgTimeSpent) {
                if (!Array.isArray(object.MsgTimeSpent))
                    throw TypeError(".manager.PeeringStats.MsgTimeSpent: array expected");
                message.MsgTimeSpent = [];
                for (var i = 0; i < object.MsgTimeSpent.length; ++i)
                    message.MsgTimeSpent[i] = object.MsgTimeSpent[i] >>> 0;
            }
            if (object.ReasonLeave != null)
                message.ReasonLeave = String(object.ReasonLeave);
            return message;
        };

        /**
         * Creates a plain object from a PeeringStats message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.PeeringStats
         * @static
         * @param {manager.PeeringStats} message PeeringStats
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PeeringStats.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.MsgCounts = [];
                object.MsgTimeSpent = [];
            }
            if (options.defaults) {
                object.TimeConnection = 0;
                object.ReasonLeave = "";
            }
            if (message.TimeConnection != null && message.hasOwnProperty("TimeConnection"))
                object.TimeConnection = message.TimeConnection;
            if (message.MsgCounts && message.MsgCounts.length) {
                object.MsgCounts = [];
                for (var j = 0; j < message.MsgCounts.length; ++j)
                    object.MsgCounts[j] = message.MsgCounts[j];
            }
            if (message.MsgTimeSpent && message.MsgTimeSpent.length) {
                object.MsgTimeSpent = [];
                for (var j = 0; j < message.MsgTimeSpent.length; ++j)
                    object.MsgTimeSpent[j] = message.MsgTimeSpent[j];
            }
            if (message.ReasonLeave != null && message.hasOwnProperty("ReasonLeave"))
                object.ReasonLeave = message.ReasonLeave;
            return object;
        };

        /**
         * Converts this PeeringStats to JSON.
         * @function toJSON
         * @memberof manager.PeeringStats
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PeeringStats.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PeeringStats;
    })();

    manager.PeerState = (function() {

        /**
         * Properties of a PeerState.
         * @memberof manager
         * @interface IPeerState
         * @property {number|null} [BufferLengthBytes] PeerState BufferLengthBytes
         * @property {number|null} [SwarmSize] PeerState SwarmSize
         * @property {number|null} [OccupiedSlot] PeerState OccupiedSlot
         * @property {number|null} [FreeSlot] PeerState FreeSlot
         * @property {number|Long|null} [WatchingTimeSec] PeerState WatchingTimeSec
         * @property {number|Long|null} [Rebuffers] PeerState Rebuffers
         * @property {number|Long|null} [RebufferingTime] PeerState RebufferingTime
         */

        /**
         * Constructs a new PeerState.
         * @memberof manager
         * @classdesc Represents a PeerState.
         * @implements IPeerState
         * @constructor
         * @param {manager.IPeerState=} [properties] Properties to set
         */
        function PeerState(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PeerState BufferLengthBytes.
         * @member {number} BufferLengthBytes
         * @memberof manager.PeerState
         * @instance
         */
        PeerState.prototype.BufferLengthBytes = 0;

        /**
         * PeerState SwarmSize.
         * @member {number} SwarmSize
         * @memberof manager.PeerState
         * @instance
         */
        PeerState.prototype.SwarmSize = 0;

        /**
         * PeerState OccupiedSlot.
         * @member {number} OccupiedSlot
         * @memberof manager.PeerState
         * @instance
         */
        PeerState.prototype.OccupiedSlot = 0;

        /**
         * PeerState FreeSlot.
         * @member {number} FreeSlot
         * @memberof manager.PeerState
         * @instance
         */
        PeerState.prototype.FreeSlot = 0;

        /**
         * PeerState WatchingTimeSec.
         * @member {number|Long} WatchingTimeSec
         * @memberof manager.PeerState
         * @instance
         */
        PeerState.prototype.WatchingTimeSec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * PeerState Rebuffers.
         * @member {number|Long} Rebuffers
         * @memberof manager.PeerState
         * @instance
         */
        PeerState.prototype.Rebuffers = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * PeerState RebufferingTime.
         * @member {number|Long} RebufferingTime
         * @memberof manager.PeerState
         * @instance
         */
        PeerState.prototype.RebufferingTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new PeerState instance using the specified properties.
         * @function create
         * @memberof manager.PeerState
         * @static
         * @param {manager.IPeerState=} [properties] Properties to set
         * @returns {manager.PeerState} PeerState instance
         */
        PeerState.create = function create(properties) {
            return new PeerState(properties);
        };

        /**
         * Encodes the specified PeerState message. Does not implicitly {@link manager.PeerState.verify|verify} messages.
         * @function encode
         * @memberof manager.PeerState
         * @static
         * @param {manager.IPeerState} message PeerState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeerState.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.BufferLengthBytes != null && message.hasOwnProperty("BufferLengthBytes"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.BufferLengthBytes);
            if (message.SwarmSize != null && message.hasOwnProperty("SwarmSize"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.SwarmSize);
            if (message.OccupiedSlot != null && message.hasOwnProperty("OccupiedSlot"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.OccupiedSlot);
            if (message.FreeSlot != null && message.hasOwnProperty("FreeSlot"))
                writer.uint32(/* id 4, wireType 5 =*/37).float(message.FreeSlot);
            if (message.WatchingTimeSec != null && message.hasOwnProperty("WatchingTimeSec"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.WatchingTimeSec);
            if (message.Rebuffers != null && message.hasOwnProperty("Rebuffers"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.Rebuffers);
            if (message.RebufferingTime != null && message.hasOwnProperty("RebufferingTime"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.RebufferingTime);
            return writer;
        };

        /**
         * Encodes the specified PeerState message, length delimited. Does not implicitly {@link manager.PeerState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.PeerState
         * @static
         * @param {manager.IPeerState} message PeerState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeerState.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PeerState message from the specified reader or buffer.
         * @function decode
         * @memberof manager.PeerState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.PeerState} PeerState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeerState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.PeerState();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.BufferLengthBytes = reader.float();
                    break;
                case 2:
                    message.SwarmSize = reader.float();
                    break;
                case 3:
                    message.OccupiedSlot = reader.float();
                    break;
                case 4:
                    message.FreeSlot = reader.float();
                    break;
                case 5:
                    message.WatchingTimeSec = reader.uint64();
                    break;
                case 6:
                    message.Rebuffers = reader.uint64();
                    break;
                case 7:
                    message.RebufferingTime = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PeerState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.PeerState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.PeerState} PeerState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeerState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PeerState message.
         * @function verify
         * @memberof manager.PeerState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PeerState.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.BufferLengthBytes != null && message.hasOwnProperty("BufferLengthBytes"))
                if (typeof message.BufferLengthBytes !== "number")
                    return "BufferLengthBytes: number expected";
            if (message.SwarmSize != null && message.hasOwnProperty("SwarmSize"))
                if (typeof message.SwarmSize !== "number")
                    return "SwarmSize: number expected";
            if (message.OccupiedSlot != null && message.hasOwnProperty("OccupiedSlot"))
                if (typeof message.OccupiedSlot !== "number")
                    return "OccupiedSlot: number expected";
            if (message.FreeSlot != null && message.hasOwnProperty("FreeSlot"))
                if (typeof message.FreeSlot !== "number")
                    return "FreeSlot: number expected";
            if (message.WatchingTimeSec != null && message.hasOwnProperty("WatchingTimeSec"))
                if (!$util.isInteger(message.WatchingTimeSec) && !(message.WatchingTimeSec && $util.isInteger(message.WatchingTimeSec.low) && $util.isInteger(message.WatchingTimeSec.high)))
                    return "WatchingTimeSec: integer|Long expected";
            if (message.Rebuffers != null && message.hasOwnProperty("Rebuffers"))
                if (!$util.isInteger(message.Rebuffers) && !(message.Rebuffers && $util.isInteger(message.Rebuffers.low) && $util.isInteger(message.Rebuffers.high)))
                    return "Rebuffers: integer|Long expected";
            if (message.RebufferingTime != null && message.hasOwnProperty("RebufferingTime"))
                if (!$util.isInteger(message.RebufferingTime) && !(message.RebufferingTime && $util.isInteger(message.RebufferingTime.low) && $util.isInteger(message.RebufferingTime.high)))
                    return "RebufferingTime: integer|Long expected";
            return null;
        };

        /**
         * Creates a PeerState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.PeerState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.PeerState} PeerState
         */
        PeerState.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.PeerState)
                return object;
            var message = new $root.manager.PeerState();
            if (object.BufferLengthBytes != null)
                message.BufferLengthBytes = Number(object.BufferLengthBytes);
            if (object.SwarmSize != null)
                message.SwarmSize = Number(object.SwarmSize);
            if (object.OccupiedSlot != null)
                message.OccupiedSlot = Number(object.OccupiedSlot);
            if (object.FreeSlot != null)
                message.FreeSlot = Number(object.FreeSlot);
            if (object.WatchingTimeSec != null)
                if ($util.Long)
                    (message.WatchingTimeSec = $util.Long.fromValue(object.WatchingTimeSec)).unsigned = true;
                else if (typeof object.WatchingTimeSec === "string")
                    message.WatchingTimeSec = parseInt(object.WatchingTimeSec, 10);
                else if (typeof object.WatchingTimeSec === "number")
                    message.WatchingTimeSec = object.WatchingTimeSec;
                else if (typeof object.WatchingTimeSec === "object")
                    message.WatchingTimeSec = new $util.LongBits(object.WatchingTimeSec.low >>> 0, object.WatchingTimeSec.high >>> 0).toNumber(true);
            if (object.Rebuffers != null)
                if ($util.Long)
                    (message.Rebuffers = $util.Long.fromValue(object.Rebuffers)).unsigned = true;
                else if (typeof object.Rebuffers === "string")
                    message.Rebuffers = parseInt(object.Rebuffers, 10);
                else if (typeof object.Rebuffers === "number")
                    message.Rebuffers = object.Rebuffers;
                else if (typeof object.Rebuffers === "object")
                    message.Rebuffers = new $util.LongBits(object.Rebuffers.low >>> 0, object.Rebuffers.high >>> 0).toNumber(true);
            if (object.RebufferingTime != null)
                if ($util.Long)
                    (message.RebufferingTime = $util.Long.fromValue(object.RebufferingTime)).unsigned = true;
                else if (typeof object.RebufferingTime === "string")
                    message.RebufferingTime = parseInt(object.RebufferingTime, 10);
                else if (typeof object.RebufferingTime === "number")
                    message.RebufferingTime = object.RebufferingTime;
                else if (typeof object.RebufferingTime === "object")
                    message.RebufferingTime = new $util.LongBits(object.RebufferingTime.low >>> 0, object.RebufferingTime.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a PeerState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.PeerState
         * @static
         * @param {manager.PeerState} message PeerState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PeerState.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.BufferLengthBytes = 0;
                object.SwarmSize = 0;
                object.OccupiedSlot = 0;
                object.FreeSlot = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.WatchingTimeSec = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.WatchingTimeSec = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.Rebuffers = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.Rebuffers = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.RebufferingTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.RebufferingTime = options.longs === String ? "0" : 0;
            }
            if (message.BufferLengthBytes != null && message.hasOwnProperty("BufferLengthBytes"))
                object.BufferLengthBytes = options.json && !isFinite(message.BufferLengthBytes) ? String(message.BufferLengthBytes) : message.BufferLengthBytes;
            if (message.SwarmSize != null && message.hasOwnProperty("SwarmSize"))
                object.SwarmSize = options.json && !isFinite(message.SwarmSize) ? String(message.SwarmSize) : message.SwarmSize;
            if (message.OccupiedSlot != null && message.hasOwnProperty("OccupiedSlot"))
                object.OccupiedSlot = options.json && !isFinite(message.OccupiedSlot) ? String(message.OccupiedSlot) : message.OccupiedSlot;
            if (message.FreeSlot != null && message.hasOwnProperty("FreeSlot"))
                object.FreeSlot = options.json && !isFinite(message.FreeSlot) ? String(message.FreeSlot) : message.FreeSlot;
            if (message.WatchingTimeSec != null && message.hasOwnProperty("WatchingTimeSec"))
                if (typeof message.WatchingTimeSec === "number")
                    object.WatchingTimeSec = options.longs === String ? String(message.WatchingTimeSec) : message.WatchingTimeSec;
                else
                    object.WatchingTimeSec = options.longs === String ? $util.Long.prototype.toString.call(message.WatchingTimeSec) : options.longs === Number ? new $util.LongBits(message.WatchingTimeSec.low >>> 0, message.WatchingTimeSec.high >>> 0).toNumber(true) : message.WatchingTimeSec;
            if (message.Rebuffers != null && message.hasOwnProperty("Rebuffers"))
                if (typeof message.Rebuffers === "number")
                    object.Rebuffers = options.longs === String ? String(message.Rebuffers) : message.Rebuffers;
                else
                    object.Rebuffers = options.longs === String ? $util.Long.prototype.toString.call(message.Rebuffers) : options.longs === Number ? new $util.LongBits(message.Rebuffers.low >>> 0, message.Rebuffers.high >>> 0).toNumber(true) : message.Rebuffers;
            if (message.RebufferingTime != null && message.hasOwnProperty("RebufferingTime"))
                if (typeof message.RebufferingTime === "number")
                    object.RebufferingTime = options.longs === String ? String(message.RebufferingTime) : message.RebufferingTime;
                else
                    object.RebufferingTime = options.longs === String ? $util.Long.prototype.toString.call(message.RebufferingTime) : options.longs === Number ? new $util.LongBits(message.RebufferingTime.low >>> 0, message.RebufferingTime.high >>> 0).toNumber(true) : message.RebufferingTime;
            return object;
        };

        /**
         * Converts this PeerState to JSON.
         * @function toJSON
         * @memberof manager.PeerState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PeerState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PeerState;
    })();

    manager.PlayerState = (function() {

        /**
         * Properties of a PlayerState.
         * @memberof manager
         * @interface IPlayerState
         * @property {number|Long|null} [Timeshift] PlayerState Timeshift
         * @property {number|Long|null} [Quality] PlayerState Quality
         * @property {manager.PlayingState|null} [PlayingState] PlayerState PlayingState
         */

        /**
         * Constructs a new PlayerState.
         * @memberof manager
         * @classdesc Represents a PlayerState.
         * @implements IPlayerState
         * @constructor
         * @param {manager.IPlayerState=} [properties] Properties to set
         */
        function PlayerState(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerState Timeshift.
         * @member {number|Long} Timeshift
         * @memberof manager.PlayerState
         * @instance
         */
        PlayerState.prototype.Timeshift = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlayerState Quality.
         * @member {number|Long} Quality
         * @memberof manager.PlayerState
         * @instance
         */
        PlayerState.prototype.Quality = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlayerState PlayingState.
         * @member {manager.PlayingState} PlayingState
         * @memberof manager.PlayerState
         * @instance
         */
        PlayerState.prototype.PlayingState = 0;

        /**
         * Creates a new PlayerState instance using the specified properties.
         * @function create
         * @memberof manager.PlayerState
         * @static
         * @param {manager.IPlayerState=} [properties] Properties to set
         * @returns {manager.PlayerState} PlayerState instance
         */
        PlayerState.create = function create(properties) {
            return new PlayerState(properties);
        };

        /**
         * Encodes the specified PlayerState message. Does not implicitly {@link manager.PlayerState.verify|verify} messages.
         * @function encode
         * @memberof manager.PlayerState
         * @static
         * @param {manager.IPlayerState} message PlayerState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerState.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Timeshift != null && message.hasOwnProperty("Timeshift"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.Timeshift);
            if (message.Quality != null && message.hasOwnProperty("Quality"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.Quality);
            if (message.PlayingState != null && message.hasOwnProperty("PlayingState"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.PlayingState);
            return writer;
        };

        /**
         * Encodes the specified PlayerState message, length delimited. Does not implicitly {@link manager.PlayerState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.PlayerState
         * @static
         * @param {manager.IPlayerState} message PlayerState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerState.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerState message from the specified reader or buffer.
         * @function decode
         * @memberof manager.PlayerState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.PlayerState} PlayerState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.PlayerState();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Timeshift = reader.int64();
                    break;
                case 2:
                    message.Quality = reader.int64();
                    break;
                case 3:
                    message.PlayingState = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.PlayerState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.PlayerState} PlayerState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerState message.
         * @function verify
         * @memberof manager.PlayerState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerState.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Timeshift != null && message.hasOwnProperty("Timeshift"))
                if (!$util.isInteger(message.Timeshift) && !(message.Timeshift && $util.isInteger(message.Timeshift.low) && $util.isInteger(message.Timeshift.high)))
                    return "Timeshift: integer|Long expected";
            if (message.Quality != null && message.hasOwnProperty("Quality"))
                if (!$util.isInteger(message.Quality) && !(message.Quality && $util.isInteger(message.Quality.low) && $util.isInteger(message.Quality.high)))
                    return "Quality: integer|Long expected";
            if (message.PlayingState != null && message.hasOwnProperty("PlayingState"))
                switch (message.PlayingState) {
                default:
                    return "PlayingState: enum value expected";
                case 0:
                case 1:
                case 3:
                case 4:
                case 5:
                    break;
                }
            return null;
        };

        /**
         * Creates a PlayerState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.PlayerState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.PlayerState} PlayerState
         */
        PlayerState.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.PlayerState)
                return object;
            var message = new $root.manager.PlayerState();
            if (object.Timeshift != null)
                if ($util.Long)
                    (message.Timeshift = $util.Long.fromValue(object.Timeshift)).unsigned = false;
                else if (typeof object.Timeshift === "string")
                    message.Timeshift = parseInt(object.Timeshift, 10);
                else if (typeof object.Timeshift === "number")
                    message.Timeshift = object.Timeshift;
                else if (typeof object.Timeshift === "object")
                    message.Timeshift = new $util.LongBits(object.Timeshift.low >>> 0, object.Timeshift.high >>> 0).toNumber();
            if (object.Quality != null)
                if ($util.Long)
                    (message.Quality = $util.Long.fromValue(object.Quality)).unsigned = false;
                else if (typeof object.Quality === "string")
                    message.Quality = parseInt(object.Quality, 10);
                else if (typeof object.Quality === "number")
                    message.Quality = object.Quality;
                else if (typeof object.Quality === "object")
                    message.Quality = new $util.LongBits(object.Quality.low >>> 0, object.Quality.high >>> 0).toNumber();
            switch (object.PlayingState) {
            case "IDLE":
            case 0:
                message.PlayingState = 0;
                break;
            case "PLAYING":
            case 1:
                message.PlayingState = 1;
                break;
            case "BUFFERING":
            case 3:
                message.PlayingState = 3;
                break;
            case "PAUSED":
            case 4:
                message.PlayingState = 4;
                break;
            case "STOP":
            case 5:
                message.PlayingState = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a PlayerState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.PlayerState
         * @static
         * @param {manager.PlayerState} message PlayerState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerState.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.Timeshift = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.Timeshift = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.Quality = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.Quality = options.longs === String ? "0" : 0;
                object.PlayingState = options.enums === String ? "IDLE" : 0;
            }
            if (message.Timeshift != null && message.hasOwnProperty("Timeshift"))
                if (typeof message.Timeshift === "number")
                    object.Timeshift = options.longs === String ? String(message.Timeshift) : message.Timeshift;
                else
                    object.Timeshift = options.longs === String ? $util.Long.prototype.toString.call(message.Timeshift) : options.longs === Number ? new $util.LongBits(message.Timeshift.low >>> 0, message.Timeshift.high >>> 0).toNumber() : message.Timeshift;
            if (message.Quality != null && message.hasOwnProperty("Quality"))
                if (typeof message.Quality === "number")
                    object.Quality = options.longs === String ? String(message.Quality) : message.Quality;
                else
                    object.Quality = options.longs === String ? $util.Long.prototype.toString.call(message.Quality) : options.longs === Number ? new $util.LongBits(message.Quality.low >>> 0, message.Quality.high >>> 0).toNumber() : message.Quality;
            if (message.PlayingState != null && message.hasOwnProperty("PlayingState"))
                object.PlayingState = options.enums === String ? $root.manager.PlayingState[message.PlayingState] : message.PlayingState;
            return object;
        };

        /**
         * Converts this PlayerState to JSON.
         * @function toJSON
         * @memberof manager.PlayerState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerState;
    })();

    manager.PingPongExchange = (function() {

        /**
         * Properties of a PingPongExchange.
         * @memberof manager
         * @interface IPingPongExchange
         * @property {number|Long|null} [Seq] PingPongExchange Seq
         * @property {string|null} [Pid] PingPongExchange Pid
         * @property {number|Long|null} [Start] PingPongExchange Start
         * @property {number|Long|null} [End] PingPongExchange End
         * @property {string|null} [State] PingPongExchange State
         */

        /**
         * Constructs a new PingPongExchange.
         * @memberof manager
         * @classdesc Represents a PingPongExchange.
         * @implements IPingPongExchange
         * @constructor
         * @param {manager.IPingPongExchange=} [properties] Properties to set
         */
        function PingPongExchange(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PingPongExchange Seq.
         * @member {number|Long} Seq
         * @memberof manager.PingPongExchange
         * @instance
         */
        PingPongExchange.prototype.Seq = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PingPongExchange Pid.
         * @member {string} Pid
         * @memberof manager.PingPongExchange
         * @instance
         */
        PingPongExchange.prototype.Pid = "";

        /**
         * PingPongExchange Start.
         * @member {number|Long} Start
         * @memberof manager.PingPongExchange
         * @instance
         */
        PingPongExchange.prototype.Start = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PingPongExchange End.
         * @member {number|Long} End
         * @memberof manager.PingPongExchange
         * @instance
         */
        PingPongExchange.prototype.End = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PingPongExchange State.
         * @member {string} State
         * @memberof manager.PingPongExchange
         * @instance
         */
        PingPongExchange.prototype.State = "";

        /**
         * Creates a new PingPongExchange instance using the specified properties.
         * @function create
         * @memberof manager.PingPongExchange
         * @static
         * @param {manager.IPingPongExchange=} [properties] Properties to set
         * @returns {manager.PingPongExchange} PingPongExchange instance
         */
        PingPongExchange.create = function create(properties) {
            return new PingPongExchange(properties);
        };

        /**
         * Encodes the specified PingPongExchange message. Does not implicitly {@link manager.PingPongExchange.verify|verify} messages.
         * @function encode
         * @memberof manager.PingPongExchange
         * @static
         * @param {manager.IPingPongExchange} message PingPongExchange message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PingPongExchange.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Seq != null && message.hasOwnProperty("Seq"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.Seq);
            if (message.Pid != null && message.hasOwnProperty("Pid"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.Pid);
            if (message.Start != null && message.hasOwnProperty("Start"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.Start);
            if (message.End != null && message.hasOwnProperty("End"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.End);
            if (message.State != null && message.hasOwnProperty("State"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.State);
            return writer;
        };

        /**
         * Encodes the specified PingPongExchange message, length delimited. Does not implicitly {@link manager.PingPongExchange.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.PingPongExchange
         * @static
         * @param {manager.IPingPongExchange} message PingPongExchange message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PingPongExchange.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PingPongExchange message from the specified reader or buffer.
         * @function decode
         * @memberof manager.PingPongExchange
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.PingPongExchange} PingPongExchange
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PingPongExchange.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.PingPongExchange();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Seq = reader.int64();
                    break;
                case 2:
                    message.Pid = reader.string();
                    break;
                case 3:
                    message.Start = reader.int64();
                    break;
                case 4:
                    message.End = reader.int64();
                    break;
                case 5:
                    message.State = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PingPongExchange message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.PingPongExchange
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.PingPongExchange} PingPongExchange
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PingPongExchange.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PingPongExchange message.
         * @function verify
         * @memberof manager.PingPongExchange
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PingPongExchange.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Seq != null && message.hasOwnProperty("Seq"))
                if (!$util.isInteger(message.Seq) && !(message.Seq && $util.isInteger(message.Seq.low) && $util.isInteger(message.Seq.high)))
                    return "Seq: integer|Long expected";
            if (message.Pid != null && message.hasOwnProperty("Pid"))
                if (!$util.isString(message.Pid))
                    return "Pid: string expected";
            if (message.Start != null && message.hasOwnProperty("Start"))
                if (!$util.isInteger(message.Start) && !(message.Start && $util.isInteger(message.Start.low) && $util.isInteger(message.Start.high)))
                    return "Start: integer|Long expected";
            if (message.End != null && message.hasOwnProperty("End"))
                if (!$util.isInteger(message.End) && !(message.End && $util.isInteger(message.End.low) && $util.isInteger(message.End.high)))
                    return "End: integer|Long expected";
            if (message.State != null && message.hasOwnProperty("State"))
                if (!$util.isString(message.State))
                    return "State: string expected";
            return null;
        };

        /**
         * Creates a PingPongExchange message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.PingPongExchange
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.PingPongExchange} PingPongExchange
         */
        PingPongExchange.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.PingPongExchange)
                return object;
            var message = new $root.manager.PingPongExchange();
            if (object.Seq != null)
                if ($util.Long)
                    (message.Seq = $util.Long.fromValue(object.Seq)).unsigned = false;
                else if (typeof object.Seq === "string")
                    message.Seq = parseInt(object.Seq, 10);
                else if (typeof object.Seq === "number")
                    message.Seq = object.Seq;
                else if (typeof object.Seq === "object")
                    message.Seq = new $util.LongBits(object.Seq.low >>> 0, object.Seq.high >>> 0).toNumber();
            if (object.Pid != null)
                message.Pid = String(object.Pid);
            if (object.Start != null)
                if ($util.Long)
                    (message.Start = $util.Long.fromValue(object.Start)).unsigned = false;
                else if (typeof object.Start === "string")
                    message.Start = parseInt(object.Start, 10);
                else if (typeof object.Start === "number")
                    message.Start = object.Start;
                else if (typeof object.Start === "object")
                    message.Start = new $util.LongBits(object.Start.low >>> 0, object.Start.high >>> 0).toNumber();
            if (object.End != null)
                if ($util.Long)
                    (message.End = $util.Long.fromValue(object.End)).unsigned = false;
                else if (typeof object.End === "string")
                    message.End = parseInt(object.End, 10);
                else if (typeof object.End === "number")
                    message.End = object.End;
                else if (typeof object.End === "object")
                    message.End = new $util.LongBits(object.End.low >>> 0, object.End.high >>> 0).toNumber();
            if (object.State != null)
                message.State = String(object.State);
            return message;
        };

        /**
         * Creates a plain object from a PingPongExchange message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.PingPongExchange
         * @static
         * @param {manager.PingPongExchange} message PingPongExchange
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PingPongExchange.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.Seq = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.Seq = options.longs === String ? "0" : 0;
                object.Pid = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.Start = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.Start = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.End = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.End = options.longs === String ? "0" : 0;
                object.State = "";
            }
            if (message.Seq != null && message.hasOwnProperty("Seq"))
                if (typeof message.Seq === "number")
                    object.Seq = options.longs === String ? String(message.Seq) : message.Seq;
                else
                    object.Seq = options.longs === String ? $util.Long.prototype.toString.call(message.Seq) : options.longs === Number ? new $util.LongBits(message.Seq.low >>> 0, message.Seq.high >>> 0).toNumber() : message.Seq;
            if (message.Pid != null && message.hasOwnProperty("Pid"))
                object.Pid = message.Pid;
            if (message.Start != null && message.hasOwnProperty("Start"))
                if (typeof message.Start === "number")
                    object.Start = options.longs === String ? String(message.Start) : message.Start;
                else
                    object.Start = options.longs === String ? $util.Long.prototype.toString.call(message.Start) : options.longs === Number ? new $util.LongBits(message.Start.low >>> 0, message.Start.high >>> 0).toNumber() : message.Start;
            if (message.End != null && message.hasOwnProperty("End"))
                if (typeof message.End === "number")
                    object.End = options.longs === String ? String(message.End) : message.End;
                else
                    object.End = options.longs === String ? $util.Long.prototype.toString.call(message.End) : options.longs === Number ? new $util.LongBits(message.End.low >>> 0, message.End.high >>> 0).toNumber() : message.End;
            if (message.State != null && message.hasOwnProperty("State"))
                object.State = message.State;
            return object;
        };

        /**
         * Converts this PingPongExchange to JSON.
         * @function toJSON
         * @memberof manager.PingPongExchange
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PingPongExchange.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PingPongExchange;
    })();

    manager.NetworkInfo = (function() {

        /**
         * Properties of a NetworkInfo.
         * @memberof manager
         * @interface INetworkInfo
         * @property {string|null} [NetworkType] NetworkInfo NetworkType
         * @property {number|null} [Downlink] NetworkInfo Downlink
         * @property {number|null} [RTT] NetworkInfo RTT
         */

        /**
         * Constructs a new NetworkInfo.
         * @memberof manager
         * @classdesc Represents a NetworkInfo.
         * @implements INetworkInfo
         * @constructor
         * @param {manager.INetworkInfo=} [properties] Properties to set
         */
        function NetworkInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NetworkInfo NetworkType.
         * @member {string} NetworkType
         * @memberof manager.NetworkInfo
         * @instance
         */
        NetworkInfo.prototype.NetworkType = "";

        /**
         * NetworkInfo Downlink.
         * @member {number} Downlink
         * @memberof manager.NetworkInfo
         * @instance
         */
        NetworkInfo.prototype.Downlink = 0;

        /**
         * NetworkInfo RTT.
         * @member {number} RTT
         * @memberof manager.NetworkInfo
         * @instance
         */
        NetworkInfo.prototype.RTT = 0;

        /**
         * Creates a new NetworkInfo instance using the specified properties.
         * @function create
         * @memberof manager.NetworkInfo
         * @static
         * @param {manager.INetworkInfo=} [properties] Properties to set
         * @returns {manager.NetworkInfo} NetworkInfo instance
         */
        NetworkInfo.create = function create(properties) {
            return new NetworkInfo(properties);
        };

        /**
         * Encodes the specified NetworkInfo message. Does not implicitly {@link manager.NetworkInfo.verify|verify} messages.
         * @function encode
         * @memberof manager.NetworkInfo
         * @static
         * @param {manager.INetworkInfo} message NetworkInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NetworkInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.NetworkType != null && message.hasOwnProperty("NetworkType"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.NetworkType);
            if (message.Downlink != null && message.hasOwnProperty("Downlink"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.Downlink);
            if (message.RTT != null && message.hasOwnProperty("RTT"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.RTT);
            return writer;
        };

        /**
         * Encodes the specified NetworkInfo message, length delimited. Does not implicitly {@link manager.NetworkInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.NetworkInfo
         * @static
         * @param {manager.INetworkInfo} message NetworkInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NetworkInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NetworkInfo message from the specified reader or buffer.
         * @function decode
         * @memberof manager.NetworkInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.NetworkInfo} NetworkInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NetworkInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.NetworkInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.NetworkType = reader.string();
                    break;
                case 2:
                    message.Downlink = reader.float();
                    break;
                case 3:
                    message.RTT = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NetworkInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.NetworkInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.NetworkInfo} NetworkInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NetworkInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NetworkInfo message.
         * @function verify
         * @memberof manager.NetworkInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NetworkInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.NetworkType != null && message.hasOwnProperty("NetworkType"))
                if (!$util.isString(message.NetworkType))
                    return "NetworkType: string expected";
            if (message.Downlink != null && message.hasOwnProperty("Downlink"))
                if (typeof message.Downlink !== "number")
                    return "Downlink: number expected";
            if (message.RTT != null && message.hasOwnProperty("RTT"))
                if (typeof message.RTT !== "number")
                    return "RTT: number expected";
            return null;
        };

        /**
         * Creates a NetworkInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.NetworkInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.NetworkInfo} NetworkInfo
         */
        NetworkInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.NetworkInfo)
                return object;
            var message = new $root.manager.NetworkInfo();
            if (object.NetworkType != null)
                message.NetworkType = String(object.NetworkType);
            if (object.Downlink != null)
                message.Downlink = Number(object.Downlink);
            if (object.RTT != null)
                message.RTT = Number(object.RTT);
            return message;
        };

        /**
         * Creates a plain object from a NetworkInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.NetworkInfo
         * @static
         * @param {manager.NetworkInfo} message NetworkInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NetworkInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.NetworkType = "";
                object.Downlink = 0;
                object.RTT = 0;
            }
            if (message.NetworkType != null && message.hasOwnProperty("NetworkType"))
                object.NetworkType = message.NetworkType;
            if (message.Downlink != null && message.hasOwnProperty("Downlink"))
                object.Downlink = options.json && !isFinite(message.Downlink) ? String(message.Downlink) : message.Downlink;
            if (message.RTT != null && message.hasOwnProperty("RTT"))
                object.RTT = options.json && !isFinite(message.RTT) ? String(message.RTT) : message.RTT;
            return object;
        };

        /**
         * Converts this NetworkInfo to JSON.
         * @function toJSON
         * @memberof manager.NetworkInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NetworkInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NetworkInfo;
    })();

    manager.ResourceAvailability = (function() {

        /**
         * Properties of a ResourceAvailability.
         * @memberof manager
         * @interface IResourceAvailability
         * @property {string|null} [URL] ResourceAvailability URL
         * @property {Array.<string>|null} [Pids] ResourceAvailability Pids
         */

        /**
         * Constructs a new ResourceAvailability.
         * @memberof manager
         * @classdesc Represents a ResourceAvailability.
         * @implements IResourceAvailability
         * @constructor
         * @param {manager.IResourceAvailability=} [properties] Properties to set
         */
        function ResourceAvailability(properties) {
            this.Pids = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResourceAvailability URL.
         * @member {string} URL
         * @memberof manager.ResourceAvailability
         * @instance
         */
        ResourceAvailability.prototype.URL = "";

        /**
         * ResourceAvailability Pids.
         * @member {Array.<string>} Pids
         * @memberof manager.ResourceAvailability
         * @instance
         */
        ResourceAvailability.prototype.Pids = $util.emptyArray;

        /**
         * Creates a new ResourceAvailability instance using the specified properties.
         * @function create
         * @memberof manager.ResourceAvailability
         * @static
         * @param {manager.IResourceAvailability=} [properties] Properties to set
         * @returns {manager.ResourceAvailability} ResourceAvailability instance
         */
        ResourceAvailability.create = function create(properties) {
            return new ResourceAvailability(properties);
        };

        /**
         * Encodes the specified ResourceAvailability message. Does not implicitly {@link manager.ResourceAvailability.verify|verify} messages.
         * @function encode
         * @memberof manager.ResourceAvailability
         * @static
         * @param {manager.IResourceAvailability} message ResourceAvailability message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceAvailability.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.URL != null && message.hasOwnProperty("URL"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.URL);
            if (message.Pids != null && message.Pids.length)
                for (var i = 0; i < message.Pids.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.Pids[i]);
            return writer;
        };

        /**
         * Encodes the specified ResourceAvailability message, length delimited. Does not implicitly {@link manager.ResourceAvailability.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.ResourceAvailability
         * @static
         * @param {manager.IResourceAvailability} message ResourceAvailability message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceAvailability.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResourceAvailability message from the specified reader or buffer.
         * @function decode
         * @memberof manager.ResourceAvailability
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.ResourceAvailability} ResourceAvailability
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceAvailability.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.ResourceAvailability();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.URL = reader.string();
                    break;
                case 2:
                    if (!(message.Pids && message.Pids.length))
                        message.Pids = [];
                    message.Pids.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResourceAvailability message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.ResourceAvailability
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.ResourceAvailability} ResourceAvailability
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceAvailability.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResourceAvailability message.
         * @function verify
         * @memberof manager.ResourceAvailability
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResourceAvailability.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.URL != null && message.hasOwnProperty("URL"))
                if (!$util.isString(message.URL))
                    return "URL: string expected";
            if (message.Pids != null && message.hasOwnProperty("Pids")) {
                if (!Array.isArray(message.Pids))
                    return "Pids: array expected";
                for (var i = 0; i < message.Pids.length; ++i)
                    if (!$util.isString(message.Pids[i]))
                        return "Pids: string[] expected";
            }
            return null;
        };

        /**
         * Creates a ResourceAvailability message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.ResourceAvailability
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.ResourceAvailability} ResourceAvailability
         */
        ResourceAvailability.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.ResourceAvailability)
                return object;
            var message = new $root.manager.ResourceAvailability();
            if (object.URL != null)
                message.URL = String(object.URL);
            if (object.Pids) {
                if (!Array.isArray(object.Pids))
                    throw TypeError(".manager.ResourceAvailability.Pids: array expected");
                message.Pids = [];
                for (var i = 0; i < object.Pids.length; ++i)
                    message.Pids[i] = String(object.Pids[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a ResourceAvailability message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.ResourceAvailability
         * @static
         * @param {manager.ResourceAvailability} message ResourceAvailability
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResourceAvailability.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.Pids = [];
            if (options.defaults)
                object.URL = "";
            if (message.URL != null && message.hasOwnProperty("URL"))
                object.URL = message.URL;
            if (message.Pids && message.Pids.length) {
                object.Pids = [];
                for (var j = 0; j < message.Pids.length; ++j)
                    object.Pids[j] = message.Pids[j];
            }
            return object;
        };

        /**
         * Converts this ResourceAvailability to JSON.
         * @function toJSON
         * @memberof manager.ResourceAvailability
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResourceAvailability.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResourceAvailability;
    })();

    /**
     * SyncType enum.
     * @name manager.SyncType
     * @enum {string}
     * @property {number} SDP=0 SDP value
     * @property {number} PLST=1 PLST value
     */
    manager.SyncType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SDP"] = 0;
        values[valuesById[1] = "PLST"] = 1;
        return values;
    })();

    manager.SyncArgs = (function() {

        /**
         * Properties of a SyncArgs.
         * @memberof manager
         * @interface ISyncArgs
         * @property {string|null} [From] SyncArgs From
         * @property {string|null} [To] SyncArgs To
         * @property {string|null} [Offer] SyncArgs Offer
         * @property {Array.<string>|null} [Candidates] SyncArgs Candidates
         * @property {manager.PairingRequestType|null} [ReqType] SyncArgs ReqType
         */

        /**
         * Constructs a new SyncArgs.
         * @memberof manager
         * @classdesc Represents a SyncArgs.
         * @implements ISyncArgs
         * @constructor
         * @param {manager.ISyncArgs=} [properties] Properties to set
         */
        function SyncArgs(properties) {
            this.Candidates = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SyncArgs From.
         * @member {string} From
         * @memberof manager.SyncArgs
         * @instance
         */
        SyncArgs.prototype.From = "";

        /**
         * SyncArgs To.
         * @member {string} To
         * @memberof manager.SyncArgs
         * @instance
         */
        SyncArgs.prototype.To = "";

        /**
         * SyncArgs Offer.
         * @member {string} Offer
         * @memberof manager.SyncArgs
         * @instance
         */
        SyncArgs.prototype.Offer = "";

        /**
         * SyncArgs Candidates.
         * @member {Array.<string>} Candidates
         * @memberof manager.SyncArgs
         * @instance
         */
        SyncArgs.prototype.Candidates = $util.emptyArray;

        /**
         * SyncArgs ReqType.
         * @member {manager.PairingRequestType} ReqType
         * @memberof manager.SyncArgs
         * @instance
         */
        SyncArgs.prototype.ReqType = 0;

        /**
         * Creates a new SyncArgs instance using the specified properties.
         * @function create
         * @memberof manager.SyncArgs
         * @static
         * @param {manager.ISyncArgs=} [properties] Properties to set
         * @returns {manager.SyncArgs} SyncArgs instance
         */
        SyncArgs.create = function create(properties) {
            return new SyncArgs(properties);
        };

        /**
         * Encodes the specified SyncArgs message. Does not implicitly {@link manager.SyncArgs.verify|verify} messages.
         * @function encode
         * @memberof manager.SyncArgs
         * @static
         * @param {manager.ISyncArgs} message SyncArgs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncArgs.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.From != null && message.hasOwnProperty("From"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.From);
            if (message.To != null && message.hasOwnProperty("To"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.To);
            if (message.Offer != null && message.hasOwnProperty("Offer"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.Offer);
            if (message.Candidates != null && message.Candidates.length)
                for (var i = 0; i < message.Candidates.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.Candidates[i]);
            if (message.ReqType != null && message.hasOwnProperty("ReqType"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.ReqType);
            return writer;
        };

        /**
         * Encodes the specified SyncArgs message, length delimited. Does not implicitly {@link manager.SyncArgs.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.SyncArgs
         * @static
         * @param {manager.ISyncArgs} message SyncArgs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncArgs.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SyncArgs message from the specified reader or buffer.
         * @function decode
         * @memberof manager.SyncArgs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.SyncArgs} SyncArgs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncArgs.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.SyncArgs();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.From = reader.string();
                    break;
                case 2:
                    message.To = reader.string();
                    break;
                case 3:
                    message.Offer = reader.string();
                    break;
                case 4:
                    if (!(message.Candidates && message.Candidates.length))
                        message.Candidates = [];
                    message.Candidates.push(reader.string());
                    break;
                case 5:
                    message.ReqType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SyncArgs message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.SyncArgs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.SyncArgs} SyncArgs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncArgs.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SyncArgs message.
         * @function verify
         * @memberof manager.SyncArgs
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SyncArgs.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.From != null && message.hasOwnProperty("From"))
                if (!$util.isString(message.From))
                    return "From: string expected";
            if (message.To != null && message.hasOwnProperty("To"))
                if (!$util.isString(message.To))
                    return "To: string expected";
            if (message.Offer != null && message.hasOwnProperty("Offer"))
                if (!$util.isString(message.Offer))
                    return "Offer: string expected";
            if (message.Candidates != null && message.hasOwnProperty("Candidates")) {
                if (!Array.isArray(message.Candidates))
                    return "Candidates: array expected";
                for (var i = 0; i < message.Candidates.length; ++i)
                    if (!$util.isString(message.Candidates[i]))
                        return "Candidates: string[] expected";
            }
            if (message.ReqType != null && message.hasOwnProperty("ReqType"))
                switch (message.ReqType) {
                default:
                    return "ReqType: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a SyncArgs message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.SyncArgs
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.SyncArgs} SyncArgs
         */
        SyncArgs.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.SyncArgs)
                return object;
            var message = new $root.manager.SyncArgs();
            if (object.From != null)
                message.From = String(object.From);
            if (object.To != null)
                message.To = String(object.To);
            if (object.Offer != null)
                message.Offer = String(object.Offer);
            if (object.Candidates) {
                if (!Array.isArray(object.Candidates))
                    throw TypeError(".manager.SyncArgs.Candidates: array expected");
                message.Candidates = [];
                for (var i = 0; i < object.Candidates.length; ++i)
                    message.Candidates[i] = String(object.Candidates[i]);
            }
            switch (object.ReqType) {
            case "PairingRequestType_offer":
            case 0:
                message.ReqType = 0;
                break;
            case "PairingRequestType_answer":
            case 1:
                message.ReqType = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SyncArgs message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.SyncArgs
         * @static
         * @param {manager.SyncArgs} message SyncArgs
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SyncArgs.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.Candidates = [];
            if (options.defaults) {
                object.From = "";
                object.To = "";
                object.Offer = "";
                object.ReqType = options.enums === String ? "PairingRequestType_offer" : 0;
            }
            if (message.From != null && message.hasOwnProperty("From"))
                object.From = message.From;
            if (message.To != null && message.hasOwnProperty("To"))
                object.To = message.To;
            if (message.Offer != null && message.hasOwnProperty("Offer"))
                object.Offer = message.Offer;
            if (message.Candidates && message.Candidates.length) {
                object.Candidates = [];
                for (var j = 0; j < message.Candidates.length; ++j)
                    object.Candidates[j] = message.Candidates[j];
            }
            if (message.ReqType != null && message.hasOwnProperty("ReqType"))
                object.ReqType = options.enums === String ? $root.manager.PairingRequestType[message.ReqType] : message.ReqType;
            return object;
        };

        /**
         * Converts this SyncArgs to JSON.
         * @function toJSON
         * @memberof manager.SyncArgs
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SyncArgs.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SyncArgs;
    })();

    manager.PlstArgs = (function() {

        /**
         * Properties of a PlstArgs.
         * @memberof manager
         * @interface IPlstArgs
         * @property {string|null} [ChannelName] PlstArgs ChannelName
         * @property {Array.<number>|null} [Plist] PlstArgs Plist
         */

        /**
         * Constructs a new PlstArgs.
         * @memberof manager
         * @classdesc Represents a PlstArgs.
         * @implements IPlstArgs
         * @constructor
         * @param {manager.IPlstArgs=} [properties] Properties to set
         */
        function PlstArgs(properties) {
            this.Plist = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlstArgs ChannelName.
         * @member {string} ChannelName
         * @memberof manager.PlstArgs
         * @instance
         */
        PlstArgs.prototype.ChannelName = "";

        /**
         * PlstArgs Plist.
         * @member {Array.<number>} Plist
         * @memberof manager.PlstArgs
         * @instance
         */
        PlstArgs.prototype.Plist = $util.emptyArray;

        /**
         * Creates a new PlstArgs instance using the specified properties.
         * @function create
         * @memberof manager.PlstArgs
         * @static
         * @param {manager.IPlstArgs=} [properties] Properties to set
         * @returns {manager.PlstArgs} PlstArgs instance
         */
        PlstArgs.create = function create(properties) {
            return new PlstArgs(properties);
        };

        /**
         * Encodes the specified PlstArgs message. Does not implicitly {@link manager.PlstArgs.verify|verify} messages.
         * @function encode
         * @memberof manager.PlstArgs
         * @static
         * @param {manager.IPlstArgs} message PlstArgs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlstArgs.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ChannelName != null && message.hasOwnProperty("ChannelName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ChannelName);
            if (message.Plist != null && message.Plist.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.Plist.length; ++i)
                    writer.uint32(message.Plist[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified PlstArgs message, length delimited. Does not implicitly {@link manager.PlstArgs.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.PlstArgs
         * @static
         * @param {manager.IPlstArgs} message PlstArgs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlstArgs.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlstArgs message from the specified reader or buffer.
         * @function decode
         * @memberof manager.PlstArgs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.PlstArgs} PlstArgs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlstArgs.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.PlstArgs();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ChannelName = reader.string();
                    break;
                case 2:
                    if (!(message.Plist && message.Plist.length))
                        message.Plist = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.Plist.push(reader.uint32());
                    } else
                        message.Plist.push(reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlstArgs message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.PlstArgs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.PlstArgs} PlstArgs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlstArgs.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlstArgs message.
         * @function verify
         * @memberof manager.PlstArgs
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlstArgs.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ChannelName != null && message.hasOwnProperty("ChannelName"))
                if (!$util.isString(message.ChannelName))
                    return "ChannelName: string expected";
            if (message.Plist != null && message.hasOwnProperty("Plist")) {
                if (!Array.isArray(message.Plist))
                    return "Plist: array expected";
                for (var i = 0; i < message.Plist.length; ++i)
                    if (!$util.isInteger(message.Plist[i]))
                        return "Plist: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a PlstArgs message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.PlstArgs
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.PlstArgs} PlstArgs
         */
        PlstArgs.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.PlstArgs)
                return object;
            var message = new $root.manager.PlstArgs();
            if (object.ChannelName != null)
                message.ChannelName = String(object.ChannelName);
            if (object.Plist) {
                if (!Array.isArray(object.Plist))
                    throw TypeError(".manager.PlstArgs.Plist: array expected");
                message.Plist = [];
                for (var i = 0; i < object.Plist.length; ++i)
                    message.Plist[i] = object.Plist[i] >>> 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a PlstArgs message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.PlstArgs
         * @static
         * @param {manager.PlstArgs} message PlstArgs
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlstArgs.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.Plist = [];
            if (options.defaults)
                object.ChannelName = "";
            if (message.ChannelName != null && message.hasOwnProperty("ChannelName"))
                object.ChannelName = message.ChannelName;
            if (message.Plist && message.Plist.length) {
                object.Plist = [];
                for (var j = 0; j < message.Plist.length; ++j)
                    object.Plist[j] = message.Plist[j];
            }
            return object;
        };

        /**
         * Converts this PlstArgs to JSON.
         * @function toJSON
         * @memberof manager.PlstArgs
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlstArgs.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlstArgs;
    })();

    manager.SyncMsg = (function() {

        /**
         * Properties of a SyncMsg.
         * @memberof manager
         * @interface ISyncMsg
         * @property {manager.SyncType|null} [Type] SyncMsg Type
         * @property {manager.ISyncArgs|null} [SyncArgs] SyncMsg SyncArgs
         * @property {manager.IPlstArgs|null} [PlstArgs] SyncMsg PlstArgs
         */

        /**
         * Constructs a new SyncMsg.
         * @memberof manager
         * @classdesc Represents a SyncMsg.
         * @implements ISyncMsg
         * @constructor
         * @param {manager.ISyncMsg=} [properties] Properties to set
         */
        function SyncMsg(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SyncMsg Type.
         * @member {manager.SyncType} Type
         * @memberof manager.SyncMsg
         * @instance
         */
        SyncMsg.prototype.Type = 0;

        /**
         * SyncMsg SyncArgs.
         * @member {manager.ISyncArgs|null|undefined} SyncArgs
         * @memberof manager.SyncMsg
         * @instance
         */
        SyncMsg.prototype.SyncArgs = null;

        /**
         * SyncMsg PlstArgs.
         * @member {manager.IPlstArgs|null|undefined} PlstArgs
         * @memberof manager.SyncMsg
         * @instance
         */
        SyncMsg.prototype.PlstArgs = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * SyncMsg Args.
         * @member {"SyncArgs"|"PlstArgs"|undefined} Args
         * @memberof manager.SyncMsg
         * @instance
         */
        Object.defineProperty(SyncMsg.prototype, "Args", {
            get: $util.oneOfGetter($oneOfFields = ["SyncArgs", "PlstArgs"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SyncMsg instance using the specified properties.
         * @function create
         * @memberof manager.SyncMsg
         * @static
         * @param {manager.ISyncMsg=} [properties] Properties to set
         * @returns {manager.SyncMsg} SyncMsg instance
         */
        SyncMsg.create = function create(properties) {
            return new SyncMsg(properties);
        };

        /**
         * Encodes the specified SyncMsg message. Does not implicitly {@link manager.SyncMsg.verify|verify} messages.
         * @function encode
         * @memberof manager.SyncMsg
         * @static
         * @param {manager.ISyncMsg} message SyncMsg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncMsg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Type != null && message.hasOwnProperty("Type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Type);
            if (message.SyncArgs != null && message.hasOwnProperty("SyncArgs"))
                $root.manager.SyncArgs.encode(message.SyncArgs, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.PlstArgs != null && message.hasOwnProperty("PlstArgs"))
                $root.manager.PlstArgs.encode(message.PlstArgs, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SyncMsg message, length delimited. Does not implicitly {@link manager.SyncMsg.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.SyncMsg
         * @static
         * @param {manager.ISyncMsg} message SyncMsg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncMsg.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SyncMsg message from the specified reader or buffer.
         * @function decode
         * @memberof manager.SyncMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.SyncMsg} SyncMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncMsg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.SyncMsg();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Type = reader.int32();
                    break;
                case 2:
                    message.SyncArgs = $root.manager.SyncArgs.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.PlstArgs = $root.manager.PlstArgs.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SyncMsg message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.SyncMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.SyncMsg} SyncMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncMsg.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SyncMsg message.
         * @function verify
         * @memberof manager.SyncMsg
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SyncMsg.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.Type != null && message.hasOwnProperty("Type"))
                switch (message.Type) {
                default:
                    return "Type: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.SyncArgs != null && message.hasOwnProperty("SyncArgs")) {
                properties.Args = 1;
                {
                    var error = $root.manager.SyncArgs.verify(message.SyncArgs);
                    if (error)
                        return "SyncArgs." + error;
                }
            }
            if (message.PlstArgs != null && message.hasOwnProperty("PlstArgs")) {
                if (properties.Args === 1)
                    return "Args: multiple values";
                properties.Args = 1;
                {
                    var error = $root.manager.PlstArgs.verify(message.PlstArgs);
                    if (error)
                        return "PlstArgs." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SyncMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.SyncMsg
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.SyncMsg} SyncMsg
         */
        SyncMsg.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.SyncMsg)
                return object;
            var message = new $root.manager.SyncMsg();
            switch (object.Type) {
            case "SDP":
            case 0:
                message.Type = 0;
                break;
            case "PLST":
            case 1:
                message.Type = 1;
                break;
            }
            if (object.SyncArgs != null) {
                if (typeof object.SyncArgs !== "object")
                    throw TypeError(".manager.SyncMsg.SyncArgs: object expected");
                message.SyncArgs = $root.manager.SyncArgs.fromObject(object.SyncArgs);
            }
            if (object.PlstArgs != null) {
                if (typeof object.PlstArgs !== "object")
                    throw TypeError(".manager.SyncMsg.PlstArgs: object expected");
                message.PlstArgs = $root.manager.PlstArgs.fromObject(object.PlstArgs);
            }
            return message;
        };

        /**
         * Creates a plain object from a SyncMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.SyncMsg
         * @static
         * @param {manager.SyncMsg} message SyncMsg
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SyncMsg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.Type = options.enums === String ? "SDP" : 0;
            if (message.Type != null && message.hasOwnProperty("Type"))
                object.Type = options.enums === String ? $root.manager.SyncType[message.Type] : message.Type;
            if (message.SyncArgs != null && message.hasOwnProperty("SyncArgs")) {
                object.SyncArgs = $root.manager.SyncArgs.toObject(message.SyncArgs, options);
                if (options.oneofs)
                    object.Args = "SyncArgs";
            }
            if (message.PlstArgs != null && message.hasOwnProperty("PlstArgs")) {
                object.PlstArgs = $root.manager.PlstArgs.toObject(message.PlstArgs, options);
                if (options.oneofs)
                    object.Args = "PlstArgs";
            }
            return object;
        };

        /**
         * Converts this SyncMsg to JSON.
         * @function toJSON
         * @memberof manager.SyncMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SyncMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SyncMsg;
    })();

    /**
     * MessageType enum.
     * @name manager.MessageType
     * @enum {string}
     * @property {number} MessageTypeUnknown=0 MessageTypeUnknown value
     * @property {number} MessageTypeRegister=256 MessageTypeRegister value
     * @property {number} MessageTypeUnregister=257 MessageTypeUnregister value
     * @property {number} MessageTypeImGood=260 MessageTypeImGood value
     * @property {number} MessageTypeGiveMePeers=512 MessageTypeGiveMePeers value
     * @property {number} MessageTypeHereAreSomePeers=513 MessageTypeHereAreSomePeers value
     * @property {number} MessageTypeSwipeRight=514 MessageTypeSwipeRight value
     * @property {number} MessageTypeSwipeLeft=516 MessageTypeSwipeLeft value
     * @property {number} MessageTypeItsAMatch=515 MessageTypeItsAMatch value
     * @property {number} MessageTypePairingRequest=518 MessageTypePairingRequest value
     * @property {number} MessageTypeRegistered=771 MessageTypeRegistered value
     * @property {number} MessageTypePairingInProgress=772 MessageTypePairingInProgress value
     * @property {number} MessageTypeEvent=1025 MessageTypeEvent value
     * @property {number} MessageTypeConf=1281 MessageTypeConf value
     * @property {number} MessageTypeBytes=1537 MessageTypeBytes value
     */
    manager.MessageType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MessageTypeUnknown"] = 0;
        values[valuesById[256] = "MessageTypeRegister"] = 256;
        values[valuesById[257] = "MessageTypeUnregister"] = 257;
        values[valuesById[260] = "MessageTypeImGood"] = 260;
        values[valuesById[512] = "MessageTypeGiveMePeers"] = 512;
        values[valuesById[513] = "MessageTypeHereAreSomePeers"] = 513;
        values[valuesById[514] = "MessageTypeSwipeRight"] = 514;
        values[valuesById[516] = "MessageTypeSwipeLeft"] = 516;
        values[valuesById[515] = "MessageTypeItsAMatch"] = 515;
        values[valuesById[518] = "MessageTypePairingRequest"] = 518;
        values[valuesById[771] = "MessageTypeRegistered"] = 771;
        values[valuesById[772] = "MessageTypePairingInProgress"] = 772;
        values[valuesById[1025] = "MessageTypeEvent"] = 1025;
        values[valuesById[1281] = "MessageTypeConf"] = 1281;
        values[valuesById[1537] = "MessageTypeBytes"] = 1537;
        return values;
    })();

    manager.Message = (function() {

        /**
         * Properties of a Message.
         * @memberof manager
         * @interface IMessage
         * @property {manager.MessageType|null} [Type] Message Type
         * @property {number|Long|null} [Timestamp] Message Timestamp
         * @property {string|null} [PeerID] Message PeerID
         * @property {manager.IArgumentsRegister|null} [ArgumentsRegister] Message ArgumentsRegister
         * @property {manager.IArgumentsUnregister|null} [ArgumentsUnregister] Message ArgumentsUnregister
         * @property {manager.IArgumentsHereAreSomePeers|null} [ArgumentsHereAreSomePeers] Message ArgumentsHereAreSomePeers
         * @property {manager.IArgumentsSwipeRight|null} [ArgumentsSwipeRight] Message ArgumentsSwipeRight
         * @property {manager.IArgumentsItsAMatch|null} [ArgumentsItsAMatch] Message ArgumentsItsAMatch
         * @property {manager.IArgumentsSwipeLeft|null} [ArgumentsSwipeLeft] Message ArgumentsSwipeLeft
         * @property {manager.IArgumentsPairingRequest|null} [ArgumentsPairingRequest] Message ArgumentsPairingRequest
         * @property {manager.IArgumentsRegistered|null} [ArgumentsRegistered] Message ArgumentsRegistered
         * @property {manager.IArgumentsEvent|null} [ArgumentsEvent] Message ArgumentsEvent
         * @property {manager.IArgumentsConf|null} [ArgumentsConf] Message ArgumentsConf
         * @property {manager.IArgumentsBytes|null} [ArgumentsBytes] Message ArgumentsBytes
         */

        /**
         * Constructs a new Message.
         * @memberof manager
         * @classdesc Represents a Message.
         * @implements IMessage
         * @constructor
         * @param {manager.IMessage=} [properties] Properties to set
         */
        function Message(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Message Type.
         * @member {manager.MessageType} Type
         * @memberof manager.Message
         * @instance
         */
        Message.prototype.Type = 0;

        /**
         * Message Timestamp.
         * @member {number|Long} Timestamp
         * @memberof manager.Message
         * @instance
         */
        Message.prototype.Timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Message PeerID.
         * @member {string} PeerID
         * @memberof manager.Message
         * @instance
         */
        Message.prototype.PeerID = "";

        /**
         * Message ArgumentsRegister.
         * @member {manager.IArgumentsRegister|null|undefined} ArgumentsRegister
         * @memberof manager.Message
         * @instance
         */
        Message.prototype.ArgumentsRegister = null;

        /**
         * Message ArgumentsUnregister.
         * @member {manager.IArgumentsUnregister|null|undefined} ArgumentsUnregister
         * @memberof manager.Message
         * @instance
         */
        Message.prototype.ArgumentsUnregister = null;

        /**
         * Message ArgumentsHereAreSomePeers.
         * @member {manager.IArgumentsHereAreSomePeers|null|undefined} ArgumentsHereAreSomePeers
         * @memberof manager.Message
         * @instance
         */
        Message.prototype.ArgumentsHereAreSomePeers = null;

        /**
         * Message ArgumentsSwipeRight.
         * @member {manager.IArgumentsSwipeRight|null|undefined} ArgumentsSwipeRight
         * @memberof manager.Message
         * @instance
         */
        Message.prototype.ArgumentsSwipeRight = null;

        /**
         * Message ArgumentsItsAMatch.
         * @member {manager.IArgumentsItsAMatch|null|undefined} ArgumentsItsAMatch
         * @memberof manager.Message
         * @instance
         */
        Message.prototype.ArgumentsItsAMatch = null;

        /**
         * Message ArgumentsSwipeLeft.
         * @member {manager.IArgumentsSwipeLeft|null|undefined} ArgumentsSwipeLeft
         * @memberof manager.Message
         * @instance
         */
        Message.prototype.ArgumentsSwipeLeft = null;

        /**
         * Message ArgumentsPairingRequest.
         * @member {manager.IArgumentsPairingRequest|null|undefined} ArgumentsPairingRequest
         * @memberof manager.Message
         * @instance
         */
        Message.prototype.ArgumentsPairingRequest = null;

        /**
         * Message ArgumentsRegistered.
         * @member {manager.IArgumentsRegistered|null|undefined} ArgumentsRegistered
         * @memberof manager.Message
         * @instance
         */
        Message.prototype.ArgumentsRegistered = null;

        /**
         * Message ArgumentsEvent.
         * @member {manager.IArgumentsEvent|null|undefined} ArgumentsEvent
         * @memberof manager.Message
         * @instance
         */
        Message.prototype.ArgumentsEvent = null;

        /**
         * Message ArgumentsConf.
         * @member {manager.IArgumentsConf|null|undefined} ArgumentsConf
         * @memberof manager.Message
         * @instance
         */
        Message.prototype.ArgumentsConf = null;

        /**
         * Message ArgumentsBytes.
         * @member {manager.IArgumentsBytes|null|undefined} ArgumentsBytes
         * @memberof manager.Message
         * @instance
         */
        Message.prototype.ArgumentsBytes = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Message Arguments.
         * @member {"ArgumentsRegister"|"ArgumentsUnregister"|"ArgumentsHereAreSomePeers"|"ArgumentsSwipeRight"|"ArgumentsItsAMatch"|"ArgumentsSwipeLeft"|"ArgumentsPairingRequest"|"ArgumentsRegistered"|"ArgumentsEvent"|"ArgumentsConf"|"ArgumentsBytes"|undefined} Arguments
         * @memberof manager.Message
         * @instance
         */
        Object.defineProperty(Message.prototype, "Arguments", {
            get: $util.oneOfGetter($oneOfFields = ["ArgumentsRegister", "ArgumentsUnregister", "ArgumentsHereAreSomePeers", "ArgumentsSwipeRight", "ArgumentsItsAMatch", "ArgumentsSwipeLeft", "ArgumentsPairingRequest", "ArgumentsRegistered", "ArgumentsEvent", "ArgumentsConf", "ArgumentsBytes"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Message instance using the specified properties.
         * @function create
         * @memberof manager.Message
         * @static
         * @param {manager.IMessage=} [properties] Properties to set
         * @returns {manager.Message} Message instance
         */
        Message.create = function create(properties) {
            return new Message(properties);
        };

        /**
         * Encodes the specified Message message. Does not implicitly {@link manager.Message.verify|verify} messages.
         * @function encode
         * @memberof manager.Message
         * @static
         * @param {manager.IMessage} message Message message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Message.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Type != null && message.hasOwnProperty("Type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Type);
            if (message.Timestamp != null && message.hasOwnProperty("Timestamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.Timestamp);
            if (message.PeerID != null && message.hasOwnProperty("PeerID"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.PeerID);
            if (message.ArgumentsRegister != null && message.hasOwnProperty("ArgumentsRegister"))
                $root.manager.ArgumentsRegister.encode(message.ArgumentsRegister, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.ArgumentsUnregister != null && message.hasOwnProperty("ArgumentsUnregister"))
                $root.manager.ArgumentsUnregister.encode(message.ArgumentsUnregister, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.ArgumentsHereAreSomePeers != null && message.hasOwnProperty("ArgumentsHereAreSomePeers"))
                $root.manager.ArgumentsHereAreSomePeers.encode(message.ArgumentsHereAreSomePeers, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.ArgumentsSwipeRight != null && message.hasOwnProperty("ArgumentsSwipeRight"))
                $root.manager.ArgumentsSwipeRight.encode(message.ArgumentsSwipeRight, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.ArgumentsItsAMatch != null && message.hasOwnProperty("ArgumentsItsAMatch"))
                $root.manager.ArgumentsItsAMatch.encode(message.ArgumentsItsAMatch, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.ArgumentsSwipeLeft != null && message.hasOwnProperty("ArgumentsSwipeLeft"))
                $root.manager.ArgumentsSwipeLeft.encode(message.ArgumentsSwipeLeft, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.ArgumentsPairingRequest != null && message.hasOwnProperty("ArgumentsPairingRequest"))
                $root.manager.ArgumentsPairingRequest.encode(message.ArgumentsPairingRequest, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.ArgumentsRegistered != null && message.hasOwnProperty("ArgumentsRegistered"))
                $root.manager.ArgumentsRegistered.encode(message.ArgumentsRegistered, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.ArgumentsEvent != null && message.hasOwnProperty("ArgumentsEvent"))
                $root.manager.ArgumentsEvent.encode(message.ArgumentsEvent, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.ArgumentsConf != null && message.hasOwnProperty("ArgumentsConf"))
                $root.manager.ArgumentsConf.encode(message.ArgumentsConf, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.ArgumentsBytes != null && message.hasOwnProperty("ArgumentsBytes"))
                $root.manager.ArgumentsBytes.encode(message.ArgumentsBytes, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Message message, length delimited. Does not implicitly {@link manager.Message.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.Message
         * @static
         * @param {manager.IMessage} message Message message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Message.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Message message from the specified reader or buffer.
         * @function decode
         * @memberof manager.Message
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.Message} Message
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Message.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.Message();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Type = reader.int32();
                    break;
                case 2:
                    message.Timestamp = reader.int64();
                    break;
                case 3:
                    message.PeerID = reader.string();
                    break;
                case 10:
                    message.ArgumentsRegister = $root.manager.ArgumentsRegister.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.ArgumentsUnregister = $root.manager.ArgumentsUnregister.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.ArgumentsHereAreSomePeers = $root.manager.ArgumentsHereAreSomePeers.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.ArgumentsSwipeRight = $root.manager.ArgumentsSwipeRight.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.ArgumentsItsAMatch = $root.manager.ArgumentsItsAMatch.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.ArgumentsSwipeLeft = $root.manager.ArgumentsSwipeLeft.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.ArgumentsPairingRequest = $root.manager.ArgumentsPairingRequest.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.ArgumentsRegistered = $root.manager.ArgumentsRegistered.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.ArgumentsEvent = $root.manager.ArgumentsEvent.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.ArgumentsConf = $root.manager.ArgumentsConf.decode(reader, reader.uint32());
                    break;
                case 27:
                    message.ArgumentsBytes = $root.manager.ArgumentsBytes.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Message message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.Message
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.Message} Message
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Message.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Message message.
         * @function verify
         * @memberof manager.Message
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Message.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.Type != null && message.hasOwnProperty("Type"))
                switch (message.Type) {
                default:
                    return "Type: enum value expected";
                case 0:
                case 256:
                case 257:
                case 260:
                case 512:
                case 513:
                case 514:
                case 516:
                case 515:
                case 518:
                case 771:
                case 772:
                case 1025:
                case 1281:
                case 1537:
                    break;
                }
            if (message.Timestamp != null && message.hasOwnProperty("Timestamp"))
                if (!$util.isInteger(message.Timestamp) && !(message.Timestamp && $util.isInteger(message.Timestamp.low) && $util.isInteger(message.Timestamp.high)))
                    return "Timestamp: integer|Long expected";
            if (message.PeerID != null && message.hasOwnProperty("PeerID"))
                if (!$util.isString(message.PeerID))
                    return "PeerID: string expected";
            if (message.ArgumentsRegister != null && message.hasOwnProperty("ArgumentsRegister")) {
                properties.Arguments = 1;
                {
                    var error = $root.manager.ArgumentsRegister.verify(message.ArgumentsRegister);
                    if (error)
                        return "ArgumentsRegister." + error;
                }
            }
            if (message.ArgumentsUnregister != null && message.hasOwnProperty("ArgumentsUnregister")) {
                if (properties.Arguments === 1)
                    return "Arguments: multiple values";
                properties.Arguments = 1;
                {
                    var error = $root.manager.ArgumentsUnregister.verify(message.ArgumentsUnregister);
                    if (error)
                        return "ArgumentsUnregister." + error;
                }
            }
            if (message.ArgumentsHereAreSomePeers != null && message.hasOwnProperty("ArgumentsHereAreSomePeers")) {
                if (properties.Arguments === 1)
                    return "Arguments: multiple values";
                properties.Arguments = 1;
                {
                    var error = $root.manager.ArgumentsHereAreSomePeers.verify(message.ArgumentsHereAreSomePeers);
                    if (error)
                        return "ArgumentsHereAreSomePeers." + error;
                }
            }
            if (message.ArgumentsSwipeRight != null && message.hasOwnProperty("ArgumentsSwipeRight")) {
                if (properties.Arguments === 1)
                    return "Arguments: multiple values";
                properties.Arguments = 1;
                {
                    var error = $root.manager.ArgumentsSwipeRight.verify(message.ArgumentsSwipeRight);
                    if (error)
                        return "ArgumentsSwipeRight." + error;
                }
            }
            if (message.ArgumentsItsAMatch != null && message.hasOwnProperty("ArgumentsItsAMatch")) {
                if (properties.Arguments === 1)
                    return "Arguments: multiple values";
                properties.Arguments = 1;
                {
                    var error = $root.manager.ArgumentsItsAMatch.verify(message.ArgumentsItsAMatch);
                    if (error)
                        return "ArgumentsItsAMatch." + error;
                }
            }
            if (message.ArgumentsSwipeLeft != null && message.hasOwnProperty("ArgumentsSwipeLeft")) {
                if (properties.Arguments === 1)
                    return "Arguments: multiple values";
                properties.Arguments = 1;
                {
                    var error = $root.manager.ArgumentsSwipeLeft.verify(message.ArgumentsSwipeLeft);
                    if (error)
                        return "ArgumentsSwipeLeft." + error;
                }
            }
            if (message.ArgumentsPairingRequest != null && message.hasOwnProperty("ArgumentsPairingRequest")) {
                if (properties.Arguments === 1)
                    return "Arguments: multiple values";
                properties.Arguments = 1;
                {
                    var error = $root.manager.ArgumentsPairingRequest.verify(message.ArgumentsPairingRequest);
                    if (error)
                        return "ArgumentsPairingRequest." + error;
                }
            }
            if (message.ArgumentsRegistered != null && message.hasOwnProperty("ArgumentsRegistered")) {
                if (properties.Arguments === 1)
                    return "Arguments: multiple values";
                properties.Arguments = 1;
                {
                    var error = $root.manager.ArgumentsRegistered.verify(message.ArgumentsRegistered);
                    if (error)
                        return "ArgumentsRegistered." + error;
                }
            }
            if (message.ArgumentsEvent != null && message.hasOwnProperty("ArgumentsEvent")) {
                if (properties.Arguments === 1)
                    return "Arguments: multiple values";
                properties.Arguments = 1;
                {
                    var error = $root.manager.ArgumentsEvent.verify(message.ArgumentsEvent);
                    if (error)
                        return "ArgumentsEvent." + error;
                }
            }
            if (message.ArgumentsConf != null && message.hasOwnProperty("ArgumentsConf")) {
                if (properties.Arguments === 1)
                    return "Arguments: multiple values";
                properties.Arguments = 1;
                {
                    var error = $root.manager.ArgumentsConf.verify(message.ArgumentsConf);
                    if (error)
                        return "ArgumentsConf." + error;
                }
            }
            if (message.ArgumentsBytes != null && message.hasOwnProperty("ArgumentsBytes")) {
                if (properties.Arguments === 1)
                    return "Arguments: multiple values";
                properties.Arguments = 1;
                {
                    var error = $root.manager.ArgumentsBytes.verify(message.ArgumentsBytes);
                    if (error)
                        return "ArgumentsBytes." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Message message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.Message
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.Message} Message
         */
        Message.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.Message)
                return object;
            var message = new $root.manager.Message();
            switch (object.Type) {
            case "MessageTypeUnknown":
            case 0:
                message.Type = 0;
                break;
            case "MessageTypeRegister":
            case 256:
                message.Type = 256;
                break;
            case "MessageTypeUnregister":
            case 257:
                message.Type = 257;
                break;
            case "MessageTypeImGood":
            case 260:
                message.Type = 260;
                break;
            case "MessageTypeGiveMePeers":
            case 512:
                message.Type = 512;
                break;
            case "MessageTypeHereAreSomePeers":
            case 513:
                message.Type = 513;
                break;
            case "MessageTypeSwipeRight":
            case 514:
                message.Type = 514;
                break;
            case "MessageTypeSwipeLeft":
            case 516:
                message.Type = 516;
                break;
            case "MessageTypeItsAMatch":
            case 515:
                message.Type = 515;
                break;
            case "MessageTypePairingRequest":
            case 518:
                message.Type = 518;
                break;
            case "MessageTypeRegistered":
            case 771:
                message.Type = 771;
                break;
            case "MessageTypePairingInProgress":
            case 772:
                message.Type = 772;
                break;
            case "MessageTypeEvent":
            case 1025:
                message.Type = 1025;
                break;
            case "MessageTypeConf":
            case 1281:
                message.Type = 1281;
                break;
            case "MessageTypeBytes":
            case 1537:
                message.Type = 1537;
                break;
            }
            if (object.Timestamp != null)
                if ($util.Long)
                    (message.Timestamp = $util.Long.fromValue(object.Timestamp)).unsigned = false;
                else if (typeof object.Timestamp === "string")
                    message.Timestamp = parseInt(object.Timestamp, 10);
                else if (typeof object.Timestamp === "number")
                    message.Timestamp = object.Timestamp;
                else if (typeof object.Timestamp === "object")
                    message.Timestamp = new $util.LongBits(object.Timestamp.low >>> 0, object.Timestamp.high >>> 0).toNumber();
            if (object.PeerID != null)
                message.PeerID = String(object.PeerID);
            if (object.ArgumentsRegister != null) {
                if (typeof object.ArgumentsRegister !== "object")
                    throw TypeError(".manager.Message.ArgumentsRegister: object expected");
                message.ArgumentsRegister = $root.manager.ArgumentsRegister.fromObject(object.ArgumentsRegister);
            }
            if (object.ArgumentsUnregister != null) {
                if (typeof object.ArgumentsUnregister !== "object")
                    throw TypeError(".manager.Message.ArgumentsUnregister: object expected");
                message.ArgumentsUnregister = $root.manager.ArgumentsUnregister.fromObject(object.ArgumentsUnregister);
            }
            if (object.ArgumentsHereAreSomePeers != null) {
                if (typeof object.ArgumentsHereAreSomePeers !== "object")
                    throw TypeError(".manager.Message.ArgumentsHereAreSomePeers: object expected");
                message.ArgumentsHereAreSomePeers = $root.manager.ArgumentsHereAreSomePeers.fromObject(object.ArgumentsHereAreSomePeers);
            }
            if (object.ArgumentsSwipeRight != null) {
                if (typeof object.ArgumentsSwipeRight !== "object")
                    throw TypeError(".manager.Message.ArgumentsSwipeRight: object expected");
                message.ArgumentsSwipeRight = $root.manager.ArgumentsSwipeRight.fromObject(object.ArgumentsSwipeRight);
            }
            if (object.ArgumentsItsAMatch != null) {
                if (typeof object.ArgumentsItsAMatch !== "object")
                    throw TypeError(".manager.Message.ArgumentsItsAMatch: object expected");
                message.ArgumentsItsAMatch = $root.manager.ArgumentsItsAMatch.fromObject(object.ArgumentsItsAMatch);
            }
            if (object.ArgumentsSwipeLeft != null) {
                if (typeof object.ArgumentsSwipeLeft !== "object")
                    throw TypeError(".manager.Message.ArgumentsSwipeLeft: object expected");
                message.ArgumentsSwipeLeft = $root.manager.ArgumentsSwipeLeft.fromObject(object.ArgumentsSwipeLeft);
            }
            if (object.ArgumentsPairingRequest != null) {
                if (typeof object.ArgumentsPairingRequest !== "object")
                    throw TypeError(".manager.Message.ArgumentsPairingRequest: object expected");
                message.ArgumentsPairingRequest = $root.manager.ArgumentsPairingRequest.fromObject(object.ArgumentsPairingRequest);
            }
            if (object.ArgumentsRegistered != null) {
                if (typeof object.ArgumentsRegistered !== "object")
                    throw TypeError(".manager.Message.ArgumentsRegistered: object expected");
                message.ArgumentsRegistered = $root.manager.ArgumentsRegistered.fromObject(object.ArgumentsRegistered);
            }
            if (object.ArgumentsEvent != null) {
                if (typeof object.ArgumentsEvent !== "object")
                    throw TypeError(".manager.Message.ArgumentsEvent: object expected");
                message.ArgumentsEvent = $root.manager.ArgumentsEvent.fromObject(object.ArgumentsEvent);
            }
            if (object.ArgumentsConf != null) {
                if (typeof object.ArgumentsConf !== "object")
                    throw TypeError(".manager.Message.ArgumentsConf: object expected");
                message.ArgumentsConf = $root.manager.ArgumentsConf.fromObject(object.ArgumentsConf);
            }
            if (object.ArgumentsBytes != null) {
                if (typeof object.ArgumentsBytes !== "object")
                    throw TypeError(".manager.Message.ArgumentsBytes: object expected");
                message.ArgumentsBytes = $root.manager.ArgumentsBytes.fromObject(object.ArgumentsBytes);
            }
            return message;
        };

        /**
         * Creates a plain object from a Message message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.Message
         * @static
         * @param {manager.Message} message Message
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Message.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.Type = options.enums === String ? "MessageTypeUnknown" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.Timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.Timestamp = options.longs === String ? "0" : 0;
                object.PeerID = "";
            }
            if (message.Type != null && message.hasOwnProperty("Type"))
                object.Type = options.enums === String ? $root.manager.MessageType[message.Type] : message.Type;
            if (message.Timestamp != null && message.hasOwnProperty("Timestamp"))
                if (typeof message.Timestamp === "number")
                    object.Timestamp = options.longs === String ? String(message.Timestamp) : message.Timestamp;
                else
                    object.Timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.Timestamp) : options.longs === Number ? new $util.LongBits(message.Timestamp.low >>> 0, message.Timestamp.high >>> 0).toNumber() : message.Timestamp;
            if (message.PeerID != null && message.hasOwnProperty("PeerID"))
                object.PeerID = message.PeerID;
            if (message.ArgumentsRegister != null && message.hasOwnProperty("ArgumentsRegister")) {
                object.ArgumentsRegister = $root.manager.ArgumentsRegister.toObject(message.ArgumentsRegister, options);
                if (options.oneofs)
                    object.Arguments = "ArgumentsRegister";
            }
            if (message.ArgumentsUnregister != null && message.hasOwnProperty("ArgumentsUnregister")) {
                object.ArgumentsUnregister = $root.manager.ArgumentsUnregister.toObject(message.ArgumentsUnregister, options);
                if (options.oneofs)
                    object.Arguments = "ArgumentsUnregister";
            }
            if (message.ArgumentsHereAreSomePeers != null && message.hasOwnProperty("ArgumentsHereAreSomePeers")) {
                object.ArgumentsHereAreSomePeers = $root.manager.ArgumentsHereAreSomePeers.toObject(message.ArgumentsHereAreSomePeers, options);
                if (options.oneofs)
                    object.Arguments = "ArgumentsHereAreSomePeers";
            }
            if (message.ArgumentsSwipeRight != null && message.hasOwnProperty("ArgumentsSwipeRight")) {
                object.ArgumentsSwipeRight = $root.manager.ArgumentsSwipeRight.toObject(message.ArgumentsSwipeRight, options);
                if (options.oneofs)
                    object.Arguments = "ArgumentsSwipeRight";
            }
            if (message.ArgumentsItsAMatch != null && message.hasOwnProperty("ArgumentsItsAMatch")) {
                object.ArgumentsItsAMatch = $root.manager.ArgumentsItsAMatch.toObject(message.ArgumentsItsAMatch, options);
                if (options.oneofs)
                    object.Arguments = "ArgumentsItsAMatch";
            }
            if (message.ArgumentsSwipeLeft != null && message.hasOwnProperty("ArgumentsSwipeLeft")) {
                object.ArgumentsSwipeLeft = $root.manager.ArgumentsSwipeLeft.toObject(message.ArgumentsSwipeLeft, options);
                if (options.oneofs)
                    object.Arguments = "ArgumentsSwipeLeft";
            }
            if (message.ArgumentsPairingRequest != null && message.hasOwnProperty("ArgumentsPairingRequest")) {
                object.ArgumentsPairingRequest = $root.manager.ArgumentsPairingRequest.toObject(message.ArgumentsPairingRequest, options);
                if (options.oneofs)
                    object.Arguments = "ArgumentsPairingRequest";
            }
            if (message.ArgumentsRegistered != null && message.hasOwnProperty("ArgumentsRegistered")) {
                object.ArgumentsRegistered = $root.manager.ArgumentsRegistered.toObject(message.ArgumentsRegistered, options);
                if (options.oneofs)
                    object.Arguments = "ArgumentsRegistered";
            }
            if (message.ArgumentsEvent != null && message.hasOwnProperty("ArgumentsEvent")) {
                object.ArgumentsEvent = $root.manager.ArgumentsEvent.toObject(message.ArgumentsEvent, options);
                if (options.oneofs)
                    object.Arguments = "ArgumentsEvent";
            }
            if (message.ArgumentsConf != null && message.hasOwnProperty("ArgumentsConf")) {
                object.ArgumentsConf = $root.manager.ArgumentsConf.toObject(message.ArgumentsConf, options);
                if (options.oneofs)
                    object.Arguments = "ArgumentsConf";
            }
            if (message.ArgumentsBytes != null && message.hasOwnProperty("ArgumentsBytes")) {
                object.ArgumentsBytes = $root.manager.ArgumentsBytes.toObject(message.ArgumentsBytes, options);
                if (options.oneofs)
                    object.Arguments = "ArgumentsBytes";
            }
            return object;
        };

        /**
         * Converts this Message to JSON.
         * @function toJSON
         * @memberof manager.Message
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Message.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Message;
    })();

    /**
     * IceCandidateType enum.
     * @name manager.IceCandidateType
     * @enum {string}
     * @property {number} CandidateTypeHost=0 CandidateTypeHost value
     * @property {number} CandidateTypeServerReflexive=1 CandidateTypeServerReflexive value
     * @property {number} CandidateTypePeerReflexive=2 CandidateTypePeerReflexive value
     * @property {number} CandidateTypeRelay=3 CandidateTypeRelay value
     */
    manager.IceCandidateType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CandidateTypeHost"] = 0;
        values[valuesById[1] = "CandidateTypeServerReflexive"] = 1;
        values[valuesById[2] = "CandidateTypePeerReflexive"] = 2;
        values[valuesById[3] = "CandidateTypeRelay"] = 3;
        return values;
    })();

    manager.IceCandidate = (function() {

        /**
         * Properties of an IceCandidate.
         * @memberof manager
         * @interface IIceCandidate
         * @property {manager.IceCandidateType|null} [Type] IceCandidate Type
         * @property {string|null} [Address] IceCandidate Address
         */

        /**
         * Constructs a new IceCandidate.
         * @memberof manager
         * @classdesc Represents an IceCandidate.
         * @implements IIceCandidate
         * @constructor
         * @param {manager.IIceCandidate=} [properties] Properties to set
         */
        function IceCandidate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IceCandidate Type.
         * @member {manager.IceCandidateType} Type
         * @memberof manager.IceCandidate
         * @instance
         */
        IceCandidate.prototype.Type = 0;

        /**
         * IceCandidate Address.
         * @member {string} Address
         * @memberof manager.IceCandidate
         * @instance
         */
        IceCandidate.prototype.Address = "";

        /**
         * Creates a new IceCandidate instance using the specified properties.
         * @function create
         * @memberof manager.IceCandidate
         * @static
         * @param {manager.IIceCandidate=} [properties] Properties to set
         * @returns {manager.IceCandidate} IceCandidate instance
         */
        IceCandidate.create = function create(properties) {
            return new IceCandidate(properties);
        };

        /**
         * Encodes the specified IceCandidate message. Does not implicitly {@link manager.IceCandidate.verify|verify} messages.
         * @function encode
         * @memberof manager.IceCandidate
         * @static
         * @param {manager.IIceCandidate} message IceCandidate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IceCandidate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Type != null && message.hasOwnProperty("Type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Type);
            if (message.Address != null && message.hasOwnProperty("Address"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.Address);
            return writer;
        };

        /**
         * Encodes the specified IceCandidate message, length delimited. Does not implicitly {@link manager.IceCandidate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof manager.IceCandidate
         * @static
         * @param {manager.IIceCandidate} message IceCandidate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IceCandidate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IceCandidate message from the specified reader or buffer.
         * @function decode
         * @memberof manager.IceCandidate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {manager.IceCandidate} IceCandidate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IceCandidate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.manager.IceCandidate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Type = reader.int32();
                    break;
                case 2:
                    message.Address = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IceCandidate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof manager.IceCandidate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {manager.IceCandidate} IceCandidate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IceCandidate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IceCandidate message.
         * @function verify
         * @memberof manager.IceCandidate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IceCandidate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Type != null && message.hasOwnProperty("Type"))
                switch (message.Type) {
                default:
                    return "Type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.Address != null && message.hasOwnProperty("Address"))
                if (!$util.isString(message.Address))
                    return "Address: string expected";
            return null;
        };

        /**
         * Creates an IceCandidate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof manager.IceCandidate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {manager.IceCandidate} IceCandidate
         */
        IceCandidate.fromObject = function fromObject(object) {
            if (object instanceof $root.manager.IceCandidate)
                return object;
            var message = new $root.manager.IceCandidate();
            switch (object.Type) {
            case "CandidateTypeHost":
            case 0:
                message.Type = 0;
                break;
            case "CandidateTypeServerReflexive":
            case 1:
                message.Type = 1;
                break;
            case "CandidateTypePeerReflexive":
            case 2:
                message.Type = 2;
                break;
            case "CandidateTypeRelay":
            case 3:
                message.Type = 3;
                break;
            }
            if (object.Address != null)
                message.Address = String(object.Address);
            return message;
        };

        /**
         * Creates a plain object from an IceCandidate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof manager.IceCandidate
         * @static
         * @param {manager.IceCandidate} message IceCandidate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IceCandidate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.Type = options.enums === String ? "CandidateTypeHost" : 0;
                object.Address = "";
            }
            if (message.Type != null && message.hasOwnProperty("Type"))
                object.Type = options.enums === String ? $root.manager.IceCandidateType[message.Type] : message.Type;
            if (message.Address != null && message.hasOwnProperty("Address"))
                object.Address = message.Address;
            return object;
        };

        /**
         * Converts this IceCandidate to JSON.
         * @function toJSON
         * @memberof manager.IceCandidate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IceCandidate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return IceCandidate;
    })();

    return manager;
})();

module.exports = $root;
