const partialCheck = (): boolean => {
  if (window && window.conflib !== undefined &&
    window.conflib.log) {
      return true
  }
}

const loginfo = (module: string, message?: any, ...optionalParameters: any) => {
  if (partialCheck() && window.conflib.log.info) {
    console.info(`@${module}`, message, optionalParameters)
  }
}

const logerr = (module: string, message?: any, ...optionalParameters: any) => {
  if (partialCheck() && window.conflib.log.error) {
    console.error(`@${module}`, message, optionalParameters)
  }
}

const logwarn = (module: string, message?: any, ...optionalParameters: any) => {
  if (partialCheck() && window.conflib.log.warn) {
    console.warn(`@${module}`, message, optionalParameters)
  }
}

const logdebug = (module: string, message?: any, ...optionalParameters: any) => {
  if (partialCheck() && window.conflib.log.debug) {
    console.debug(`@${module}`, message, optionalParameters)
  }
}

export {
  loginfo,
  logerr,
  logwarn,
  logdebug,
}
