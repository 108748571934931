import { IStatsOutputPlugin } from "../statsCollectors"
// import { interval } from "rxjs"
import { ParsedUrlQueryInput, stringify } from "querystring"
import { manager } from "../protos/eb.manager"
import { logwarn, loginfo } from "../utils"

const modName = "stats-receiver"
// expose a function that takes the eblib and bind the new plugin
interface IRequestParams extends ParsedUrlQueryInput {
  // Origin;
  or?: string
  // Viewer id.
  id: string
  // Content url.
  IDContenu: string
  // Formatted.
  metrics: string
  // OS
  os: string
  // Browser
  b: string
  // Content is audio: "t", video: "f"
  r: string
  // Content is on demand: "t", lisve: "f"
  vod: string
  // ISP / Mobile network
  mnc?: string
  // fluctuation
  nf?: string
  // register values
  rv?: string
  //
  pq?: string

}

export class StatsReceiverOutputPlugin implements IStatsOutputPlugin {
  /**
   * next method takes the next message from events
   */
  public playerInfo: manager.IArgumentsPlayerInfo
  public peerInfo: manager.IArgumentsPeerInfo
  private id: string
  private statsReceiverURL: string
  private cdnCount: number = 0
  private v2vCount: number = 0
  private statsDownload: number[] = [0, 0, 0, 0, 0.1, 0.1] // v2v chunk, cdn chunk, sizeSum v2v, cdn, time, time
  private sentCount: number = 0
  private swarmSize: number = 0
  private cdnCountLastSend: number = 0
  private v2vCountLastSend: number = 0
  private chunksLength: number = 0
  private statsInterval: number = 30000
  private idInterval: any
  private occupiedSlots: number = 0
  private occupiedSlotsConst: number = 3
  private occupiedSlotsCounter: number = 1
  public constructor(statsUrl: string, statsInterval: number = 30000) {
    this.statsReceiverURL = statsUrl
    this.statsInterval = statsInterval
  }
  public next = (msg: manager.Message): void => {
    // take only the event messages
    if (msg.Type !== manager.MessageType.MessageTypeEvent) {
      return
    }
    // listen to player info
    if (msg.ArgumentsEvent.EventName === manager.ArgumentsEvent.EventClass.PEER
      && msg.ArgumentsEvent.Action === manager.ArgumentsEvent.Actions.REGISTERED
      && msg.ArgumentsEvent.ArgumentsRegistered) {
      this.id = msg.ArgumentsEvent.ArgumentsRegistered.ID
    }
    if (msg.ArgumentsEvent.EventName === manager.ArgumentsEvent.EventClass.PLAYER
      && msg.ArgumentsEvent.Action === manager.ArgumentsEvent.Actions.INFO
      && msg.ArgumentsEvent.ArgumentsPlayerInfo) {
      this.playerInfo = new manager.ArgumentsPlayerInfo(msg.ArgumentsEvent.ArgumentsPlayerInfo)
    }
    // listens to peer info
    if (msg.ArgumentsEvent.EventName === manager.ArgumentsEvent.EventClass.PEER
      && msg.ArgumentsEvent.Action === manager.ArgumentsEvent.Actions.INFO
      && msg.ArgumentsEvent.ArgumentsPeerInfo) {
      this.peerInfo = new manager.ArgumentsPeerInfo(msg.ArgumentsEvent.ArgumentsPeerInfo)
    }
    if (msg.ArgumentsEvent.EventName === manager.ArgumentsEvent.EventClass.SWARM
      && msg.ArgumentsEvent.Action === manager.ArgumentsEvent.Actions.SIZEUPDATE) {
      this.swarmSize = msg.ArgumentsEvent.ArgumentsSwarmSizeUpdate.Size
    }

    if (msg.ArgumentsEvent.EventName === manager.ArgumentsEvent.EventClass.RESOURCE
      && msg.ArgumentsEvent.Action === manager.ArgumentsEvent.Actions.SLOTUPDATE) {
        this.occupiedSlots = this.occupiedSlots + msg.ArgumentsEvent.ArgumentsSlotUpdate.OccupiedSlot
        this.occupiedSlotsCounter++
      }

    // listens to resource manager
    if (msg.ArgumentsEvent.EventName !== manager.ArgumentsEvent.EventClass.RESOURCE) {
      return
    }
    // downloaded && uploaded
    switch (msg.ArgumentsEvent.Action ) {
      case manager.ArgumentsEvent.Actions.DOWNLOADED:
        if (msg.ArgumentsEvent.ArgumentsResourceDownloaded.Mode === "v2v") {
          this.v2vCount += 1
          this.statsDownload[0] ++
          this.statsDownload[2] += msg.ArgumentsEvent.ArgumentsResourceDownloaded.SizeBytes
          this.statsDownload[4] += msg.ArgumentsEvent.ArgumentsResourceDownloaded.TimeSpentMs
        } else {
          this.cdnCount += 1
          this.statsDownload[1] ++
          this.statsDownload[3] += msg.ArgumentsEvent.ArgumentsResourceDownloaded.SizeBytes
          this.statsDownload[5] += msg.ArgumentsEvent.ArgumentsResourceDownloaded.TimeSpentMs
        }
        this.chunksLength += msg.ArgumentsEvent.ArgumentsResourceDownloaded.SizeBytes
        loginfo(`V2V: ${this.statsDownload[0]} - ${Math.round(this.statsDownload[2]
          / this.statsDownload[4])} KB/S | CDN: ${this.statsDownload[1]} - ${Math.round(this.statsDownload[3]
            / this.statsDownload[5])} KB/S | SENT: ${this.sentCount}`)
        break
      case manager.ArgumentsEvent.Actions.UPLOADED:
        this.sentCount += 1
        break
    }
  }
  public start = (): StatsReceiverOutputPlugin => {
    // this methods starts the sending loop
    this.idInterval = setInterval(() => {
      this.sendMetrics(this.peerInfo, this.playerInfo)
    }, this.statsInterval)
    return this
  }

  public stop() {
    clearInterval(this.idInterval)
  }
  private sendMetrics = (peerInfo: manager.IArgumentsPeerInfo, playerInfo: manager.IArgumentsPlayerInfo): void => {
    if (!playerInfo) {
      logwarn(modName, "playerInfo not available")
      return
    }
    if (!peerInfo) {
      logwarn(modName, "peerInfo not available")
      return
    }
    const params: IRequestParams = {
      id: this.id,
      IDContenu: peerInfo.Content,
      metrics: this.getMetricsString(peerInfo, playerInfo),
      os: peerInfo.OS,
      b : peerInfo.Browser,
      r: (peerInfo.Radio) ? "t" : "f",
      vod: (peerInfo.VOD) ? "t" : "f",
    }
    if (peerInfo.Origin !== undefined) {
      params.or = peerInfo.Origin
    }
    if (playerInfo.BandwidthFluctuation !== undefined) {
      params.nf = playerInfo.BandwidthFluctuation.toString()
    }
    if (playerInfo.Bandwidth !== undefined) {
      params.pq = playerInfo.Bandwidth.toString()
    }

    // if (this.isp !== undefined) {
    //   params.mnc = this.isp
    // }

    fetch(
      `${this.statsReceiverURL}/?${stringify(params)}`,
    )
    .catch((err: Error) => {
      logwarn(modName, `Could not send statistics: ${err}`)
    })
    .then(() => {
      this.cdnCountLastSend = this.cdnCount
      this.v2vCountLastSend = this.v2vCount
      this.sentCount = 0
      this.occupiedSlots = 0
      this.occupiedSlotsCounter = 1
      // this.fluctuation = 0
      // this.registered_value = 0
    })

  }
  private getMetricsString(peerInfo: manager.IArgumentsPeerInfo, playerInfo: manager.IArgumentsPlayerInfo): string {
    const parts: string[] = [
      peerInfo.StreamProto,
      // (this.v2vEnabled) ? 'on' : 'off',
      "on",
      `${this.sentCount}`,
      `${this.swarmSize}`,
      this.getV2VRatio(),
      this.getOccupiedSlots(),
      this.roundValue(playerInfo.BufferLength),
      playerInfo.PlayingState.toString(),
      this.roundValue((this.chunksLength / (this.statsInterval / 1000)) / 1000),
      this.roundValue(peerInfo.StartUpTime),
      `${playerInfo.WatchingTime}`,
      this.roundValue(playerInfo.RebufferingTime),
      `${playerInfo.Rebuffers}`,
      this.getAudioAverageLength(),
      this.getVideoAverageLength(),
      this.getChunkCountValue(),
    ]

    return parts.join("~")
  }
  private roundValue(value: number): string {
    return (Math.round(value * 1000) / 1000).toString()
  }

  private getChunkCountValue(): string {
    const compose: Uint8Array = new Uint8Array(4)
    const v2v: number = this.v2vCount - this.v2vCountLastSend
    const cdn: number = this.cdnCount - this.cdnCountLastSend

    if (this.peerInfo.Radio) {
      compose[0] = Math.min(cdn, 256)
      compose[1] = Math.min(v2v, 256)
      compose[2] = 0
      compose[3] = 0
    } else {
      compose[0] = 0
      compose[1] = 0
      compose[2] = Math.min(cdn, 256)
      compose[3] = Math.min(v2v, 256)
    }
    return (new DataView(compose.buffer)).getInt32(0, false).toString()
  }


  private getV2VRatio(): string {
    if ((this.v2vCount === 0) && (this.cdnCount === 0)) {
      return "0"
    }
    return Math.round(this.v2vCount / (this.v2vCount + this.cdnCount)).toString()
  }

  private getAudioAverageLength(): string {
    if (!this.peerInfo.Radio) {
      return "0"
    }
    return Math.round(this.chunksLength / (this.v2vCount + this.cdnCount)).toString()
  }

  /**
   * Get average video chunks length in bytes.
   */
  private getVideoAverageLength(): string {
    if (this.peerInfo.Radio) {
      return "0"
    }
    return Math.round(this.chunksLength / (this.v2vCount + this.cdnCount)).toString()
  }

  private getOccupiedSlots(): string {
    if (this.occupiedSlotsCounter > 1) {
      this.occupiedSlotsCounter = this.occupiedSlotsCounter - 1
    }
    return Math.round(this.occupiedSlots / this.occupiedSlotsCounter).toString()
  }
}
